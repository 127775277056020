import { messageResources } from "./messageResources"
import axios from 'axios'


export const getGenderSeries = (students) => {

    let female = 0
    let male = 0
    let genderSeries = []

    students.map(student => {

       // console.log(student)

        if(student.gender.label == 'Male'){
            male++
        } else {
            female++
        }

    })    

    genderSeries.push({toLabel: "Male", toData: male})
    genderSeries.push({toLabel: "Female", toData: female})

    return genderSeries

}


export const getBursarySeries = (students) => {

    let yes = 0
    let no = 0
    let bursarySeries = []

    students.map(student => {

       // console.log(student)

        if(student.bursary && student.bursary.discretionary){
            yes++
        } else {
            no++
        }

    })    

    bursarySeries.push({toLabel: "Eligible", toData: yes})
    bursarySeries.push({toLabel: "NON Eligible", toData: no})

    return bursarySeries

}



export const getLangSeries = (students) => {

    let italian = 0;
    let spanish = 0;
    let portuguese = 0;
    let french = 0;
    let somali = 0;
    let dutch = 0;
    let russian = 0;
    let latvian = 0;
    let arabic = 0;
    let lingala = 0;
    let yoruba = 0;
    let german = 0;

    students.map(student => {

        if(student.languages?.length > 0){

            student.languages?.map(langu => {
            
                if(langu.label == "Italian"){
                    italian++
                }else if(langu.label == "Spanish"){
                    spanish++
                }else if(langu.label == "Portuguese"){
                    portuguese++
                }else if(langu.label == "French"){
                    french++
                }else if(langu.label == "Somali"){
                    somali++
                }else if(langu.label == "Dutch"){
                    dutch++
                }else if(langu.label == "Russian"){
                    russian++
                }else if(langu.label == "Latvian"){
                    latvian++
                }else if(langu.label == "Arabic"){
                    arabic++
                }else if(langu.label == "Lingala"){
                    lingala++
                }else if(langu.label == "Yoruba"){
                    yoruba++
                }else if(langu.label == "German"){
                    german++
                }
            })
        }


    })    

    let langStats = [
        {toData: italian, toLabel: 'Italian'},
        {toData: spanish, toLabel: 'Spanish'},
        {toData: portuguese, toLabel: 'Portuguese'},
        {toData: french, toLabel: 'French'},
        {toData: somali, toLabel: 'Somali'},
        {toData: dutch, toLabel: 'Dutch'},
        {toData: russian, toLabel: 'Russian'},
        {toData: latvian, toLabel: 'Latvian'},
        {toData: arabic, toLabel: 'Arabic'},
        {toData: lingala, toLabel: 'Lingala'},
        {toData: yoruba, toLabel: 'Yoruba'},
        {toData: german, toLabel: 'German'}
    ]

    return langStats

}



export const getEthniSeries = (students) => {

    let bAfrican = 0;
    let wBritish = 0;
    let oWhite = 0;
    let mM = 0;
    let indi = 0
    let pak = 0
    let bang = 0
    let oAback = 0
    let bCar = 0
    let chi = 0
    let other = 0

    students.map(student => {

        if(student.ethnicity.label == "Black African"){
            bAfrican++
        }else if(student.ethnicity.label == "White British"){
            wBritish++
        }else if(student.ethnicity.label == "Other White"){
            oWhite++
        }else if(student.ethnicity.label == "Mixed/Multiple"){
            mM++
        }else if(student.ethnicity.label == "Indian"){
            indi++
        }else if(student.ethnicity.label == "Pakistani"){
            pak++
        }else if(student.ethnicity.label == "Bangladeshi"){
            bang++
        }else if(student.ethnicity.label == "Other Asian background"){
            oAback++
        }else if(student.ethnicity.label == "Black Caribbean"){
            bCar++
        }else if(student.ethnicity.label == "Chinese"){
            chi++
        }else if(student.ethnicity.label == "Other"){
            other++
        }

    })    

    let ethStats = [
        {toData: wBritish, toLabel: 'White British'},
        {toData: oWhite, toLabel: 'Other White'},
        {toData: mM, toLabel: 'Mixed/Multiple'},
        {toData: indi, toLabel: 'Indian'},
        {toData: pak, toLabel: 'Pakistani'},
        {toData: bang, toLabel: 'Bangladeshi'},
        {toData: oAback, toLabel: 'Other Asian background'},
        {toData: bCar, toLabel: 'Black Caribbean'},
        {toData: bAfrican, toLabel: 'Black African'},
        {toData: chi, toLabel: 'Chinese'},
        {toData: other, toLabel: 'Other'}
    ]


    return ethStats

}



export const getBoroughSeries = (students) => {

    let barking = 0;
    let barnet = 0;
    let bexley = 0;
    let brent = 0;
    let bromley = 0
    let camden = 0
    let london = 0
    let croydon = 0
    let ealing = 0
    let enfield = 0
    let greenwich = 0
    let fulham = 0;
    let haringey = 0;
    let harrow = 0;
    let havering = 0;
    let hillingdon = 0
    let hounslow = 0
    let islington = 0
    let chelsea = 0
    let kingston = 0
    let lambeth = 0
    let lewisham = 0
    let merton = 0;
    let newham = 0;
    let redbridge = 0;
    let richmond = 0;
    let southwark = 0
    let sutton = 0
    let tower = 0
    let waltham = 0
    let wandsworth = 0
    let westminster = 0


    students.map(student => {

        if(student.borough.label == "Barking and Dagenham"){
            barking++
        }else if(student.borough.label == "Barnet"){
            barnet++
        }else if(student.borough.label == "Bexley"){
            bexley++
        }else if(student.borough.label == "Brent"){
            brent++
        }else if(student.borough.label == "Bromley"){
            bromley++
        }else if(student.borough.label == "Camden"){
            camden++
        }else if(student.borough.label == "City of London"){
            london++
        }else if(student.borough.label == "Croydon"){
            croydon++
        }else if(student.borough.label == "Ealing"){
            ealing++
        }else if(student.borough.label == "Enfield"){
            enfield++
        }else if(student.borough.label == "Greenwich"){
            greenwich++
        }else if(student.borough.label == "Hammersmith and Fulham"){
            fulham++
        }else if(student.borough.label == "Haringey"){
            haringey++
        }else if(student.borough.label == "Harrow"){
            harrow++
        }else if(student.borough.label == "Havering"){
            havering++
        }else if(student.borough.label == "Hillingdon"){
            hillingdon++
        }else if(student.borough.label == "Hounslow"){
            hounslow++
        }else if(student.borough.label == "Islington"){
            islington++
        }else if(student.borough.label == "Kensington and Chelsea"){
            chelsea++
        }else if(student.borough.label == "Kingston upon Thames"){
            kingston++
        }else if(student.borough.label == "Lambeth"){
            lambeth++
        }else if(student.borough.label == "Lewisham"){
            lewisham++
        }else if(student.borough.label == "Merton"){
            merton++
        }else if(student.borough.label == "Newham"){
            newham++
        }else if(student.borough.label == "Redbridge"){
            redbridge++
        }else if(student.borough.label == "Richmond upon Thames"){
            richmond++
        }else if(student.borough.label == "Southwark"){
            southwark++
        }else if(student.borough.label == "Sutton"){
            sutton++
        }else if(student.borough.label == "Tower Hamlets"){
            tower++
        }else if(student.borough.label == "Waltham Forest"){
            waltham++
        }else if(student.borough.label == "Wandsworth"){
            wandsworth++
        }else if(student.borough.label == "Westminster"){
            westminster++
        } 

    })    

    let boStats = [
        {toData: barking, toLabel: 'Barking and Dagenham'},
        {toData: barnet, toLabel: 'Barnet'},
        {toData: bexley, toLabel: 'Bexley'},
        {toData: brent, toLabel: 'Brent'},
        {toData: bromley, toLabel: 'Bromley'},
        {toData: camden, toLabel: 'Camden'},
        {toData: london, toLabel: 'City of London'},
        {toData: croydon, toLabel: 'Croydon'},
        {toData: ealing, toLabel: 'Ealing'},
        {toData: enfield, toLabel: 'Enfield'},
        {toData: greenwich, toLabel: 'Greenwich'},
        {toData: fulham, toLabel: 'Hammersmith and Fulham'},
        {toData: haringey, toLabel: 'Haringey'},
        {toData: harrow, toLabel: 'Harrow'},
        {toData: havering, toLabel: 'Havering'},
        {toData: hillingdon, toLabel: 'Hillingdon'},
        {toData: hounslow, toLabel: 'Hounslow'},
        {toData: islington, toLabel: 'Islington'},
        {toData: chelsea, toLabel: 'Kensington and Chelsea'},
        {toData: kingston, toLabel: 'Kingston upon Thames'},
        {toData: lambeth, toLabel: 'Lambeth'},
        {toData: lewisham, toLabel: 'Lewisham'},
        {toData: merton, toLabel: 'Merton'},
        {toData: newham, toLabel: 'Newham'},
        {toData: redbridge, toLabel: 'Redbridge'},
        {toData: richmond, toLabel: 'Richmond upon Thames'},
        {toData: southwark, toLabel: 'Southwark'},
        {toData: sutton, toLabel: 'Sutton'},
        {toData: tower, toLabel: 'Tower Hamlets'},
        {toData: waltham, toLabel: 'Waltham Forest'},
        {toData: wandsworth, toLabel: 'Wandsworth'},
        {toData: westminster, toLabel: 'Westminster'}
    
    ]

    return boStats

}



export const getVocChoiceSeries = (students) => {

    let floristry = 0
    let horticulture = 0
    let hospitality = 0
    let retail = 0
    let retHos = 0
    let hortRetail = 0
    let customer = 0;

    students.map(student => {

        if(student.activeProgramme){


            if(student.activeProgramme.speciality.label == messageResources.VOCATIONAL.FLORITRY){
                floristry++  
            }else if(student.activeProgramme.speciality.label == messageResources.VOCATIONAL.HORTI){
                horticulture++  
            }else if(student.activeProgramme.speciality.label == messageResources.VOCATIONAL.HOSPI){
                hospitality++  
            }else if(student.activeProgramme.speciality.label == messageResources.VOCATIONAL.RETAIL){
                retail++  
            }else if(student.activeProgramme.speciality.label == messageResources.VOCATIONAL.RETHOS){
                retHos++  
            }else if(student.activeProgramme.speciality.label == messageResources.VOCATIONAL.HORTRET){
                hortRetail++  
            }else if(student.activeProgramme.speciality.label == messageResources.VOCATIONAL.CUSTOMER){
                customer++  
            }

        }

    })    

    let vocStats = [
        {toData: floristry, toLabel: messageResources.VOCATIONAL.FLORITRY},
        {toData: horticulture, toLabel: messageResources.VOCATIONAL.HORTI},
        {toData: hospitality, toLabel: messageResources.VOCATIONAL.HOSPI},
        {toData: retail, toLabel: messageResources.VOCATIONAL.RETAIL},
        {toData: retHos, toLabel: messageResources.VOCATIONAL.RETHOS},
        {toData: hortRetail, toLabel: messageResources.VOCATIONAL.HORTRET},
        {toData: customer, toLabel: messageResources.VOCATIONAL.CUSTOMER}
    ]

    return vocStats

}




export const getProgChoiceSeries = (students) => {

    let study = 0
    let pre = 0
    let non = 0

    students.map(student => {

        if(student.activeProgramme){

            if(student.activeProgramme.programme.value == 1){
                study++  
            }else if(student.activeProgramme.programme.value == 2){
                pre++  
            }else if(student.activeProgramme.programme.value == 3){
                non++  
            }

        }

    })    

    let progStats = [
        {toData: study, toLabel: messageResources.PROGRAM.STUDY},
        {toData: pre, toLabel: messageResources.PROGRAM.PRESUP},
        {toData: non, toLabel: messageResources.PROGRAM.NON}
    ]

    return progStats

}

const getNumbers = (subjects, numbers) => {

    //console.log("=======> getNumbers <===========")
    subjects.map(subject => {
        //console.log(subject.label)

        // Learning Continued is not counted in
        
        if(subject.achievement?.value != 4) {

            if(subject.label.includes("Horticulture Skills")){
            
                numbers.totalExpected = numbers.totalExpected + 1
                numbers.hortiExpected = numbers.hortiExpected + 1
                if(subject.achievement?.value == 1) {
                    numbers.totalAchieved = numbers.totalAchieved + 1
                    numbers.hortiAchieved = numbers.hortiAchieved + 1
                
                }

            }else if(subject.label.includes("Floral")){
                numbers.totalExpected = numbers.totalExpected + 1
                numbers.floralExpected = numbers.floralExpected + 1
                if(subject.achievement?.value == 1) {
                    numbers.totalAchieved = numbers.totalAchieved + 1
                    numbers.floralAchieved = numbers.floralAchieved + 1
                }

            }else if(subject.label.includes("Mathematics") || subject.label.includes("Maths")){
        
                numbers.totalExpected = numbers.totalExpected + 1
                numbers.mathsExpected = numbers.mathsExpected + 1
                if(subject.achievement?.value == 1) {
                    numbers.totalAchieved = numbers.totalAchieved + 1
                    numbers.mathsAchieved = numbers.mathsAchieved + 1
                }
                    
            }else if(subject.label.includes("Science")){
                
                numbers.totalExpected = numbers.totalExpected + 1
                numbers.entryExpected = numbers.entryExpected + 1
                if(subject.achievement?.value == 1) {
                    numbers.totalAchieved = numbers.totalAchieved + 1
                    numbers.entryAchieved = numbers.entryAchieved + 1
                }

            }else if(subject.label.includes("Employability")){
                numbers.totalExpected = numbers.totalExpected + 1
                numbers.employExpected = numbers.employExpected + 1
                if(subject.achievement?.value == 1) {
                    numbers.totalAchieved = numbers.totalAchieved + 1
                    numbers.employAchieved = numbers.employAchieved + 1
                }

            }else if(subject.label.includes("Retail")){
                numbers.totalExpected = numbers.totalExpected + 1
                numbers.retailExpected = numbers.retailExpected + 1
                if(subject.achievement?.value == 1) {
                    numbers.totalAchieved = numbers.totalAchieved + 1
                    numbers.retailAchieved = numbers.retailAchieved + 1
                }

            }else if(subject.label.includes("English")){
                numbers.totalExpected = numbers.totalExpected + 1
                numbers.englishExpected = numbers.englishExpected + 1
                if(subject.achievement?.value == 1) {
                    numbers.totalAchieved = numbers.totalAchieved + 1
                    numbers.englishAchieved = numbers.englishAchieved + 1
                }

            }else if(subject.label.includes("Non regulated")){
                
                numbers.totalExpected = numbers.totalExpected + 1
                numbers.nonExpected = numbers.nonExpected + 1
                if(subject.achievement?.value == 1) {
                    numbers.totalAchieved = numbers.totalAchieved + 1
                    numbers.nonAchieved = numbers.nonAchieved + 1
                }

            }else if(subject.label.includes("ICT")){
                numbers.totalExpected = numbers.totalExpected + 1
                numbers.ictExpected = numbers.ictExpected + 1
                if(subject.achievement?.value == 1) {
                    numbers.totalAchieved = numbers.totalAchieved + 1
                    numbers.ictAchieved = numbers.ictAchieved + 1
                }
            }
        }else{
            numbers.totalContinued = numbers.totalContinued + 1
        }
    })


    return numbers
}



export const getProgStatsPerYear = (programes) => {

    let study = 0
    let pre = 0
    let non = 0

    let floristry = 0
    let horticulture = 0
    let hospitality = 0
    let retail = 0
    let retHos = 0
    let hortRetail = 0

    let plus = 0
    let less = 0
    let further = 0
    let health = 0;
    let neet = 0
    let returning = 0
    let supported = 0
    let volun = 0
    let leavers = 0

    let numbers = {
        nonExpected: 0,
        nonAchieved: 0,
        hortiExpected: 0,
        hortiAchieved: 0,
        entryExpected: 0,
        entryAchieved: 0,
        retailExpected: 0,
        retailAchieved: 0,
        floralExpected: 0,
        floralAchieved: 0,
        englishExpected: 0,
        englishAchieved: 0,
        mathsExpected: 0,
        mathsAchieved: 0,
        ictExpected: 0,
        ictAchieved: 0,
        employExpected: 0,
        employAchieved: 0,
        totalExpected: 0,
        totalAchieved: 0,
        totalContinued: 0

    }

    //let onlyPercent = []


    programes.map(uno => {

        if(uno.suppActivities?.length > 0){
            numbers =  getNumbers(uno.suppActivities,numbers)
        }

        if(uno.eepActivities?.length > 0){
            numbers = getNumbers(uno.eepActivities,numbers)
        }

        if(uno.cgQualifications?.length > 0){
            numbers = getNumbers(uno.cgQualifications,numbers)
        }

        //console.log(numbers)

        numbers.nonPercent = ((numbers.nonAchieved / numbers.nonExpected) * 100).toFixed(2);
        numbers.hortiPercent = ((numbers.hortiAchieved / numbers.hortiExpected) * 100).toFixed(2);
        numbers.entryPercent = ((numbers.entryAchieved / numbers.entryExpected) * 100).toFixed(2);
        numbers.retailPercent = ((numbers.retailAchieved / numbers.retailExpected) * 100).toFixed(2);
        numbers.floralPercent = ((numbers.floralAchieved / numbers.floralExpected) * 100).toFixed(2);
        numbers.englishPercent = ((numbers.englishAchieved / numbers.englishExpected) * 100).toFixed(2);
        numbers.mathsPercent = ((numbers.mathsAchieved / numbers.mathsExpected) * 100).toFixed(2);
        numbers.employPercent = ((numbers.employAchieved / numbers.employExpected) * 100).toFixed(2);
        numbers.ictPercent = ((numbers.ictAchieved / numbers.ictExpected) * 100).toFixed(2);
        numbers.totalPercent = ((numbers.totalAchieved / numbers.totalExpected) * 100).toFixed(2);

        // onlyPercent = [Number(numbers.nonPercent), Number(numbers.hortiPercent), Number(numbers.entryPercent),
        //     Number(numbers.retailPercent), Number(numbers.floralPercent), Number(numbers.englishPercent),  
        //     Number(numbers.mathsPercent), Number(numbers.employPercent), Number(numbers.totalPercent)] //Number(numbers.ictPercent), 

        if(uno.programme.value == 1){
            study++  
        }else if(uno.programme.value == 2){
            pre++  
        }else if(uno.programme.value == 3){
            non++  
        }

        if(uno.speciality.label == messageResources.VOCATIONAL.FLORITRY){
            floristry++  
        }else if(uno.speciality.label == messageResources.VOCATIONAL.HORTI){
            horticulture++  
        }else if(uno.speciality.label == messageResources.VOCATIONAL.HOSPI){
            hospitality++  
        }else if(uno.speciality.label == messageResources.VOCATIONAL.RETAIL){
            retail++  
        }else if(uno.speciality.label == messageResources.VOCATIONAL.RETHOS){
            retHos++  
        }else if(uno.speciality.label == messageResources.VOCATIONAL.HORTRET){
            hortRetail++  
        }


        if(uno.destination?.value == 1){
            plus++  
        }else if(uno.destination?.value == 2){
            less++  
        }else if(uno.destination?.value == 3){
            further++  
        }else if(uno.destination?.value == 4){
            health++  
        }else if(uno.destination?.value == 5){
            neet++  
        }else if(uno.destination?.value == 6){
            returning++  
        }else if(uno.destination?.value == 7){
            supported++  
        }else if(uno.destination?.value == 8){
            volun++  
        }
    
        
    })    

    leavers = volun + supported + neet + health + further + less + plus

    let retenStats = [
        {toData: returning, toLabel: messageResources.DESTINATION.RETUR},
        {toData: leavers, toLabel: messageResources.DESTINATION.LEAVE}
    ]


    let progStats = [
        {toData: study, toLabel: messageResources.PROGRAM.STUDY},
        {toData: pre, toLabel: messageResources.PROGRAM.PRESUP},
        {toData: non, toLabel: messageResources.PROGRAM.NON}
    ]

    let vocStats = [
        {toData: floristry, toLabel: messageResources.VOCATIONAL.FLORITRY},
        {toData: horticulture, toLabel: messageResources.VOCATIONAL.HORTI},
        {toData: hospitality, toLabel: messageResources.VOCATIONAL.HOSPI},
        {toData: retail, toLabel: messageResources.VOCATIONAL.RETAIL},
        {toData: retHos, toLabel: messageResources.VOCATIONAL.RETHOS},
        {toData: hortRetail, toLabel: messageResources.VOCATIONAL.HORTRET}
    ]


    let destStats = [
        {toData: plus, toLabel: messageResources.DESTINATION.PLUS16},
        {toData: less, toLabel: messageResources.DESTINATION.LESS16},
        {toData: further, toLabel: messageResources.DESTINATION.FURTHER},
        {toData: health, toLabel: messageResources.DESTINATION.HEALTH},
        {toData: neet, toLabel: messageResources.DESTINATION.NEET},
        {toData: returning, toLabel: messageResources.DESTINATION.RETURNING},
        {toData: supported, toLabel: messageResources.DESTINATION.SUPPORTED},
        {toData: volun, toLabel: messageResources.DESTINATION.VOLUNTEERING}
    ]



    let mainStats = {

        vocational: vocStats,
        programme: progStats,
        destination: destStats,
        retention: retenStats,
        numbers: numbers
        //percents: onlyPercent

    }

    return mainStats


    
}    



// export const getPercents = (token) => {

//     const years = [messageResources.YEAR.Y22, messageResources.YEAR.Y23]
//     const percents = []

//     years.map(async year => {

//         console.log("|-------------> ITERATING <---------------|")
//         console.log(year)
//         let yearNoSlash = (year).replace('/','_')
//         let numbers = {}

//         const programesPerYear = await axios.get(
//             `https://www.seroots.org.uk/programmes/${yearNoSlash}`, {
                
//             headers: {
//                         Authorization: `Bearer ` + token
//                     }    
//             }
//         );


//         programesPerYear.data.map(uno => {

//             if(uno.suppActivities?.length > 0){
//                 numbers =  getNumbers(uno.suppActivities,numbers)
//             }
    
//             if(uno.eepActivities?.length > 0){
//                 numbers = getNumbers(uno.eepActivities,numbers)
//             }
    
//             if(uno.cgQualifications?.length > 0){
//                 numbers = getNumbers(uno.cgQualifications,numbers)
//             }
    
//             //console.log(numbers)
    
//             numbers.nonPercent = ((numbers.nonAchieved / numbers.nonExpected) * 100).toFixed(2);
//             numbers.hortiPercent = ((numbers.hortiAchieved / numbers.hortiExpected) * 100).toFixed(2);
//             numbers.entryPercent = ((numbers.entryAchieved / numbers.entryExpected) * 100).toFixed(2);
//             numbers.retailPercent = ((numbers.retailAchieved / numbers.retailExpected) * 100).toFixed(2);
//             numbers.floralPercent = ((numbers.floralAchieved / numbers.floralExpected) * 100).toFixed(2);
//             numbers.englishPercent = ((numbers.englishAchieved / numbers.englishExpected) * 100).toFixed(2);
//             numbers.mathsPercent = ((numbers.mathsAchieved / numbers.mathsExpected) * 100).toFixed(2);
//             numbers.employPercent = ((numbers.employAchieved / numbers.employExpected) * 100).toFixed(2);
//             numbers.ictPercent = ((numbers.ictAchieved / numbers.ictExpected) * 100).toFixed(2);
//             numbers.totalPercent = ((numbers.totalAchieved / numbers.totalExpected) * 100).toFixed(2);
    
//             // onlyPercent = [Number(numbers.nonPercent), Number(numbers.hortiPercent), Number(numbers.entryPercent),
//             //     Number(numbers.retailPercent), Number(numbers.floralPercent), Number(numbers.englishPercent),  
//             //     Number(numbers.mathsPercent), Number(numbers.employPercent), Number(numbers.totalPercent)] //Number(numbers.ictPercent), 
    
//             percents.push(numbers)
            
//         })

//         console.log("|-------------> LAP FINISHED <---------------|")

//     })


//     let totals = {
//         years: years,
//         percents: percents
//     }

//     return totals


// }
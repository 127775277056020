
const formatSubjects = (activities) => {

    let activitiesToReturn = []

    activities.map(subject => {
    
        const {hours,achievement,completed,_id,actualDate, ...rest} = subject
        activitiesToReturn.push(rest)
    })

    return activitiesToReturn

}

export default formatSubjects
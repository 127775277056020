import React from 'react'
import { useNavigate } from 'react-router-dom'
import { messageResources } from '../../assets/util/messageResources';
import Messages from '../messages/Messages';

const Unauthorized = () => {

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    return (
        <>
        <div className="row">
            <div className="col-md-12">
                <Messages message={messageResources.BANNER.UNAUTHORIZED} type="error" />
            </div>
        </div>   
        <div className="profile-userbuttons">
            <button onClick={goBack} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">
                {messageResources.BUTTON.GO_BACK}
            </button>
        </div> 
        </>     
)
}
export default Unauthorized
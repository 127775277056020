import React, {useEffect,useState} from 'react'
import ClipboardCopy from '../../components/copyText/ClipboardCopy'
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'


function ChangeMapView( {coords} ) {
    const map = useMap();
    //console.log('ChangeMapView ChangeMapView ChangeMapView')
    //console.log(coords)
    coords && map.setView(coords, map.getZoom());
    return null;
}

const StreetLeafMap = ({slots, address, keyMap, showHeader}) => {

    let coor1 = [Number(address?.lat), Number(address?.long)];
    //console.log(coor1)
    //console.log(address?.address1)

    const [map, setMap] = useState(null);

    useEffect(() => {
		//console.log("<><><><><><> StreetLeafMap RENDERING StreetLeafMap <><><><><><>")
	}, [slots, address]);


    useEffect(() => {
        if (map) {
           setInterval(function () {
              map.invalidateSize();
           }, 100);
        }
     }, [map]);
    
    return (

        <div className={slots}>
            <div className="card">
                {showHeader && <div className="card-head card-topline-aqua">
                    <header>Address</header>
                </div>}
                <div className="card-body no-padding height-9">
                    <div className="row text-center m-t-10">
                        <div className="col-md-12">
                            <p><b><ClipboardCopy copyText={`${address?.address1}  ${address?.address2}  ${address?.postcode}  ${address?.city}`} /></b></p>
                        </div><br/>
                        {/* <div id="map" style={{height: '350px', width: '100%'}}> */}

                        {address?.lat && address?.long &&
                            
                            <MapContainer key={keyMap} style={{ height: "350px", width: "100%" }} center={coor1} zoom={15} scrollWheelZoom={false} whenCreated={setMap}>
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={coor1}>
                                    <Popup>
                                    A pretty CSS3 popup. <br /> Easily customizable.
                                    </Popup>
                                </Marker>
                                {/* <ChangeMapView coords={coor1} /> */}
                            </MapContainer>
                        }

                        {/* </div> */}
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default StreetLeafMap

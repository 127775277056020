import {useEffect} from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'; 
import { messageResources } from '../../assets/util/messageResources';
import logo from '../../assets/img/logo/lettersWhite.png'
import { toast } from 'react-toastify';
import "./navbar.css"
import { useSendLogoutMutation } from '../../redux/auth/authApiSlice';
import { selectAllNotifications } from '../../redux/notifications/notificationsApiCalls'
import { useGetStaffByIdQuery } from '../../redux/staff/staffApiSlice';
import useAuth from '../../hooks/useAuth';
import addLocation from "../../assets/util/addLocation"


const Navbar = () => {

    //console.log("|------> Navbar COMPONENT Navbar <-----|")
    let navigate = useNavigate();

    const { roles, id } = useAuth()
    let cuantas = 0;

    //const [getStaffById] = useGetStaffByIdQuery(id)
    const notis = useSelector(selectAllNotifications)
    //console.log(notis)
    if(notis?.length > 0){

        notis.map(una => {
            if(!una.isProcessed){
                
                una.owner.map(staff => {
                    if(staff._id == id){
                        cuantas++
                    }
                })
                
            }
        })

    }

    const {
        data: user,
    } = useGetStaffByIdQuery(id)

    //console.log(`|-------------> USER USER USER <--------|` )
    //console.log(user)

    const [sendLogout, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useSendLogoutMutation()

    useEffect(() => {
        if(isSuccess) {
            toast.info(messageResources.TOASTY.LOGGED_OUT)
            navigate('/login')
        }
    },[isSuccess, navigate])

    const handleLogout = async () => {
        var toEdit = {}
        console.log("handleLogout handleLogout handleLogout")
        const formEdited = await addLocation(toEdit) 
        console.log(formEdited)
        sendLogout(formEdited)
    };



    return (
    
		<div className="page-header navbar navbar-fixed-top">
        <div className="page-header-inner ">
            {/* <!-- logo start --> */}
            <div className="page-logo">
                <Link to={`/dashboard`}>
                    <img className='varLogo' src={logo} />
                    {/* <span className="logo-icon material-icons fa-rotate-45">school</span>
                    <span className="logo-default"> {messageResources.OPS_TITLES.SEROOTS} </span>  */}
                </Link>
            </div>
            {/* <!-- logo end --> */}
            {/* <!-- start header menu --> */}
            <div className="top-menu">
                <ul className="nav navbar-nav pull-right">
                    
                    <li class="dropdown dropdown-extended dropdown-notification" id="header_notification_bar">
                        {/* <a href="javascript:;" class="dropdown-toggle">
                            <i class="fa fa-bell-o"></i>
                            <span class="badge headerBadgeColor1"> 6 </span>
                        </a> */}
                        {!roles.includes(6) &&  
                            <Link to={`/notifications`} className="dropdown-toggle forDown">
                                <i class="fa fa-bell-o"></i>
                                <span class="badge headerBadgeColor1"> {cuantas} </span>
                            </Link>
                        }
                    </li>    
                    {/* <!-- start manage user dropdown --> */}
                    <li className="dropdown dropdown-user">
                        <a href="" className="dropdown-toggle" id="navbarDropdownProfile" 
                            role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {user && user.picture_path
                                    ? <img alt="" className="img-circle " src={user.picture_path} /> 
                                    : <img alt="" className="img-circle " src="../assets/img/dp.jpg" />}
                            
                            <span className="username username-hide-on-mobile"> {user && user.name} </span>
                            <i className="fa fa-angle-down"></i>
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownProfile" id="userNavbarOpts">
                            <li>
                                {user && !roles.includes(6) &&
                                    <Link to={`/staff/${user._id}`}>
                                        <i className="icon-user"></i> {messageResources.STAFF.MY}
                                    </Link>
                                }
                            </li>
                            <li>
                                {user && 
                                    <Link to="/logout" onClick={handleLogout}> 
                                        <i className="icon-logout"></i> {messageResources.OPS_TITLES.LOGOUT}
                                    </Link>
                                }
                            </li>
                        </ul>
                    </li>
                    {/* <!-- end manage user dropdown --> */}
                </ul>
            </div>
        </div>
        {/* <!-- start horizontal menu --> */}
        <div className="navbar-custom">
            <div className="hor-menu hidden-sm hidden-xs">
                <ul className="nav navbar-nav">
                    
                    
                    <li className="mega-menu-dropdown onlyThisOne">
                        <Link to="/documents" className="dropdown-toggle"> 
                            <i className="material-icons">description</i>
                            <span className="title"> {messageResources.DOCUMENTS.DOCS_MENU} </span>
                        </Link>
                    </li>
                    <li className="mega-menu-dropdown ">
                        <Link to="/students" className="dropdown-toggle"> 
                            <i className="material-icons">local_library</i> {messageResources.COMMON.STUDENTS}
                            <i className="fa fa-angle-down"></i>
                            <span className="arrow "></span>
                        </Link>
                        <ul className="dropdown-menu" style={{ minWidth: '200px', marginTop: '60px' }}>
                            <li>
                                <div className="mega-menu-content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul className="mega-menu-submenu">
                                                <li>
                                                    {/* SIMPLE LINE ICONS file:///Users/manuel/Documents/SERROOTS/smart/source/full/ui_icons.html#tab2 */}
                                                    <Link to="/students">
                                                        <i className="icon-list"/> {messageResources.OPS_TITLES.STUDENTS}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/students/data">
                                                    <i className="icon-paper-clip"/> {messageResources.OPS_TITLES.EXPORT}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/students/ehDates">
                                                    <i className="icon-calendar"/> {messageResources.OPS_TITLES.EHCP_DATES}
                                                    </Link>
                                                </li>
                                                <li>   
                                                    <Link to="/students/cards" >
                                                        <i className="icon-credit-card"/> {messageResources.OPS_TITLES.STU_CARDS}
                                                    </Link>
                                                </li>
                                                {!roles?.includes(6) && 
                                                
                                                    <>
                                                    <li>
                                                        <Link to="/students/add" > 
                                                            <i className="icon-plus"/> {messageResources.OPS_TITLES.STU_ADD}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/students/archive" > 
                                                            <i className="icon-briefcase"/> {messageResources.OPS_TITLES.ARCHIVE}
                                                        </Link>
                                                    </li>
                                                    </>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className="mega-menu-dropdown">
                        <Link to="/academic/current" className="dropdown-toggle"> 
                            <i className="material-icons">school</i> {messageResources.OPS_TITLES.ACADEMIC}
                            <i className="fa fa-angle-down"></i>
                            <span className="arrow "></span>
                        </Link>
                        <ul className="dropdown-menu" style={{ minWidth: '200px', marginTop: '60px' }}>
                            <li>
                                <div className="mega-menu-content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul className="mega-menu-submenu">
                                                <li>
                                                    {/* SIMPLE LINE ICONS file:///Users/manuel/Documents/SERROOTS/smart/source/full/ui_icons.html#tab2 */}
                                                    <Link to="/academic/current" className="dropdown-toggle"> 
                                                        <i className="icon-clock"/> {messageResources.OPS_TITLES.ONGOING}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/academic/completed">
                                                        <i className="icon-badge"/> {messageResources.OPS_TITLES.FINISHED}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/academic/subjects">
                                                        <i className="icon-like"/> {messageResources.OPS_TITLES.ACHIEVEMENTS}
                                                    </Link>
                                                </li>
                                                    {!roles?.includes(6) && 
                                                
                                                        <li>
                                                            <Link to="/academic/archived">
                                                            <i className="icon-drawer"/> {messageResources.OPS_TITLES.ARCHIVED}
                                                            </Link>
                                                        </li>
                                                    }    
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>            
                                        
                    </li>

                    {/* <li className="mega-menu-dropdown onlyThisOne">
                        <Link to="/charts" className="dropdown-toggle"> 
                            <i className="material-icons">tune</i>
                            <span className="title"> {messageResources.OPS_TITLES.CHARTS} </span>
                        </Link>
                    </li> */}

                    <li className="mega-menu-dropdown">
                        <Link to="/charts" className="dropdown-toggle"> 
                            <i className="material-icons">tune</i> {messageResources.OPS_TITLES.CHARTS}
                            <i className="fa fa-angle-down"></i>
                            <span className="arrow "></span>
                        </Link>
                        <ul className="dropdown-menu" style={{ minWidth: '200px', marginTop: '60px' }}>
                            <li>
                                <div className="mega-menu-content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul className="mega-menu-submenu">
                                                <li>
                                                    {/* SIMPLE LINE ICONS file:///Users/manuel/Documents/SERROOTS/smart/source/full/ui_icons.html#tab2 */}
                                                    <Link to="/charts" className="dropdown-toggle"> 
                                                        <i className="icon-bubbles"/> {messageResources.OPS_TITLES.STUDENTS}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/yearCharts">
                                                        <i className="icon-hourglass"/> {messageResources.OPS_TITLES.CHAR_ACADEMIC}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>           
                    </li>

                    {user && user.role && user.role.includes(2) &&
                    <>
                        {/* <li className="mega-menu-dropdown ">
                            <Link to="/staff" className="dropdown-toggle"> 
                                <i className="material-icons">assignment_ind</i>
                                <span className="title"> {messageResources.OPS_TITLES.STAFF_MEMBERS} </span>
                            </Link>
                        </li> */}

                        <li className="mega-menu-dropdown">
                            <Link to="/staff" className="dropdown-toggle"> 
                                <i className="material-icons">lock_open</i> {messageResources.OPS_TITLES.ADMIN}
                                <i className="fa fa-angle-down"></i>
                                <span className="arrow "></span>
                            </Link>
                            <ul className="dropdown-menu" style={{ minWidth: '200px', marginTop: '60px' }}>
                                <li>
                                    <div className="mega-menu-content">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <ul className="mega-menu-submenu">
                                                    <li>
                                                        {/* SIMPLE LINE ICONS file:///Users/manuel/Documents/SERROOTS/smart/source/full/ui_icons.html#tab2 */}
                                                        <Link to="/staff" className="dropdown-toggle"> 
                                                            <i className="icon-clock"/> {messageResources.OPS_TITLES.STAFF_MEMBERS}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/tracker">
                                                            <i className="icon-book-open"/> {messageResources.OPS_TITLES.LOGS}
                                                        </Link>
                                                    </li>
                                                    
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>           
                        </li>

                    </>


                    }
                </ul>
            </div>
        </div>
        {/* <!-- end horizontal menu --> */}
    </div>

    )
}

export default Navbar

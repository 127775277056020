import React from 'react'
import { messageResources } from '../../assets/util/messageResources'
import InfoModal from '../../components/modal/InfoModal'
import EditNokModal from '../../components/modal/EditNokModal'
import { useSelector } from 'react-redux';
import { selectStudentById, useGetStudentByIdQuery } from '../../redux/student/studentSlice';
import useAuth from '../../hooks/useAuth';

const NokCard = ({nokin, entityId, isStuActive}) => {

    //console.log("|-----------> NokCard COMPONENT NokCard <--------|")

    const { roles } = useAuth()

    return (
        <div className="col-lg-6 col-md-12">
            <div className="card card-topline-aqua">
                <div className="card-body no-padding height-9">
                    <div className="profile-usertitle">
                        <div className="profile-usertitle-name">
                            
                            {nokin.name} {nokin.surname} 

                            {(roles?.includes(2) || roles?.includes(3) || roles?.includes(5)) && isStuActive &&
                                <div style={{marginBottom: '10px'}}>

                                    <InfoModal 
                                        buttonText={messageResources.BUTTON.DELETE}
                                        stuId={entityId}
                                        toDeleteId={nokin._id}
                                        methodType="deleteNok"
                                        toastText={messageResources.TOASTY.NOK_DELETED}
                                        header={messageResources.MODAL.DEL_NOK} 
                                        text={messageResources.MODAL.DEL_NOK_TEXT}
                                                    />
                                    
                                    <EditNokModal
                                        buttonText={messageResources.BUTTON.AMEND}
                                        stuId={entityId}
                                        nokToEdit={nokin}
                                        amend={true}
                                        header={messageResources.MODAL.AMEND_NOK} 
                                                    />
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                    <ul className="list-group list-group-unbordered">
                        <li className="list-group-item">
                            <b>{messageResources.NOK.KINSHIP}</b> 
                            <a className="pull-right">{nokin.kinship?.value}</a>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.CONTACT_INFO.NUMBER}</b> 
                            <a className="pull-right">{nokin.contact_number}</a>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.COMMON.EMAIL}</b> 
                            <a className="pull-right">{nokin.email}</a>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.COMMON.ADDRESS}</b> 
                            <a className="pull-right">{nokin.address.address1}, {nokin.address.postcode}, {nokin.address.city}</a>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.COMMON.NOTES}</b> 
                            <a className="pull-right">{nokin.notes}</a>
                        </li>
                    </ul>
                    </div>
                    {/* <div className="profile-userbuttons">
                        <Link to={`/students/edit/${studentInfo._id}`} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">
                            {messageResources.OPS_TITLES.STU_EDIT}
                        </Link>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default NokCard
import React from 'react'
import { messageResources } from '../../../assets/util/messageResources';
import { Controller } from "react-hook-form"
import Select from 'react-select'

const InputSelect = ({slots, label, errors, control, inputName, originalError, errorText, selectOptions, isClearable}) => {

    
    //console.log(Object.keys(errors));
    const keys = Object.keys(errors)
    //console.log(keys.includes(inputName));
    let errorToShow = messageResources.VALIDATIONS.REQUIRED
    if(errorText){
        errorToShow = errorText
    }

    return (
        
        <div className={slots}>
            <div className="form-group">
                <label>{label}
                    {keys.includes(inputName) && <span className="errorSpan">  {originalError ? errors[inputName].message : errorToShow} </span>}
                </label>
                <Controller
                    name={inputName}
                    control={control}
                    render={({ field }) => (
                    <Select options={selectOptions} 
                        menuPortalTarget={document.getElementById('root')}
                        isClearable={isClearable}
                        {...field}
                        />
                    )}
                />
            </div>
        </div>
    )
}

export default InputSelect
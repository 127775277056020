import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (

        <div class="limiter">
            <div class="container-login100 page-background">
                <div class="wrap-login100">
                    <form class="form-404">
                        <span class="login100-form-logo">
                            <i class="twa twa-2x twa-flushed" style={{margin: 0}}></i>
                        </span>
                        <span class="form404-title p-b-34 p-t-27">
                            OOPS!!
                        </span>
                        <p class="content-404">The page you are looking for does't exist or an other error occurred.</p>
                        <div class="container-login100-form-btn">
                            <Link to="/" className="login100-form-btn">
                                Go to home page
                            </Link>
                        </div>
                        <div class="text-center p-t-27">
                            <a class="txt1" href="#">
                                Need Help?
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default NotFound

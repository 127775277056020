import React from 'react'
import { messageResources } from '../../assets/util/messageResources'
import Moment from 'moment'


const printList = (list) => {

    var toPrint = "";
    list.map((item) => {
        toPrint += `(${item.value}) ${item.label} `
        //console.log(toPrint)
    })   
    //console.log(toPrint)
    return toPrint;                                   
}

const MonoDataCard = ({message,isDate,data,slots,isList,isLink,isTitle}) => {
    return (
        <div className={slots}>
            <div className="card card-topline-aqua">
                <div className="card-body no-padding height-9">
                    <div className="profile-usertitle">
                        <div className="profile-usertitle-name">{message}</div>
                    </div>
                    {isTitle 
                    
                    ?
                    
                        <ul className="list-group list-group-unbordered">
                            <li className='centerText'>
                                
                                {data}
                                
                            </li>
                        </ul>
                    :
                        <ul className="list-group list-group-unbordered">
                            <li className='centerText'>
                                
                                {data 
                                    ? 
                                        isDate 
                                            ? 
                                                Moment(data).format('DD MMMM YYYY')
                                            :
                                                isList
                                                    ?
                                                    printList(data)
                                                    : 
                                                        isLink
                                                            ?
                                                            <a target="_blank" href={data}> {messageResources.COMMON.OPEN} </a>
                                                            :
                                                            data
                                                    
                                    : messageResources.COMMON.NA
                                }
                                
                            </li>
                        </ul>
                    }
                </div>
            </div>
        </div>
    )
}

export default MonoDataCard
import {useRef, useEffect, useState} from 'react'
import InputSelect from '../../components/forms/Input/InputSelect'
import InputText from '../../components/forms/Input/InputText'
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker'
import { toast } from 'react-toastify';
import { useForm, Controller, FormProvider } from "react-hook-form"
import {useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from "@hookform/resolvers/yup"
import { addAssessmentSchema } from '../../assets/util/yupValidations'
import { messageResources } from '../../assets/util/messageResources'
import { englishExemOptions, mathExemOptions, 
    mathOptions, englishOptions, resultOptions, attainmentOptions} from '../../assets/util/selectOptions'
import { useEditAssessmentFromStudentMutation, selectStudentById
            , useAddGooDocumentFromStudentMutation, useUpdateGooDocumentFromStudentMutation
            , useEditAssessmentFromStudentInvalidateMutation } from '../../redux/student/studentSlice'


const Assessment = () => {


    function SortArray(x, y){
        return x.label.localeCompare(y.label);
    }

    const englishOptionsSorted = englishOptions.sort(SortArray)
    const attainmentOptionsSorted = attainmentOptions.sort(SortArray);

    const {studentId,action} = useParams()
    const [render,setRender] = useState(false);
	
    let assessmentBtnRef = useRef();
    let navigate = useNavigate()
    const studentInfo = useSelector((state) => selectStudentById(state, studentId))
    
    const [editAssessmentFromStudent] = useEditAssessmentFromStudentMutation()
    const [updateGooDocumentFromStudent] = useUpdateGooDocumentFromStudentMutation()
    const [addGooDocumentFromStudent] = useAddGooDocumentFromStudentMutation()
    const [editAssessmentInvalidate] = useEditAssessmentFromStudentInvalidateMutation()


    useEffect(() => {
        var assessmentButtonDiv = document.getElementById("fueraAssess")
        if(assessmentButtonDiv) assessmentButtonDiv.style.display = 'none'
        reset(studentInfo?.assessment);
        setRender(true);
    }, [studentInfo]);

    let buttonText;
    
    if(action == 'edit'){
        buttonText = messageResources.BUTTON.AMEND_ASS
    }else{
        buttonText = messageResources.BUTTON.ADD_ASS
    }


    const methods = useForm({
        resolver: yupResolver(addAssessmentSchema) ,
    });

    const {
        register,
        handleSubmit,
        reset,
        control,
        watch,
        getValues,
        formState: { errors },
    } = methods;

    console.log(errors)



    const onSubmit = async () => {

        if(assessmentBtnRef.current){
            assessmentBtnRef.current.setAttribute("disabled", "disabled");
        }

        const values = getValues()

        values.stuId = studentId
        values.stuName = studentInfo.name+" "+studentInfo.surname

        try {


            if(values.ass_link != ""){

                // IF YOU INVALIDATE TAGS AND WANTS TO RETRIEVE THE STUDENT
                // DETAILS AFTER ALL IS DONE, YOU NEED TO INVALIDATE TAGS ONLY IN 
                // THE LAST METHOD 
                // IN THIS CASE IF I INVALIDATE TAGS IN editAssessmentFromStudent
                // THEN RTK QUERY WILL CALL /stuydentsApi/true ONLY ONCE AND 
                // BEFORE THE DOC IS ADDED TO THE STUDENT SO IT WILL NOT SHOW
                // IN ORDER TO WORK PROPERLY WE ONLY INCLUDE INVALIDATE TAGS 
                // IN updateGooDocumentFromStudent AND addGooDocumentFromStudent
                await editAssessmentFromStudent(values).unwrap()

                let addNew = true;
                
                studentInfo.gooDocuments.map(async docu => {
                    //console.log("|---------> STU DOCS <><><><><><>")
                    //let docId = (docu._id).toString()

                    if(docu.type.value == `${messageResources.DOCUMENTS.INITIAL}`){
                        //console.log(docId)
                        await updateGooDocumentFromStudent({id: docu._id, url_link: values.ass_link}).unwrap()
                        addNew = false;
                    }
                })

                //console.log(addNew)

                if(addNew){

                    const gooDoc = {}
                    gooDoc.type = {}
                    gooDoc.type.value = `${messageResources.DOCUMENTS.INITIAL}`
                    gooDoc.type.label = `${messageResources.DOCUMENTS.INITIAL}`
                    gooDoc.description = `${messageResources.DOCUMENTS.AUTO_DESC}`
                    gooDoc.url_link = values.ass_link
                    gooDoc.stuId = studentId
    
                    await addGooDocumentFromStudent(gooDoc).unwrap()
                    
                    
                }
            }else {

                // NOTHING TO DO WITH DOCUMENT SO WE EDIT
                // ASSESSMENT WITH THE METHOD THAT INVALIDATES STUDENT
                // TAG AND REFRESH THE INFO FOR THE STUDENT 
                await editAssessmentInvalidate(values).unwrap()

            }


            if(action == 'edit'){
                toast.success(messageResources.TOASTY.ASSESSMENT_EDIT)
            }else{
                toast.success(messageResources.TOASTY.ASSESSMENT_ADDED)
            }


            if(assessmentBtnRef.current){
                //console.log("------> DISABLE BUTTON <---------")
                assessmentBtnRef.current.removeAttribute("disabled");
            }

            navigate(`/students/${studentId}`)

        }catch(err){
            console.log(err)
        }

    }    
    

    return (
        <div className="row assess" id="assessmentDiv">
                            
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                
                    <div className="card card-box">
                        <div className="card-body " id="bar-parent">
                            <div className="row">
                            
                                <InputSelect 
                                        slots="col-md-6" 
                                        label={messageResources.PROGRAMME.MATH_EXEM} 
                                        errors={errors}
                                        control={control}
                                        inputName="mathsExem"
                                        original={false}
                                        selectOptions={mathExemOptions}
                                    />
                                
                                <InputSelect 
                                        slots="col-md-6" 
                                        label={messageResources.PROGRAMME.ENG_EXEM} 
                                        errors={errors}
                                        control={control}
                                        inputName="engExem"
                                        original={false}
                                        selectOptions={englishExemOptions}
                                    />
                            </div>
                            <div className="row"> 
                                <InputSelect 
                                        slots="col-md-3" 
                                        label={messageResources.PROGRAMME.CGSE_MATH} 
                                        errors={errors}
                                        control={control}
                                        inputName="gcseMath"
                                        original={false}
                                        isClearable={true}
                                        selectOptions={mathOptions}
                                    />                                                      
                                <InputSelect 
                                        slots="col-md-3" 
                                        label={messageResources.PROGRAMME.CGSE_ENG} 
                                        errors={errors}
                                        control={control}
                                        inputName="gcseEng"
                                        original={false}
                                        isClearable={true}
                                        selectOptions={englishOptionsSorted}
                                    />

                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>{messageResources.ASSESSMENT.ENG_COMPLETED}
                                            {/* {errors.engDate && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>} */}
                                        </label>
                                        <Controller
                                            control={control}
                                            name='engDate'
                                            id="engDate"
                                            render={({ field }) => (
                                                <DatePicker
                                                    onChange={(date) => field.onChange(date)}
                                                    dateFormat="d MMMM yyyy"
                                                    selected={field.value ? field.value : Date.parse(studentInfo?.assessment?.engDate) }
                                                    placeholderText="Select Date"
                                                    peekNextMonth
                                                    portalId="root-portal"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <InputSelect 
                                    slots="col-md-2" 
                                    label={messageResources.ASSESSMENT.ENG_RESULTS} 
                                    errors={errors}
                                    control={control}
                                    inputName="engResults"
                                    original={false}
                                    isClearable={true}
                                    selectOptions={resultOptions}
                                />
                            </div>
                            <div className="row">
                            
                                
                    
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>{messageResources.ASSESSMENT.MATHS_COMPLETED}
                                            {/* {errors.mathsDate && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>} */}
                                        </label>
                                        <Controller
                                            control={control}
                                            name='mathsDate'
                                            id="mathsDate"
                                            render={({ field }) => (
                                                <DatePicker
                                                    onChange={(date) => field.onChange(date)}
                                                    dateFormat="d MMMM yyyy"
                                                    selected={field.value ? field.value : Date.parse(studentInfo?.assessment?.mathsDate) }
                                                    placeholderText="Select Date"
                                                    peekNextMonth
                                                    portalId="root-portal"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <InputSelect 
                                    slots="col-md-2" 
                                    label={messageResources.ASSESSMENT.MATHS_RESULTS} 
                                    errors={errors}
                                    control={control}
                                    inputName="mathsResults"
                                    original={false}
                                    isClearable={true}
                                    selectOptions={resultOptions}
                                />

                                <InputSelect 
                                        slots="col-md-3" 
                                        label={messageResources.PROGRAMME.PRIOR} 
                                        errors={errors}
                                        control={control}
                                        inputName="priorAtt"
                                        original={false}
                                        isClearable={true}
                                        selectOptions={attainmentOptionsSorted}
                                    />

                                <InputText 
                                    slots="col-md-5"
                                    label={messageResources.ASSESSMENT.LINK}
                                    errors={errors}
                                    register={register}
                                    type="string"
                                    plaHolder={messageResources.ASSESSMENT.LINK}
                                    inputName="ass_link"
                                    original={true}
                                />

                            

                            </div>

                            
                                
                            <div className="col-md-1">
                                <button type="submit" 
                                        style={{marginTop:"25px"}} 
                                        className="btn btn-primary" 
                                        ref={assessmentBtnRef} 
                                        onClick={methods.handleSubmit(onSubmit)}
                                >   
                                    {buttonText}

                                </button>
                            </div>
                            
                        </div>
                    </div>
                    </form>
            </FormProvider>
        </div>

    )


}

export default Assessment
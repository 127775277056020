import React from 'react'
import ClipboardCopy from '../../components/copyText/ClipboardCopy'
import ProcessNotiModal from '../modal/ProcessNotiModal'
import { useSelector } from 'react-redux'
import { selectNotificationById } from '../../redux/notifications/notificationsApiCalls'
import { messageResources } from '../../assets/util/messageResources'
import Moment from 'moment'

const AboutStudentCard = ({studentInfo, notificationId}) => {


    const printListComa = (list) => {
	
        var toPrint = "";
        list?.map((item) => {
            toPrint += `${item.label}, `
            //console.log(toPrint)
        })   
    
        if(toPrint.trim().endsWith(',')) { return toPrint.slice(0, -2)} else { return toPrint }
                            
    }

    
    let notitoProcess = useSelector(state => selectNotificationById(state, notificationId))

    //console.log(notitoProcess)

    return (
        <div className="col-lg-4 col-md-12" >
            <div className="card" >
                <div className="card-head card-topline-aqua"> 
                    <header>{messageResources.STUDENT.ABOUT}</header>
                </div>
                <div className="card-body no-padding height-9" style={{height: "460px"}}>
                    {/* <div className="profile-desc">
                        Hello I am Sarah Smith a student in Sanjivni College Surat. I love to study
                        with all my class friends and
                        professors.
                    </div> */}
                    <ul className="list-group list-group-unbordered">
                        <li className="list-group-item">
                            <b>{messageResources.COMMON.EMAIL} </b>
                            <ClipboardCopy copyText={studentInfo?.email} cName="profile-desc-item pull-right" element="div" bClass="pull-right"/>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.STUDENT.NINS} </b>

                            {notitoProcess && notitoProcess.type ==6 
                                ?
                                    <ProcessNotiModal
                                        buttonText={messageResources.MODAL.NO_NIN.toUpperCase()}
                                        notificationId={notitoProcess._id}
                                        noType="nin"
                                        toastText={messageResources.TOASTY.NOTI_PROCESS}
                                        header={messageResources.MODAL.NO_NIN} 
                                        text={messageResources.MODAL.NO_NIN_TEXT}
                                    />
                                :
                                <ClipboardCopy copyText={studentInfo?.nin} cName="profile-desc-item pull-right" element="div" bClass="pull-right"/>
                            }
                            
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.DOCUMENTS.PROFILE}</b>
                            {/* <ClipboardCopy copyText={studentInfo.lrs} cName="profile-desc-item pull-right" element="div" bClass="pull-right"/> */}
                            <div className="profile-desc-item pull-right">
                                {
                                    studentInfo?.profile_link 
                                    ? 
                                    <a target="_blank" href={studentInfo?.profile_link}> {messageResources.COMMON.OPEN} </a>
                                    :
                                    
                                    messageResources.COMMON.NO_LINK
                                }
                            </div>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.STUDENT.LRS}</b>
                            {/* <ClipboardCopy copyText={studentInfo.lrs} cName="profile-desc-item pull-right" element="div" bClass="pull-right"/> */}
                            <div className="profile-desc-item pull-right">
                                {
                                    studentInfo?.lrs 
                                    ? 
                                    <a target="_blank" href={studentInfo?.lrs}> {messageResources.COMMON.OPEN} </a>
                                    :
                                    
                                    messageResources.COMMON.NO_LINK
                                }
                            </div>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.STUDENT.DOB_CERT}</b>
                            <div className="profile-desc-item pull-right">
                                {
                                    studentInfo?.cert_path 
                                    ? 
                                    <a target="_blank" href={studentInfo?.cert_path}> {messageResources.COMMON.OPEN} </a>
                                    :
                                    messageResources.COMMON.NO_LINK
                                }
                            </div>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.STUDENT.PASSPORT_NUM}</b>
                            <ClipboardCopy copyText={studentInfo?.passport_number} cName="profile-desc-item pull-right" element="div" bClass="pull-right"/>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.STUDENT.PASSPORT_EXPIRY}</b>
                            <ClipboardCopy copyText={studentInfo?.passport_expiry ? Moment(studentInfo?.passport_expiry).format('DD MMMM YYYY') : ""} cName="profile-desc-item pull-right" element="div" bClass="pull-right"/>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.STUDENT.PASSPORT_DOC}</b>
                            <div className="profile-desc-item pull-right">
                                {
                                    studentInfo?.pass_path 
                                    ? 
                                    <a target="_blank" href={studentInfo?.pass_path}> {messageResources.COMMON.OPEN} </a>
                                    :
                                    messageResources.COMMON.NO_LINK
                                }
                            </div>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.STUDENT.PHOTO}</b>
                            <div className="profile-desc-item pull-right">
                                {
                                    studentInfo?.isPhoto 
                                    ? 
                                    messageResources.COMMON.YES
                                    :
                                    messageResources.COMMON.NO
                                }
                            </div>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.STUDENT.PASSPORT_UK}</b>
                            <div className="profile-desc-item pull-right">
                                {
                                    studentInfo?.isUkPassport 
                                    ? 
                                    messageResources.COMMON.YES
                                    :
                                    messageResources.COMMON.NO
                                }
                            </div>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.STUDENT.LANG}</b>
                            <ClipboardCopy copyText={printListComa(studentInfo?.languages)} cName="profile-desc-item pull-right" element="div" bClass="pull-right"/>
                        </li>
                    </ul>
                    {/* <div className="row list-separated profile-stat">
                        <div className="col-md-4 col-sm-4 col-6">
                            <div className="uppercase profile-stat-title"> 37 </div>
                            <div className="uppercase profile-stat-text"> Projects </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-6">
                            <div className="uppercase profile-stat-title"> 51 </div>
                            <div className="uppercase profile-stat-text"> Tasks </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-6">
                            <div className="uppercase profile-stat-title"> 61 </div>
                            <div className="uppercase profile-stat-text"> Uploads </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default AboutStudentCard
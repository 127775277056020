import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from "../api/apiSlice"

const documentsAdapter = createEntityAdapter({})

const initialState = documentsAdapter.getInitialState()

export const documentsApiSlice = apiSlice.injectEndpoints({

    endpoints: builder => ({
        getGooDocuments: builder.query({
            query: () => '/docs',
            transformResponse: responseData => {

                const loadedDocs = responseData.map(doc => {
                    // ESSENTIAL TO USE NORMALIZATION AND MONGO DB
                    doc.id = doc._id
                    return doc
                })

                return documentsAdapter.setAll(initialState, loadedDocs)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids){
                    return [
                        { type: 'GooDocuments', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'GooDocuments', id}))
                    ]
                } else return [{ type: 'GooDocuments', id: 'LIST'}]
                
            }
        }),
        addGooDocument: builder.mutation({
            query: docData => ({
                url: '/docs/add',
                method: 'POST',
                body: {
                    ...docData
                }
            }),
            invalidatesTags: [
                { type: 'GooDocuments', id: 'LIST'}
            ]
        }),
        updateGooDocument: builder.mutation({
            query: docToUpdate => ({
                url: `/docs/${docToUpdate.id}`,
                method: 'PUT',
                body: { ...docToUpdate }
            }),
            // async onQueryStarted(arg, { dispatch, queryFulfilled}) {
            //     try {
            //         // in this case data would be "cookie cleared" as we
            //         // set in the api controller
            //         console.log("|---> updateGooDocument updateGooDocument <-----|")
            //         console.log(arg)
            //     }catch(err){
            //         console.log(err)
            //     }   
            // },
            invalidatesTags: (result, error, arg) => [
                { type: 'GooDocuments', id: arg.id }
            ]    
        })
    })
})

export const {
    useAddGooDocumentMutation,
    useGetGooDocumentsQuery,
    useUpdateGooDocumentMutation
} = documentsApiSlice



// // returns the query result object
export const selectGooDocumentsResult = documentsApiSlice.endpoints.getGooDocuments.select()

// // creates memoized selector
// // used below to create selectors
const selectDocumentsData = createSelector(
    selectGooDocumentsResult,
    gooDocumentsResult => gooDocumentsResult.data // normalized state object ids & entities
)

export const {
    selectAll: selectAllGooDocuments,
    selectById: selectGooDocumentById,
    selectIds: selectGooDocumentsIds
} = documentsAdapter.getSelectors(state => selectDocumentsData(state) ?? initialState)





// //RTK Query replaces axios and pulls that code out of the 
// //component logic 
// // export const notificationsApiCalls = createApi({
// //     // this is the default value so can be removed
// //     reducerPath: 'api',
// //     baseQuery: fetchBaseQuery({ baseUrl: 'http://localhost:8800/serruts/'}),
// //     // the results of RTK queries get cached so we need to invalidate the previous 
// //     // query results in order to see the updated results from the below mutations
// //     // so we assign a tag to the cache and we let it know what mutations will invalidate that chache
// //     // and then it'll automatically fetch the updated data 
// //     tagTypes: ['Notifications'],
// //     // here we define methods to interact with the api
// //     endpoints: (builder) => ({
// //         getNotification: builder.query({
// //             query: () => '/notifications',
// //             // we can manipulate the response in this case sorting by id
// //             transformResponse: res => res.sort((a,b) => b.id - a.id),
// //             // giving a tag to this query to be referenced in the mutations to invalidate
// //             // its cache when executing the mutations below
// //             providesTags: ['Notifications']
// //         }),
// //         // herer we use mutation vause we are changing the data 
// //         addNotification: builder.mutation({
// //             query: (notification) => ({
// //                 url: '/notifications/add',
// //                 method: 'POST',
// //                 body: notification
// //             }),
// //             // invalidate cache from providesTags: ['Notifications']
// //             // when executing this mutation
// //             invalidatesTags: ['Notifications']
// //         }),
// //         updateNotification: builder.mutation({
// //             query: (notification) => ({
// //                 url: `/notifications/${notification.id}`,
// //                 // PATCH WHEN YOU EDIT PART OF THE ENTITY
// //                 // AND PUT WHEN YOU EDIT ALL OF IT
// //                 // YOU CAN USE EITHER OF THEM
// //                 method: 'PUT',
// //                 body: notification
// //             }),
// //             invalidatesTags: ['Notifications']
// //         }),
// //         deleteNotification: builder.mutation({
// //             query: ({ idNoti }) => ({
// //                 url: `/notifications/${idNoti}`,
// //                 method: 'DELETE',
// //                 body: idNoti
// //             }),
// //             invalidatesTags: ['Notifications']
// //         })
// //     })
// // })


// //we can create custom hooks based on the methods created above
// // export const {
// //     useGetNotificationQuery,
// //     useAddNotificationMutation,
// //     useUpdateNotificationMutation,
// //     useDeleteNotificationMutation
// // } = notificationsApiCalls

















// // export const totalNotifications = async (idUser,dispatch) => {

// //     //dispatch(loginStart());
// //     // const userId = user.email
// //     // const password = user.password
// //     console.log("<><><><><><> TOTAL NOTIFICATIONS IN REDUX CALLS <><><><><><>")
// //     console.log(idUser)

// //     try {

// //         const notis = await axios.get(`/notifications/toProcess/${idUser}`);

// //         console.log(`SIZE OF NOTIS ${notis.data.length}`)
// //         dispatch(notificationsTotal(notis.data.length)); 

// //         // let notisToShow = []
// //         // console.log("<><><><><><> BEFORE MAP <><><><><><>")
// //         // if(notis.data.length > 0){

// //         //     notis.data.map((noti) => {
// //         //         let notiToList = {}
// //         //         notiToList.type = noti.type.id
// //         //         notiToList.desc = noti.type.desc
// //         //         notiToList.message = noti.message
// //         //         notiToList.priority = noti.priority
// //         //         notiToList.studentId = noti.studentId
// //         //         notisToShow.push(notiToList)
// //         //     })

// //         // }
// //         // console.log("<><><><><><> AFTER MAP <><><><><><>")
// //         // console.log(notisToShow)

// //         console.log("<><><><><><> NOTIFICATIONS REDUX RESPONSE <><><><><><>")
// //         console.log(notis)

// //         //dispatch(notificationsToProcess(notis))

// //         console.log("<><><><><><> NOTIFICATIONS REDUX LIST <><><><><><>")
// //         console.log(notis.data)

// //         return notis.data.length

// //     }catch(err){
// //         console.log(err)
// //         dispatch(notificationsError());
// //     }

// // }



export const currentAcademicYear = () => {

    const today = new Date()
    const thisYear = today.getFullYear()
    let year

    const startAcaYear = new Date(thisYear, 7, 31) // first of September of startDateProgram
    const endAcaYear = new Date(thisYear+1, 7, 30) // 31st of August next year for program

    if(today > startAcaYear && today < endAcaYear){ 
        // start date between academic year start and end
        year = `${parseInt(thisYear)}/${parseInt(thisYear)+1}`
    }else if(today < startAcaYear){
        year = `${parseInt(thisYear)-1}/${parseInt(thisYear)}`
    }
    
    //console.log(year)

    return year

}


export const previousAcademicYear = () => {

    const today = new Date()
    const thisYear = today.getFullYear()
    let year

    const startAcaYear = new Date(thisYear, 7, 31) // first of September of startDateProgram
    const endAcaYear = new Date(thisYear+1, 7, 30) // 31st of August next year for program

    if(today > startAcaYear && today < endAcaYear){ 
        // start date between academic year start and end
        year = `${parseInt(thisYear)-1}/${parseInt(thisYear)}`
    }else if(today < startAcaYear){
        year = `${parseInt(thisYear)-2}/${parseInt(thisYear)-1}`
    }
    
    //console.log(year)

    return year
}


export const isFirstHalfOfYear = () => {

    const today = new Date()
    const thisYear = today.getFullYear()
    const firstSeptember = new Date(thisYear, 7, 31) // first of September this year

    if(today < firstSeptember){
        return true
    }else{
        return false
    }

}
import React, {useEffect, useRef, useState} from 'react'
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useParams} from "react-router-dom";
import { messageResources } from '../../assets/util/messageResources'
import { loginSchema } from '../../assets/util/yupValidations'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'; 
import axios from 'axios'
import { setCredentials } from '../../redux/auth/authSlice';
import { useLoginMutation } from '../../redux/auth/authApiSlice';
import lettersOnly from '../../assets/img/logo/lettersOnlyWhite.png'
import addLocation from "../../assets/util/addLocation"
import treeOnly from '../../assets/img/logo/treeOnlyWhite3.png'
import ReCAPTCHA from 'react-google-recaptcha'
import "../../assets/css/pages/extra_pages.css"



const Login = () => {

    //console.log("|--------> LOGIN LOGIN <----------|")
    const dispatch = useDispatch();
    const recaptRef = useRef();
    const passRef = useRef()

    const[eye,seteye]=useState(true);
    const[password,setpassword]=useState("password");

    const Eye=()=>{
        if(password=="password"){
            setpassword("text");
            seteye(false);
        }
        else{
            setpassword("password");
            seteye(true);
        }
    }


    const [login] = useLoginMutation()

    // take the previous value and set it to the oposite
    //const handleToggle = () => setPersist(prev => !prev)

    const { register, handleSubmit, watch, reset, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(loginSchema),
    });


    const onSubmit = async (formData) => { 

        try {

    
        //console.log(formData);
        console.log("|-------> NO ERRORS <--------|")

        const recaptToken = await recaptRef.current.executeAsync();
        // it allows to reexecute the recaptcha check 
        recaptRef.current.reset();

        //console.log(recaptToken)
        formData.recaptToken = recaptToken
        formData.email = formData.email.toLowerCase();

        //console.log("|-------> formData formData <--------|")
        //console.log(formData)
        // OLD CALL
        //const accessToken = await authUser(formData, recaptRef, dispatch)
        
        //const res = await axios.get('https://geolocation-db.com/json/')

        const res = await axios.get('https://api.ipify.org/?format=json')

        //console.log(res.data.ip);
        formData.ip = res.data.ip

        const formEdited = await addLocation(formData) 

        // NEW CALL
        const { accessToken, text } = await login(formEdited).unwrap()

        // console.log("|-------> accessToken accessToken <--------|")
        // console.log(accessToken)

        if(accessToken){ // && userStatus === 'idle'

            // we can add .unwrap() at the end of the dispatch 
            // so redux can provide either a promise with the action payload 
            // or throws error if it's the rejected action
            //console.log("DISPATCHING fetchUser")
            //dispatch(fetchUser(formData.email)).unwrap()
            //console.log(`USER STATUS: ${userStatus}`)
            // if(userStatus === 'succeeded'){
            //     console.log("USER SHOULD BE IN REDUX NOW")

            //     if(notisStatus === 'idle'){
            //         dispatch(fetchMyNotisToProcess(userIn.id))
            //     }
            // }else{

            // }

            dispatch(setCredentials({accessToken}))
            toast.success(messageResources.TOASTY.LOGIN_SUCCESS)
            navigate('/dashboard')

        }else{

            var toShow = ""
            text != null && text != "" && text ==  "userDisabled" ? toShow = messageResources.TOASTY.DISABLED : toShow = messageResources.TOASTY.LOGIN_ERROR

            toast.error(toShow,{
                autoClose: 10002,
            })


        }

    }catch(err){
        console.log(err)
    }

    
    };

    toast.configure({
        pauseOnFocusLoss: false
    });


    const {text} = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        // only when verifying from link
        
        //console.log("TEXT TEXT TEXT TEXT ")
        //console.log(text)
        if(text && text !== ""){
            
            if(text === "verified"){

                toast.success(messageResources.TOASTY.VERIFIED,{
                    autoClose: 12002,
                })

            }else if(text === "verifiedBefore"){
            
                toast.error(messageResources.TOASTY.VERIFIED_BEFORE,{
                    autoClose: 12002,
                })

            }else if(text === "linkExpired"){
            
                toast.error(messageResources.TOASTY.LINKED_EXPIRED,{
                    autoClose: 12002,
                })
            }
            // we are coming from /verify so we need /login 
            navigate("/login")

        }
    },[])


    return (
        
        <>
            <div className="limiter">
                <div className="container-login100 page-background" >
                {/* <pre>{JSON.stringify(errors, undefined, 2)}</pre> */}
                    <div className="wrap-login100">
                        <form className="login100-form validate-form" onSubmit={handleSubmit(onSubmit)}>
                            <div className='d-flex justify-content-center'>
                                    <img src={treeOnly} />
                            </div> 
                            <div className='d-flex justify-content-center'>
                                    <img src={lettersOnly} />
                                    {/* <i class="twa twa-2x twa-grimacing" style={{margin: 0}}></i> */}
                            </div>
                            <span className="login100-form-title p-b-34 p-t-27">
                                {messageResources.LOGIN.SIGN}
                            </span>
                            <div className="row">
                                {errors.email && <span className="errorSpanOut"> {errors.email.message} </span>}
                                <div className="wrap-input100 validate-input">
                                    <input className="input100" 
                                        type="text"
                                       //value="eiranovahrt@gmail.com"
                                        // autoComplete='off'
                                        placeholder={messageResources.COMMON.EMAIL} 
                                        {...register('email')}
                                    />
                                    <span className="focus-input100" data-placeholder="&#xf207;"></span>
                                </div>
                                {errors.password && <span className="errorSpanOut"> {errors.password.message} </span>}
                                <div className="wrap-input100 validate-input" style={{ display: 'flex'}}>
                                    <input  className="input100" ref={passRef}
                                        type={password} 
                                        placeholder={messageResources.COMMON.PASSWORD}
                                        {...register('password')}
                                    />
                                    <span className="focus-input100" data-placeholder="&#xf191;"></span>
                                    <i onClick={Eye} style={{ color: '#fff', fontSize: '22px', lineHeight: '46px' }} className={`fa ${eye ? "fa-eye-slash" : "fa-eye" }`}></i>
                                </div>

                                {/* <div >
                                    <input  className="input100" 
                                        type="checkbox" 
                                        placeholder={messageResources.LOGIN.TRUST}
                                        id="persist"
                                        onChange={handleToggle}
                                        checked={persist}
                                        //{...register('password')}
                                    />
                                    <span className="focus-input100" data-placeholder="&#xf191;"></span>
                                </div> */}

                                <ReCAPTCHA  sitekey='6LfPnGwgAAAAAF3e8lQCTEzMRvJ7Cl6yACMwNZWU'
                                            size='invisible'
                                            ref={recaptRef} 
                                />

                                <div className="container-login100-form-btn">
                            
                                        <button class="login100-form-btn" onClick={handleSubmit(onSubmit)}>
                                                {messageResources.LOGIN.LOGIN}
                                        </button>
                                    {/* {isLoading 
                                        ?   <CircularProgress /> 
                                        :   <button class="login100-form-btn" onClick={handleSubmit(onSubmit)}>
                                                    {messageResources.LOGIN.LOGIN}
                                            </button>
                                    } */}

                                </div>
                            </div>    
                            <div className="text-center p-t-30">
                                <a href="/register" className="txt1">
                                    {messageResources.LOGIN.REGISTER}
                                </a>
                            </div>
                            <div className="text-center p-t-30">
                                <a href="/forgot" className="txt1">
                                    {messageResources.LOGIN.FORGOT}
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login

import React, {useEffect,useState} from 'react'
import { useFormContext } from "react-hook-form"
import { messageResources } from '../../../assets/util/messageResources'



const ContactInfo = ({contactData, pathField}) => {

    const { register, formState: { errors }, reset } = useFormContext();

    const [reder,setRender] = useState();

    useEffect(() => {
		reset(contactData)
        setRender(true);
	},[contactData]);

    
    return (
        <div className="card card-box">
            {/* <div className="card-head">
                <header>{contactData?.email && messageResources.COMMON.STEP2 } {messageResources.CONTACT_INFO.HEADER}</header>
                <div className="tools">
                    <a className="t-collapse btn-color fa fa-chevron-down"
                        href="javascript:;"></a>
                </div>
            </div> */}
            <div className="card-body " id="bar-parent">
            
                    <div className="row">
            
                        <div className="col-md-5">
                            <div className="form-group">
                                {pathField=="nokAddress" 
                                    ? 
                                    <label >{messageResources.CONTACT_INFO.ADDRESS_1}
                                        {errors.nokAddress?.address1 && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                    </label> 
                                    :
                                    <label >{messageResources.CONTACT_INFO.ADDRESS_1}
                                        {errors.address?.address1 && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}   
                                    </label>
                                }
                                <input 
                                    type="text" 
                                    className="form-control"
                                    {...register(`${pathField}.address1`)}
                                    placeholder={messageResources.CONTACT_INFO.ADDRESS_1}
                                /> 
                                
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="form-group">
                                <label for="simpleFormEmail">{messageResources.CONTACT_INFO.ADDRESS_2}</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    {...register(`${pathField}.address2`)}
                                    placeholder={messageResources.CONTACT_INFO.ADDRESS_2}/>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                {pathField=="nokAddress" 
                                    ? 
                                    <label >{messageResources.CONTACT_INFO.POSTCODE}
                                        {/* {errors.nokAddress?.postcode && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>} */}
                                        {errors.nokAddress?.postcode && <span className="errorSpan">  {errors.nokAddress.postcode.message} </span>}
                                    </label> 
                                    :
                                    <label >{messageResources.CONTACT_INFO.POSTCODE}
                                        {/* {errors.address?.postcode && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}    */}
                                        {errors.address?.postcode && <span className="errorSpan">  {errors.address.postcode.message} </span>}   
                                    </label>
                                }   
                        
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    {...register(`${pathField}.postcode`)}
                                    placeholder={messageResources.CONTACT_INFO.POSTCODE}/>
                            </div>
                        </div>
                    </div>
                    
            </div>
        </div>
    )
}

export default ContactInfo

import React, {useState,useEffect} from "react";
import {Modal, Button} from 'react-bootstrap';
import axios from 'axios'
import addLocation from "../../assets/util/addLocation"
import { messageResources } from "../../assets/util/messageResources";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from "react-hook-form"
import { supportSchemaModal } from '../../assets/util/yupValidations'
import { yupResolver } from "@hookform/resolvers/yup"
import formatData from "../../assets/util/formatData"
import useAuth from "../../hooks/useAuth";
import numberDaysDifference from "../../assets/util/numberDaysDifference"
import { selectStudentById, useAddSupportFormStudentMutation,
    useEditSupportFromStudentMutation } from "../../redux/student/studentSlice";
import { useUpdateNotificationMutation, 
    selectAllNotifications } from "../../redux/notifications/notificationsApiCalls";



export default function EditSupportModal({buttonText, header, stuId, supportToEdit, amend}) {

    //console.log("|----> EditSupportModal COMPONENT EditSupportModal <-------|")

    const {id} = useAuth()

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const studentFormState = useSelector((state) => selectStudentById(state, stuId))

    useEffect(() => {
		reset(supportToEdit)
	}, [supportToEdit]);

    const methods = useForm({
        //defaultValues: studentData,
        resolver: yupResolver(supportSchemaModal),
    });

    const notis = useSelector(selectAllNotifications)
    const [updateNotification] = useUpdateNotificationMutation()
    const [addSupportFormStudent] = useAddSupportFormStudentMutation()
    const [editSupportFromStudent] = useEditSupportFromStudentMutation()

	const {
        register,
        handleSubmit,
        reset,
		control,
        formState: { errors },
    } = methods;


    //console.log(errors)

    const onSubmit = async (formData) => {

        
        console.log("NO ERRORS")

        //console.log(formData);

		try{

            formData.stuId = stuId
            formData.stuName = studentFormState?.name+" "+studentFormState?.surname
            await addLocation(formData) 
            getLatLonPic(formData);

		}catch(err){
			console.log(err);
		}
	}



    const getLatLonPic = async (formData) => {

        const resFromMaps = await axios.get(`
            https://api.opencagedata.com/geocode/v1/json?q=${formData.address1} ${formData.address2} ${formData.postcode} London United Kingdom&key=c0348377c7af49c29b5b501efa40474b
        `)
        
        //console.log(resFromMaps);
        // console.log(resFromMaps?.data.results[0].geometry.lat)
        // console.log(resFromMaps?.data.results[0].geometry.lng)
        // console.log(resFromMaps?.data.results[0].components.town)

        formData.lat = resFromMaps?.data.results[0].geometry.lat;
        formData.long = resFromMaps?.data.results[0].geometry.lng
        resFromMaps?.data.results[0].components?.town 
                            ? formData.city = resFromMaps?.data.results[0].components?.town
                            : resFromMaps?.data.results[0].components?.city 
                                ? formData.city = resFromMaps?.data.results[0].components?.city
                                : formData.city = ""

        formData.contact_number = formatData("phone",formData.contact_number)
        //formData.stuId = stuId

        //console.log(formData)

        if(amend){
            await editSupportFromStudent(formData)
            toast.success(messageResources.TOASTY.SUPPORT_EDIT)
        }else{
            await addSupportFormStudent(formData)

            notis?.map(una => {
                // console.log(" UNA JODERRRRRRRR ")
                // console.log(una.studentId._id)
                // console.log(stuId)
                // console.log(una.studentId._id == stuId)
        
                if(una.studentId._id == stuId && una.type == 4 && !una.isProcessed){
                    //console.log(" DOS DOS ")
                    const today = new Date()

                    let notiBody = {}
                    notiBody.idFor = una.id
                    notiBody.isProcessed = true
                    notiBody.processedBy = id
                    notiBody.dateProcessed = today
                    notiBody.daysElapsed = numberDaysDifference(una.createdAt,today)

                    updateNotification(notiBody).unwrap()

                    return
                }
            })
            
            toast.success(messageResources.TOASTY.SUPPORT_ADDED)
        }

        setShow(false)

    }



    return (
        <>

        {amend 
            ?
            <button style={{marginRight: '10px'}} className="btn btn-outline-success btn-circle" onClick={handleShow}>
                {buttonText}
            </button>
            :
            <div className="profile-userbuttons">
                <button style={{marginRight: '10px'}} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" onClick={handleShow}>
                    {buttonText}
                </button>
            </div>
        }


        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
            <Modal.Title>
                <div className="row">
                    <div className="col-md-1"><i style={{fontSize: '32px'}} className="material-icons">error_outline</i></div>
                    <div className="col-md-10">{header}</div>
                </div>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body><div className="row">
                                
                                <FormProvider {...methods}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                    
                                            <div className="card card-box">
                                                <div className="card-body " id="bar-parent">
                                                {/* <div className="row"> */}
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{messageResources.COMMON.NAME} 
                                                                    {errors?.name && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                                                </label>
                                                                <input 	type="text" 
                                                                        className="form-control" 
                                                                        {...register('name')}
                                                                    // onChange={(e) => formatPhoneNumber(e)}
                                                                        placeholder="Name"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label >{messageResources.COMMON.SURNAME}
                                                                    {errors?.surname && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                                                </label>
                                                                <input 	type="text" 
                                                                        className="form-control" 
                                                                        {...register('surname')}
                                                                        placeholder="Surname"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                    <div className="col-md-7">
                                                        <div className="form-group">
                                                            <label for="simpleFormEmail">{messageResources.COMMON.EMAIL}
                                                                {errors.email && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED_EMAIL} </span>}
                                                            </label>
                                                            <input 
                                                                type="email" 
                                                                className="form-control" 
                                                                {...register('email')}
                                                                placeholder={messageResources.COMMON.EMAIL}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label for="simpleFormEmail">{messageResources.CONTACT_INFO.NUMBER}
                                                                {/* {errors.contact_number && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>} */}
                                                                {errors?.contact_number && <span className="errorSpan"> {errors.contact_number.message} </span>}
                                                            </label>
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                {...register('contact_number')}
                                                                placeholder={messageResources.CONTACT_INFO.NUMBER}/>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    {/* <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label for="simpleFormEmail">{messageResources.COMMON.EMAIL}
                                                                {errors.email && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED_EMAIL} </span>}
                                                            </label>
                                                            <input 
                                                                type="email" 
                                                                className="form-control" 
                                                                {...register('email')}
                                                                placeholder={messageResources.COMMON.EMAIL}/>
                                                        </div>
                                                    </div> */}
                                                    
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            
                                                            <label >{messageResources.CONTACT_INFO.ADDRESS_1}
                                                                {errors.address1 && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}   
                                                            </label>
                                                            
                                                            <input 
                                                                type="text" 
                                                                className="form-control"
                                                                {...register(`address1`)}
                                                                placeholder={messageResources.CONTACT_INFO.ADDRESS_1}
                                                            /> 
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-7">
                                                            <div className="form-group">
                                                                <label for="simpleFormEmail">{messageResources.CONTACT_INFO.ADDRESS_2}</label>
                                                                <input 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    {...register(`address2`)}
                                                                    placeholder={messageResources.CONTACT_INFO.ADDRESS_2}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <div className="form-group">
                                    
                                                                <label >{messageResources.CONTACT_INFO.POSTCODE}
                                                                    {errors.postcode && <span className="errorSpan">  {errors.postcode.message} </span>}   
                                                                </label>
                                                                <input 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    {...register(`postcode`)}
                                                                    placeholder={messageResources.CONTACT_INFO.POSTCODE}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>{messageResources.MEDICAL.NOTES} 
                                                                {/* {errors?.contact_number && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>} */}
                                                            </label>
                                                            <textarea 
                                                                rows="4"
                                                                className="form-control" 
                                                                {...register('notes')}
                                                                placeholder={messageResources.MEDICAL.NOTES}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        <div className="fake-modal-footer">
                                            <Button variant="secondary" onClick={handleClose}>
                                                {messageResources.BUTTON.CANCEL}
                                            </Button>
                                            <button className="btn btn-modal" onClick={() => methods.handleSubmit(onSubmit)}>
                                                {amend 
                                                    ?
                                                    messageResources.BUTTON.AMEND
                                                    :
                                                    messageResources.BUTTON.ADD
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </FormProvider>

                            </div></Modal.Body>
            {/* <Modal.Footer>
            
            </Modal.Footer> */}
        </Modal>
        </>
    );
}
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Messages from '../../components/messages/Messages'
import { useParams, useLocation, Link } from 'react-router-dom'
import Subjects from '../programme/Subjects'
import { messageResources } from '../../assets/util/messageResources'
import { useGetStudentByIdQuery } from '../../redux/student/studentSlice'
import { useSelector } from 'react-redux'
import ProgrammeCard from '../../components/card/ProgrammeCard'
import ProgrammeCardSecond from '../../components/card/ProgrammeCardSecond'
import ProgrammeCardFourth from '../../components/card/ProgrammeCardFourth'
import MonoDataCard from '../../components/card/MonoDataCard'
import axios from 'axios'
import AddDestination from '../../components/modal/AddDestination'

const FullProgramme = () => {

    //console.log("|-----> FullProgramme COMPONENT FullProgramme <------|")

    const {studentId} = useParams()
    const location = useLocation();

    // Cant use redux cause student is archived and thereofore not loaded on redux
    //const studentInfo = useSelector((state) => selectStudentById(state, studentId))

    const studentInfo = useGetStudentByIdQuery(studentId);

    let buttonsInfo = [
        {title: `${messageResources.BUTTON.PROFILE}`, linkTo: `/students/${studentId}`}
    ]

    const programme = location.state?.programme;
    const showButton = location.state?.showButton;

    let eepHours = 0;
    let qualiHours = 0;
    let qualiMessage = "";
    let eepMessage = "";

    if(programme?.suppActivities?.length > 0){
        programme?.suppActivities?.map(subject => {
            if(subject.hours) eepHours += subject.hours
        })
        if(eepHours > programme?.eppHours){
            eepMessage = <Messages message={`${messageResources.BANNER.EEP_HOURS} (${eepHours}) ${messageResources.BANNER.EEP_HOURS_2} (${programme?.eppHours})`} type="error" />
        }else if(eepHours < programme?.eppHours){
            eepMessage = <Messages message={`${messageResources.BANNER.EEP_HOURS_LESS} (${eepHours}) ${messageResources.BANNER.EEP_HOURS_LESS_2} (${programme?.eppHours})`} type="alert" />
        }
    }


    if(programme?.eepActivities?.length > 0){
        programme?.eepActivities?.map(subject => {
            if(subject.hours) eepHours += subject.hours
        })
        if(eepHours > programme?.eppHours){
            eepMessage = <Messages message={`${messageResources.BANNER.EEP_HOURS} (${eepHours}) ${messageResources.BANNER.EEP_HOURS_2} (${programme?.eppHours})`} type="error" />
        }else if(eepHours < programme?.eppHours){
            eepMessage = <Messages message={`${messageResources.BANNER.EEP_HOURS_LESS} (${eepHours}) ${messageResources.BANNER.EEP_HOURS_LESS_2} (${programme?.eppHours})`} type="alert" />
        }
    }

    if(programme?.cgQualifications?.length > 0){
        programme?.cgQualifications?.map(subject => {
            if(subject.hours) qualiHours += subject.hours
        })
        if(qualiHours > programme?.qualyHours){
            qualiMessage = <Messages message={`${messageResources.BANNER.QUALI_HOURS} (${qualiHours}) ${messageResources.BANNER.QUALI_HOURS_2} (${programme?.qualyHours})`} type="error" />
        }else if(qualiHours < programme?.qualyHours){
            qualiMessage = <Messages message={`${messageResources.BANNER.QUALI_HOURS_LESS} (${qualiHours}) ${messageResources.BANNER.QUALI_HOURS_LESS_2} (${programme?.qualyHours})`} type="alert" />
        }
    }


    const generatePdf = async () => {

        // CHANGE URL PASSING PARAMENTERS
		const response = await axios.get(`https://www.seroots.org.uk/template/resumme/${programme?._id}/${studentInfo?.data?._id}`, {
			responseType: 'blob',
			headers: {
			'Content-Type': 'application/pdf'
			}
		})
		// console.log("RESPONSE IN COMPONENT")
        // console.log(response.data)

		// const response = await axios.get(`http://localhost:8800/serruts/template/resumme/${programme?._id}/${studentInfo?._id}`, {
		// 	responseType: 'blob',
		// 	headers: {
		// 	'Content-Type': 'application/pdf'
		// 	}
		// })

		//Create a Blob from the PDF Stream
		const file = new Blob(
			[response.data], 
			{type: 'application/pdf'});
		
	
		//Build a URL from the file
		const fileURL = URL.createObjectURL(file);
		
		//Open the URL on new Window
		window.open(fileURL);
	}


    return ( 

        <>    
            <Breadcrumb buttonsInfo={buttonsInfo} pageTitle={`${messageResources.OPS_TITLES.SUBJECTS} ${studentInfo?.data?.name} ${studentInfo?.data?.surname} (${programme?.academicYear}) `}/>
            
            <div className="row">
                <div className="col-md-12" >
                
                    {/* <a style={{float: 'right', marginLeft: '15px'}} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" onClick={generatePdf}>{messageResources.BUTTON.PDF_PROGR}</a> */}
                    <MonoDataCard 	slots="col-lg-12"
                        message={`${programme?.programme?.label} - ${programme?.speciality?.label}`}
                        isTitle={true}
                        data={programme?.destination?.label && programme?.destination?.employer ? [<b>Destination: </b>, `${programme?.destination?.label}.   `, <b>Employer/Educator: </b>, `${programme?.destination?.employer}`] : programme?.destination?.label && [<b>Destination: </b>,` ${programme?.destination?.label}`]}
                    />
                    
                    <div style={{textAlign:"center"}}>

                        <AddDestination  
                            buttonText={messageResources.BUTTON.ADD_DEST}
                            stuId={studentId}
                            progId={programme._id}
                            currentDest={programme.destination}
                            header={messageResources.MODAL.ADD_DEST} 
                        />

                        <a style={{marginRight: '10px'}} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" onClick={generatePdf}>
                                {messageResources.BUTTON.PDF_PROGR}
                        </a>

                        <Link to={`/students/${studentId}`} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">
                                {messageResources.BUTTON.PROFILE}
                        </Link>&nbsp;

                    </div>

                    {qualiMessage}

                    {programme?.cgQualifications && programme?.cgQualifications.length > 0 &&
                        <Subjects subjects={programme?.cgQualifications} 
                                progId={programme?._id}
                                stuId={studentId}
                                componentFrom="FullProgramme"
                                tableTitle={messageResources.PROGRAMME.CyG_QUALI}
                            />
                    }


                    {eepMessage}

                    {programme?.suppActivities && programme?.suppActivities.length > 0 &&
                        <Subjects subjects={programme?.suppActivities} 
                                progId={programme?._id}
                                stuId={studentId}
                                componentFrom="FullProgramme"
                                tableTitle={`${messageResources.PROGRAMME.SUP_ACTIS}`}
                            />
                    }

                    {programme?.eepActivities && programme?.eepActivities.length > 0 &&
                        <Subjects subjects={programme?.eepActivities} 
                                progId={programme?._id}
                                stuId={studentId}
                                componentFrom="FullProgramme"
                                tableTitle={messageResources.PROGRAMME.PLAN_EEP}
                            />
                    }

                    
                    <div className="row" style={{display: 'flex'}}>
                        <ProgrammeCard programme={programme}/>
                        <ProgrammeCardSecond programme={programme}/>
                        <ProgrammeCardFourth programme={programme}/>
                        {/* <ProgrammeCardThird programme={programme}/> */}
                    </div>
                </div>
            </div>
        </>

    
    )
}

export default FullProgramme

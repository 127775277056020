import React, {useState} from "react";
import {Modal, Button} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectNotificationById } from '../../redux/notifications/notificationsApiCalls'
import { useUpdateNotificationMutation } from "../../redux/notifications/notificationsApiCalls";
import useAuth from '../../hooks/useAuth'
import numberDaysDifference from "../../assets/util/numberDaysDifference"


export default function ProcessNotiModal({buttonText, notificationId, header, text, toastText, noType}) {

    //console.log("|----> ProcessNotiModal COMPONENT ProcessNotiModal <----|")

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { id } = useAuth()

    let notitoProcess = useSelector(state => selectNotificationById(state, notificationId))
    const [updateNotification] = useUpdateNotificationMutation()

    //console.log(notitoProcess)

    const handleDelete = async () => {

        try{
			
            //const notiToProcess = await axios.put(`/notifications/${notitoProcess._id}`,notitoProcess)
            
            const today = new Date()

            let notiBody = {}
            notiBody.idFor = notitoProcess.id
            notiBody.isProcessed = true
            notiBody.processedBy = id
            notiBody.dateProcessed = today
            notiBody.daysElapsed = numberDaysDifference(notitoProcess.createdAt,today)

            updateNotification(notiBody).unwrap()

            setShow(false)

            toast.success(toastText)

		}catch(err){
			console.log(err);
		}
    }

    return (
        <>
        
{/* 
        <button style={{marginRight: '10px'}} className="btn btn-outline-success btn-circle" onClick={handleShow}>
            {buttonText} 
        </button> */}
        {noType == "nin"
        ?
            <button className="button-noNin mdl-js-button mdl-button--raised mdl-js-ripple-effect btn-circle btn-primary pull-right" onClick={handleShow}>
                {buttonText}
            </button>
        :
            <button style={{marginRight: '10px'}} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" onClick={handleShow}>
                {buttonText}
            </button>
        } 

    
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
            <Modal.Title>
                <div className="row">
                <div className="col-md-1"><i style={{fontSize: '32px'}} className="material-icons">error_outline</i></div>
                    <div className="col-md-10">{header}</div>
                </div>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>{text}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    NO
                </Button>
                <button className="btn btn-modal" onClick={() => handleDelete()}>
                    YES
                </button>
            </Modal.Footer>
        </Modal>
        </>
    );
}
import React, {useState,useEffect} from "react";
import {Modal, Button} from 'react-bootstrap';
import Select from 'react-select'
import { messageResources } from "../../assets/util/messageResources";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useForm, Controller,FormProvider } from "react-hook-form"
import { newMedicalSchema } from '../../assets/util/yupValidations'
import { yupResolver } from "@hookform/resolvers/yup"
import useAuth from "../../hooks/useAuth"
import numberDaysDifference from "../../assets/util/numberDaysDifference"
import { medicalOptions, dietaryOptions } from "../../assets/util/selectOptions";
import { useEditMedicalFromStudentMutation } from "../../redux/student/studentSlice";
import { useUpdateNotificationMutation, selectAllNotifications } from "../../redux/notifications/notificationsApiCalls";

export default function EditMedicalModal({amend, buttonText, header, stuId, medicalToEdit}) {

    const { id } = useAuth()

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [render,setRender] = useState(false);

    useEffect(() => {

        if(amend){
            //console.log("------> MEDICAL amend amend amend")
            reset(medicalToEdit)
            setRender(true);
        }else {
            reset({})
        }

	}, [medicalToEdit]);

    const methods = useForm({
        resolver: yupResolver(newMedicalSchema),
    });

	const {
        register,
        handleSubmit,
        reset,
        defaultValues,
		control,
        formState: { errors },
    } = methods;

    //console.log(errors)

    // const medicalSorted = medicalOptions.sort()
    // console.log(medicalSorted)

    const notis = useSelector(selectAllNotifications)
    const [updateNotification] = useUpdateNotificationMutation()
    const [editMedicalFromStudent] = useEditMedicalFromStudentMutation()

    const onSubmit = async (formData) => {

        //console.log(formData);
        console.log("NO ERRORS")

		try{
            
            formData.stuId = stuId

            await editMedicalFromStudent(formData)

            if(amend){
                
                toast.success(messageResources.TOASTY.MEDICAL_EDIT)

            }else{

                notis?.map(una => {
                //    console.log(" UNA JODERRRRRRRR ")
                //     console.log(una.isProcessed)
                //     console.log(una.studentId._id)
                //     console.log(studentId)
                //     console.log(una.studentId._id == studentId)
                //     console.log(una.type)
            

                    if(una.studentId._id == stuId && una.type == 7 && !una.isProcessed){
                        //console.log(" DOS DOS ")
                        const today = new Date()

                        let notiBody = {}
                        notiBody.idFor = una.id
                        notiBody.isProcessed = true
                        notiBody.processedBy = id
                        notiBody.dateProcessed = today
                        notiBody.daysElapsed = numberDaysDifference(una.createdAt,today)

                        updateNotification(notiBody).unwrap()

                        return
                    }
                })

                toast.success(messageResources.TOASTY.MEDICAL_ADDED)
            }
    
            setShow(false)

		}catch(err){
			console.log(err);
		}
	}



    return (
        <>
        {amend 
            ?
            <div className="profile-userbuttons">
                <button style={{marginRight: '10px'}} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" onClick={handleShow}>
                    {buttonText}
                </button>
            </div>
            :
            <button style={{marginRight: '10px'}} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" onClick={handleShow}>
                {buttonText}
            </button>
        
        }
        <Modal show={show} onHide={handleClose} centered size="lg">
            <Modal.Header closeButton>
            <Modal.Title>
                <div className="row">
                <div className="col-md-1"><i style={{fontSize: '32px'}} className="material-icons">error_outline</i></div>
                    <div className="col-md-10">{header}</div>
                </div>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body><div className="row">
                                
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    
                            <div className="card card-box">
                                <div className="card-body " id="bar-parent">
                                {/* <div className="row"> */}
                                
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>{messageResources.MEDICAL.CONDITION}
                                                {errors?.condition && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                            </label>
                                            <Controller
                                                name="condition"
                                                control={control}
                                                render={({ field }) => (
                                                <Select options={medicalOptions.sort()} 
                                                    isMulti
                                                    defaultValue={medicalToEdit?.condition}
                                                    {...field}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-12">
                                        <div className="form-group">
                                            <label>{messageResources.MEDICAL.DIETARY}
                                            </label>
                                            <Controller
                                                name="dietary"
                                                control={control}
                                                render={({ field }) => (
                                                <Select options={dietaryOptions.sort()} 
                                                    isMulti
                                                    defaultValue={medicalToEdit?.dietary}
                                                    {...field}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div> */}
                                    
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>{messageResources.MEDICAL.ADMNISTRATION} 
                                                {/* {errors?.medication && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>} */}
                                            </label>
                                            <textarea 
                                                rows="4"
                                                className="form-control" 
                                                {...register('medication')}
                                                placeholder={messageResources.MEDICAL.ADMNISTRATION} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>{messageResources.MEDICAL.DIETARY} 
                                                {/* {errors?.contact_number && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>} */}
                                            </label>
                                            <textarea 
                                                rows="4"
                                                className="form-control" 
                                                {...register('assistance')}
                                                placeholder={messageResources.MEDICAL.DIETARY}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>{messageResources.MEDICAL.NOTES} 
                                                {/* {errors?.contact_number && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>} */}
                                            </label>
                                            <textarea 
                                                rows="4"
                                                className="form-control" 
                                                {...register('notes')}
                                                placeholder={messageResources.MEDICAL.NOTES}/>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-1">
                                        <button type="submit" style={{marginTop:"25px"}} className="btn btn-primary" onClick={methods.handleSubmit(onSubmit)}>{messageResources.BUTTON.ADD}</button>
                                    </div> */}
                                {/* </div> */}
                            </div>
                        </div>
                        <div className="fake-modal-footer">
                            <Button variant="secondary" onClick={handleClose}>
                                {messageResources.BUTTON.CANCEL}
                            </Button>
                            <button className="btn btn-modal" onClick={() => methods.handleSubmit(onSubmit)}>
                                {amend 
                                    ?
                                    messageResources.BUTTON.AMEND
                                    :
                                    messageResources.BUTTON.ADD
                                }
                            </button>
                        </div>
                    </form>
                </FormProvider>

            </div></Modal.Body>
            {/* <Modal.Footer>
            
            </Modal.Footer> */}
        </Modal>
        </>
    );
}
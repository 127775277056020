import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from "../api/apiSlice"
import formatStudent from "../../assets/util/formatStudent"

const studentsAdapter = createEntityAdapter({
    // supposedly alphabetic
    sortComparer: (a, b) => a.name > b.name ? 1 : -1
    //sortComparer: (a,b) => b.createDate.localeCompare(a.createDate)
})

const initialState = studentsAdapter.getInitialState()

// 
export const studentSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getStudents: builder.query({
            query: () => '/studentsApi/true',
            //we normalize the reponse according to the adapter above
            transformResponse: responseData => {
                
                // console.log("|-----> RTK /studentsApi/true <------|")

                const loadedStaffs = responseData.map(student => {
                    return formatStudent(student)
                });
                return studentsAdapter.setAll(initialState, loadedStaffs)
            },
            // validateStatus: (response, result) => {
            //     return response.status === 200 && !result.error
            // },
            providesTags: (result, error, arg) => {
                // every time we invalidate any of these tags, it will 
                // auto fetch all the students again '/studentsApi'
                if(result?.ids){
                    return [
                        { type: 'Student', id: "LIST"},
                        // if any of those post ids are invalidated it will also
                        // preform the auto fetch of al lthe students
                        ...result.ids.map(id => ({ type: 'Student', id }))
                    ]
                } else return [{ type: 'Student', id: "LIST"}]
            }
        }),
        getStudentsArchived: builder.query({
            query: () => '/studentsApi/false',
            //we normalize the reponse according to the adapter above
            transformResponse: responseData => {
                const loadedStaffs = responseData.map(student => {
                    return formatStudent(student)
                });
                return studentsAdapter.setAll(initialState, loadedStaffs)
            },
            // validateStatus: (response, result) => {
            //     return response.status === 200 && !result.error
            // },
            providesTags: (result, error, arg) => {
                // every time we invalidate any of these tags, it will 
                // auto fetch all the students again '/studentsApi'
                if(result?.ids){
                    return [
                        { type: 'Student', id: "NOTACTIVE"},
                        // if any of those post ids are invalidated it will also
                        // preform the auto fetch of al lthe students
                        ...result.ids.map(id => ({ type: 'Student', id }))
                    ]
                } else return [{ type: 'Student', id: "NOTACTIVE"}]
            }
        }),
        getStudentById: builder.query({
            query: id => `/studentsApi/find/${id}`,
            transformResponse: responseData => {
                
                
                //console.log("|-----> STU FROM SLICE RTK <------|")
                //console.log(responseData)
                return responseData
            },
            // providesTags: (result, error, arg) => {
            //     console.log(result)

            // }
        }),
        openPdfStu: builder.query({
            query: id => `/studentsApi/openPdf/${id}`,
            transformResponse: responseData => {
                
                
                //console.log("|-----> STU FROM SLICE RTK <------|")
                //console.log(responseData)
                return responseData
            },
            // providesTags: (result, error, arg) => {
            //     console.log(result)

            // }
        }),
        addNewStudent: builder.mutation({
            query: newStudent => ({
                url: '/studentsApi/addStudent',
                method: 'POST',
                body: newStudent
            }),
            
            // as we perfrom a mutation we use invalidatesTags
            // this is going to invalidate the list
            // the new student will be part of the list so 
            // it should invalidae the student list
            invalidatesTags: [
                { type: 'Student', id: "LIST"},
                { type: 'Notifications', id: 'LISTNOTIS'}
            ]
        }),
        updateStudent: builder.mutation({
            query: studentToEdit => ({
                url: `/studentsApi/${studentToEdit.id}`,
                method: 'PUT',
                body: 
                    // edit student as it is done in StudentForm.jsx
                    studentToEdit,
                    //contact_number: formatData("phone",studentToEdit.contact_number),
                    //nin: formatData("nin",studentToEdit.nin)
                
            }),
            invalidatesTags: (result, error, arg) => [
                // this is going ot invalidate whichever student id 
                // is there being arg the student edited
                { type: 'Student', id: arg.id}
                
            ]
        }),
        archiveStudent: builder.mutation({
            query: studentToEdit => ({
                url: `/studentsApi/${studentToEdit.id}`,
                method: 'PUT',
                body: 
                    // edit student as it is done in StudentForm.jsx
                    studentToEdit,
                    //contact_number: formatData("phone",studentToEdit.contact_number),
                    //nin: formatData("nin",studentToEdit.nin)
                
            }),
            invalidatesTags: (result, error, arg) => [
                // this is going ot invalidate whichever student id 
                // is there being arg the student edited
                { type: 'Student', id: "LIST"},
                { type: 'Student', id: "NOTACTIVE"}
                
            ]
        }),
        deleteStudent: builder.mutation({
            query: ({id}) => ({
                url: `/studentsApi/${id}`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Student', id: arg.id}
            ]
        }),
        updateGooDocumentFromStudent: builder.mutation({
            query: docToUpdate => ({
                url: `/docs/${docToUpdate.id}`,
                method: 'PUT',
                body: { ...docToUpdate }
            }),
            invalidatesTags: (result, error, arg) => [
                //{ type: 'Student', id: "LIST"}
                { type: 'Student', id: arg.stuId}
            ]    
        }),
        addGooDocumentFromStudent: builder.mutation({
            query: docData => ({
                url: '/docs/addNewDoc',
                method: 'POST',
                body: {
                    ...docData
                }
            }),
            invalidatesTags:  (result, error, arg) => [
                //{ type: 'Student', id: "LIST"}
                { type: 'Student', id: arg.stuId}
            ]
        }),
        deleteGooDocumentFromStudent: builder.mutation({
            query: contentIds => ({
                url: `/docs/delete/${contentIds.idDocu}/${contentIds.idStu}`,
                method: 'DELETE'
            }),
            invalidatesTags:  (result, error, arg) => [
                { type: 'Student', id: arg.id}
            ]
        }),
        deleteNokFromStudent: builder.mutation({
            query: contentIds => ({
                url: `/nofKin/delete/${contentIds.idNok}/${contentIds.idStu}`,
                method: 'DELETE'
            }),
            // async onQueryStarted(arg, { dispatch, queryFulfilled}) {
            //     try {

            //         console.log("|---> deleteNokFromStudent onQueryStarted <-----|")
            //         const { data } = await queryFulfilled
            //         console.log(data)

            //         // dispatch(
            //         //     apiSlice.util.updateQueryData('getStudentById',arg.idStu, (draft) => {
            //         //         console.log("|---> updateQueryData updateQueryData <-----|")
            //         //         console.log(draft)
            //         //         //Object.assign(draft,patch)
            //         //     })
            //         // )

            //     }catch(err){

            //     }
            // },        
            invalidatesTags:  (result, error, arg) => [
                { type: 'Student', id: arg.id}
            ]
        }),

        // ADD TWO FRO SUPPORT POINTING THE SUPPORT URL AS NOKIN IS DONE
        deleteSupportFromStudent: builder.mutation({
            query: contentIds => ({
                url: `/worker/delete/${contentIds.idWorker}/${contentIds.idStu}`,
                method: 'DELETE'
            }),    
            invalidatesTags:  (result, error, arg) => [
                { type: 'Student', id: arg.id}
            ]
        }),
        addSupportFormStudent: builder.mutation({
            query: supportToAdd => ({
                url: `/worker/add`,
                method: 'POST',
                body: supportToAdd
            }),
            invalidatesTags:  (result, error, arg) => [
                { type: 'Student', id: arg.stuId}
            ]
        }),
        editSupportFromStudent: builder.mutation({
            query: supportToEdit => ({
                url: `/worker/${supportToEdit._id}`,
                method: 'PUT',
                body: supportToEdit
            }),
            invalidatesTags:  (result, error, arg) => [
                { type: 'Student', id: arg.stuId}
            ]
        }),

        addNokFromStudent: builder.mutation({
            query: nokToAdd => ({
                url: '/nofKin/add',
                method: 'POST',
                body: nokToAdd
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Student', id: "LIST"}
            ]
        }),
        editNokFormStudent: builder.mutation({
            query: nokToEdit => ({
                url: `/nofKin/${nokToEdit._id}`,
                method: 'PUT',
                body: nokToEdit
            }),
            invalidatesTags:  (result, error, arg) => [
                { type: 'Student', id: arg.id}
            ]
        }),
        addTaxiFromStudent: builder.mutation({
            query: taxiToAdd => ({
                url: `/studentsApi/addTaxi/${taxiToAdd.stuId}`,
                method: 'PUT',
                body: taxiToAdd
            }),
            invalidatesTags:  (result, error, arg) => [
                { type: 'Student', id: arg.stuId}
            ]
        }),
        editTaxiFormStudent: builder.mutation({
            query: taxiToEdit => ({
                url: `/studentsApi/editTaxi/${taxiToEdit.stuId}`,
                method: 'PUT',
                body: taxiToEdit
            }),
            invalidatesTags:  (result, error, arg) => [
                { type: 'Student', id: arg.stuId}
            ]
        }),
        deleteTaxiFromStudent: builder.mutation({
            query: stuId => ({
                url: `/studentsApi/delTaxi/${stuId}`,
                method: 'PUT',
            }),
            invalidatesTags:  (result, error, arg) => [
                { type: 'Student', id: arg.stuId}
            ]
        }),
        editBursaryFormStudent: builder.mutation({
            query: bursaryToEdit => ({
                url: `/studentsApi/editBursary/${bursaryToEdit.stuId}`,
                method: 'PUT',
                body: bursaryToEdit
            }),
            invalidatesTags:  (result, error, arg) => [
                { type: 'Student', id: arg.stuId}
            ]
        }),
        editMedicalFromStudent: builder.mutation({
            query: medToEdit => ({
                url: `/studentsApi/putMedical/${medToEdit.stuId}`,
                method: 'PUT',
                body: medToEdit
            }),
            invalidatesTags:  (result, error, arg) => [
                { type: 'Student', id: arg.stuId}
            ]
        }),
        editEhcpFromStudent: builder.mutation({
            query: ehcpToEdit => ({
                url: `/studentsApi/editEhcp/${ehcpToEdit.stuId}`,
                method: 'PUT',
                body: ehcpToEdit
            }),
            invalidatesTags:  (result, error, arg) => [
                { type: 'Student', id: arg.stuId}
            ]
        }),
        // editProgrammeFromStudent: builder.mutation({
        //     query: ehcpToEdit => ({
        //         url: `/studentsApi/editEhcp/${ehcpToEdit.stuId}`,
        //         method: 'PUT',
        //         body: ehcpToEdit
        //     }),
        //     invalidatesTags:  (result, error, arg) => [
        //         { type: 'Student', id: arg.stuId}
        //     ]
        // }),
        addCareersFromStudent: builder.mutation({
            query: careersToAdd => ({
                url: `/studentsApi/addCareers/${careersToAdd.stuId}`,
                method: 'PUT',
                body: careersToAdd
            }),
            invalidatesTags:  (result, error, arg) => [
                { type: 'Student', id: arg.stuId}
            ]
        }),
        editCareersFormStudent: builder.mutation({
            query: careersToEdit => ({
                url: `/studentsApi/editCareers/${careersToEdit.stuId}`,
                method: 'PUT',
                body: careersToEdit
            }),
            invalidatesTags:  (result, error, arg) => [
                { type: 'Student', id: arg.stuId}
            ]
        }),
        addProgrammeFromStudent: builder.mutation({
            query: programmeToAdd => ({
                url: '/programmes/add',
                method: 'POST',
                body: programmeToAdd
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Student', id: arg.stuId}
            ]
        }),
        editProgrammeFromStudent: builder.mutation({
            query: programmeToEdit => ({
                url: `/programmes/${programmeToEdit._id}`,
                method: 'PUT',
                body: programmeToEdit
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Student', id: arg.stuId}
            ]
        }),
        editAssessmentFromStudent: builder.mutation({
            query: assessmentToEdit => ({
                url: `/studentsApi/assessment/${assessmentToEdit.stuId}`,
                method: 'PUT',
                body: assessmentToEdit
            }),
            // invalidatesTags: (result, error, arg) => [
            //     { type: 'Student', id: "LIST"},
            //     { type: 'Student', id: arg.stuId}
            // ]
        }),
        editAssessmentFromStudentInvalidate: builder.mutation({
            query: assessmentToEdit => ({
                url: `/studentsApi/assessment/${assessmentToEdit.stuId}`,
                method: 'PUT',
                body: assessmentToEdit
            }),
            invalidatesTags: (result, error, arg) => [
                //{ type: 'Student', id: "LIST"},
                { type: 'Student', id: arg.stuId}
            ]
        }),
    })
})

export const {
    useGetStudentsQuery,
    useGetStudentByIdQuery,
    useAddNewStudentMutation,
    useUpdateStudentMutation,
    useDeleteStudentMutation,
    useUpdateGooDocumentFromStudentMutation,
    useAddGooDocumentFromStudentMutation,
    useDeleteGooDocumentFromStudentMutation,
    useDeleteNokFromStudentMutation,
    useAddNokFromStudentMutation,
    useEditNokFormStudentMutation,
    useAddTaxiFromStudentMutation,
    useEditTaxiFormStudentMutation,
    useDeleteTaxiFromStudentMutation,
    useEditBursaryFormStudentMutation,
    useDeleteSupportFromStudentMutation,
    useAddSupportFormStudentMutation,
    useEditSupportFromStudentMutation,
    useEditMedicalFromStudentMutation,
    useEditEhcpFromStudentMutation,
    useGetStudentsArchivedQuery,
    useAddCareersFromStudentMutation,
    useEditCareersFormStudentMutation,
    useAddProgrammeFromStudentMutation,
    useEditProgrammeFromStudentMutation,
    useArchiveStudentMutation,
    useEditAssessmentFromStudentMutation,
    useOpenPdfStuQuery,
    useEditAssessmentFromStudentInvalidateMutation
} = studentSlice


// return the entire query result object
export const selectStudentsResult = studentSlice.endpoints.getStudents.select();

//creates memoized selector, just the data
const selectStudentsData = createSelector(
    // input function
    selectStudentsResult,
    // output function 
    studentsResult => studentsResult.data // normalized state object with ids & entities
)


// selectors that come with normalization adaptor
export const {
    selectAll: selectAllStudents,
    selectById: selectStudentById,
    selectIds: selectStudentIds
    // pass in a selector the returns the students slice of the state
    // the ?? means that if left is null it will return wht it is on the right (initialState)
} = studentsAdapter.getSelectors(state => selectStudentsData(state) ?? initialState)











// infromation about the reducer and the states we want to store
// and the actions we want to perform
// initialState: initial states for the entity
// reducers: functions we wnat to use to alter the values of the app
// login: (state, action) where state is the current state and action contains 
// payload an type. payload is an object in which you can pass info to change the state 
// and type is the action type.
// export const studentSlice = createSlice({
//     name: "reduxStudent",
//     initialState: {
//         studentInfo:  {},
//         isLoading: false,
//         error: false,
        
//     },
//     reducers: {
        
//         // FIRST SOLUTION: CUSTOM REDUCERS
//         getStudentStart: (state) => {
//             state.isLoading = true;
//             state.studentInfo = {};
//         },
//         getStudentSuccess: (state,action) => {
//             state.isLoading = false
//             state.studentInfo = action.payload
//         },
//         getStudentError: (state,action) => {
//             state.error = action.payload;
//             state.isLoading = false;
//         },
//     }

// });

// export const { getStudentStart, getStudentSuccess, getStudentError} = studentSlice.actions;

// export default studentSlice.reducer;

import React, {useEffect, useRef, useState} from 'react'
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useParams} from "react-router-dom";
import { messageResources } from '../../assets/util/messageResources'
import { resetPwSchema } from '../../assets/util/yupValidations'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'; 
import { useGetStaffByIdQuery, useUpdateStaffResetMutation } from '../../redux/staff/staffApiSlice'
import lettersOnly from '../../assets/img/logo/lettersOnlyWhite.png'
import treeOnly from '../../assets/img/logo/treeOnlyWhite3.png'
import ReCAPTCHA from 'react-google-recaptcha'
import "../../assets/css/pages/extra_pages.css"



const ResetPw = () => {

    //console.log("|--------> RESET RESET <----------|")
    const dispatch = useDispatch();
    const recaptRef = useRef();
    const passRef = useRef()

    const[eye,seteye]=useState(true);
    const[password,setpassword]=useState("password");

    const Eye=()=>{
        if(password=="password"){
            setpassword("text");
            seteye(false);
        }
        else{
            setpassword("password");
            seteye(true);
        }
    }

    let navigate = useNavigate();
    
    const {id} = useParams();
    const [updateStaff] = useUpdateStaffResetMutation()
    const {
		data: staffInfo,
	} = useGetStaffByIdQuery(id)


    const { register, handleSubmit, watch, reset, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(resetPwSchema),
    });


    const onSubmit = async (formData) => { 

        console.log("NO ERRORS")
        formData._id = id

        try {

    
            //console.log(staffInfo?._id == id)
        //console.log("|-------> NO ERRORS <--------|")
        
        const recaptToken = await recaptRef.current.executeAsync();
        // it allows to reexecute the recaptcha check 
        recaptRef.current.reset();

        //console.log(recaptToken)
        formData.recaptToken = recaptToken

        if(staffInfo?._id == id){

            const res = await updateStaff(formData).unwrap()

            toast.success(messageResources.TOASTY.PW_RESETED,{
                autoClose: 10002,
            })
            navigate('/login')

        }else{
            
            toast.error(messageResources.TOASTY.NO_RESET,{
                autoClose: 10002,
            })
            navigate('/login')

        }
        

    }catch(err){
        console.log(err)
    }

    
    };

    toast.configure({
        pauseOnFocusLoss: false
    });



    return (
        
        <>
            <div className="limiter">
                <div className="container-login100 page-background" >
                {/* <pre>{JSON.stringify(errors, undefined, 2)}</pre> */}
                    <div className="wrap-login100">
                        <form className="login100-form validate-form" onSubmit={handleSubmit(onSubmit)}>
                            <div className='d-flex justify-content-center'>
                                    <img src={treeOnly} />
                            </div> 
                            <div className='d-flex justify-content-center'>
                                    <img src={lettersOnly} />
                                    {/* <i class="twa twa-2x twa-grimacing" style={{margin: 0}}></i> */}
                            </div>
                            <span className="login100-form-title p-b-34 p-t-27">
                                {messageResources.LOGIN.RESET}
                            </span>
                            <div className="row">
                                {errors.password && <span className="errorSpanOut"> {errors.password.message} </span>}
                                <div className="wrap-input100 validate-input" style={{ display: 'flex'}}>
                                    <input  className="input100" ref={passRef}
                                        type={password} 
                                        placeholder={messageResources.COMMON.PASSWORD}
                                        {...register('password')}
                                    />
                                    <span className="focus-input100" data-placeholder="&#xf191;"></span>
                                    <i onClick={Eye} style={{ color: '#fff', fontSize: '22px', lineHeight: '46px' }} className={`fa ${eye ? "fa-eye-slash" : "fa-eye" }`}></i>
                                </div>
                                {errors.password2 && <span className="errorSpanOut"> {errors.password2.message} </span>}
                                <div className="wrap-input100 validate-input" style={{ display: 'flex'}}>
                                    <input  className="input100" ref={passRef}
                                        type={password} 
                                        placeholder={messageResources.COMMON.CONFIRM_PW}
                                        {...register('password2')}
                                    />
                                    <span className="focus-input100" data-placeholder="&#xf191;"></span>
                                    <i onClick={Eye} style={{ color: '#fff', fontSize: '22px', lineHeight: '46px' }} className={`fa ${eye ? "fa-eye-slash" : "fa-eye" }`}></i>
                                </div>

                                <ReCAPTCHA  sitekey='6LfPnGwgAAAAAF3e8lQCTEzMRvJ7Cl6yACMwNZWU'
                                            size='invisible'
                                            ref={recaptRef} 
                                />

                                <div className="container-login100-form-btn">
                            
                                        <button class="login100-form-btn" onClick={handleSubmit(onSubmit)}>
                                                {messageResources.LOGIN.RESET}
                                        </button>

                                </div>
                            </div>    
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPw

import React, {useState} from "react";
import {Modal, Button} from 'react-bootstrap';
import { messageResources } from "../../assets/util/messageResources";
import { toast } from 'react-toastify';
import {useNavigate} from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useAddNotificationMutation } from "../../redux/notifications/notificationsApiCalls";
import { useGetStaffsQuery } from "../../redux/staff/staffApiSlice"
import { useDeleteGooDocumentFromStudentMutation,
            useDeleteNokFromStudentMutation,
            useDeleteTaxiFromStudentMutation, 
            useDeleteSupportFromStudentMutation,
            useUpdateStudentMutation,
            useArchiveStudentMutation,
            selectStudentById } from "../../redux/student/studentSlice";


export default function InfoModal({forList, buttonText, stuId, toDeleteId, header, text, toastText, methodType}) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [deleteGooDocumentFromStudent] = useDeleteGooDocumentFromStudentMutation()
    const [deleteNokFromStudent] = useDeleteNokFromStudentMutation()
    const [deleteTaxiFromStudent] = useDeleteTaxiFromStudentMutation()
    const [deleteSupportFromStudent] = useDeleteSupportFromStudentMutation()
    const [addNotification] = useAddNotificationMutation()
    const [ archiveStudent ] = useArchiveStudentMutation()
    const [ updateStudent ] = useUpdateStudentMutation()

    let navigate = useNavigate()

    const {data: staffs} = useGetStaffsQuery()
    const studentInfo = useSelector((state) => selectStudentById(state, stuId))

    const handleDelete = async (methodType) => {

        try{
			
            if(methodType == "deleteDoc"){
                //console.log("|----->  Deleting Doc From Student <-----|")
                await deleteGooDocumentFromStudent({idDocu: toDeleteId, idStu: stuId}).unwrap()

            }else if(methodType == "deleteNok"){ 
                
                const studentUpdated = await deleteNokFromStudent({idNok: toDeleteId, idStu: stuId}).unwrap()

                if(!studentUpdated.kins || studentUpdated.kins.length == 0){

                    const {ids, entities} = staffs

                    const listStaffs = ids?.length
                        ? ids.map(staffId => entities[staffId] )
                        : null

                    let notiNokOwners = []
                    listStaffs?.map(async (staff) => {
        
                        if(staff.role.includes(3)){ // STUDENT REGISTER ROLE
                            notiNokOwners.push(staff._id)
                        }
        
                    })
        
                    let noti = {
                                type: 5, 
                                description: "Add Next of Kin",
                                message: `Add Next of Kin for ${studentUpdated.name} ${studentUpdated.surname} .`,
                                studentId: `${studentUpdated._id}`,
                                owner: notiNokOwners,
                                isProcessed: false,
                                priority: 1 
                            }
        
                    await addNotification(noti).unwrap()
                }

            }else if(methodType == "deleteTaxi"){
                await deleteTaxiFromStudent(stuId).unwrap()
            }else if(methodType == "deleteSupport"){
                //await deleteSupportFromStudent(stuId).unwrap()
                await deleteSupportFromStudent({idWorker: toDeleteId, idStu: stuId}).unwrap()
            }else if(methodType == "archiveStu"){
                await updateStudent({isActive: false, id: stuId }).unwrap()
            }else if(methodType == "activeStu"){
                await archiveStudent({isActive: true, id: stuId }).unwrap()
                navigate('/students')
            }

            setShow(false)

            toast.success(toastText)

		}catch(err){
			console.log(err);
		}
    }

    return (
        <>
    
        {/* <button className="btn btn-outline-success" onClick={handleShow}>
            {buttonText}
        </button> */}
        
        <button style={{marginRight: '10px'}} className="btn btn-outline-success btn-circle" onClick={handleShow}>
            {buttonText}
        </button>

        {/* <button className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" onClick={handleShow}>
            {buttonText}
        </button> */}

        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
            <Modal.Title>
                <div className="row">
                <div className="col-md-1"><i style={{fontSize: '32px'}} className="material-icons">error_outline</i></div>
                    <div className="col-md-10">{header}</div>
                </div>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>{text}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    NO
                </Button>
                <button className="btn btn-modal" onClick={() => handleDelete(methodType)}>
                    {/* {messageResources.BUTTON.DELETE} */} YES
                </button>
            </Modal.Footer>
        </Modal>
        </>
    );
}
import React, {useState,useEffect} from "react";
import {Modal, Button} from 'react-bootstrap';
import { messageResources } from "../../assets/util/messageResources";
import InputText from "../forms/Input/InputText";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import addLocation from "../../assets/util/addLocation"
import { useForm, FormProvider, Controller } from "react-hook-form"
import useAuth from "../../hooks/useAuth";
import Toggle from 'react-toggle'
import { selectStudentById, useEditBursaryFormStudentMutation
            , useAddGooDocumentFromStudentMutation, useDeleteGooDocumentFromStudentMutation
            , useUpdateGooDocumentFromStudentMutation} from "../../redux/student/studentSlice";
import { selectAllNotifications } from "../../redux/notifications/notificationsApiCalls";

export default function EditBursaryModal({amend, buttonText, header, stuId, bursaryToEdit}) {

    //console.log("------> EditTaxiModal EditTaxiModal <----|")

    const { id } = useAuth()

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [viewDisc, setViewDisc] = useState()
    const [viewVul, setVewVul] = useState()


    var studentFormState = useSelector((state) => selectStudentById(state, stuId))
    //console.log(studentFormState)

    useEffect(() => {

        //console.log("------> EditTaxiModal UseEffect <----|")
        if(bursaryToEdit?.discretionary){
            setViewDisc(true)
        }else{
            setViewDisc(false)
        }
        
        if(bursaryToEdit?.vulnerable){
            setVewVul(true)
        }else{
            setVewVul(false)
        }

		reset(bursaryToEdit)
	}, [bursaryToEdit, show]);


    const methods = useForm({});

	const {
        register,
        handleSubmit,
        reset,
        watch,
        defaultValues,
		control,
        formState: { errors },
    } = methods;

    //console.log(errors)

    useEffect(() => {

        const subscription = watch((value, { name, type }) => {
        //console.log(value, name, type)
        
            if(value.discretionary){
                setViewDisc(true)
            }else{
                setViewDisc(false)
            }
            
            if(value.vulnerable){
                setVewVul(true)
            }else{
                setVewVul(false)
            }
        });
    
       // console.log(subscription)
       // return () => subscription.unsubscribe();
    }, [watch]);

    const notis = useSelector(selectAllNotifications)
    const [editBursaryFormStudent] = useEditBursaryFormStudentMutation()
    const [addGooDocumentFromStudent] = useAddGooDocumentFromStudentMutation()
    const [deleteGooDocumentFromStudent] = useDeleteGooDocumentFromStudentMutation()
    const [updateGooDocumentFromStudent] = useUpdateGooDocumentFromStudentMutation()


    const deleteDoc = (studentFormState,  docType) => {

        studentFormState.gooDocuments.map(docu => {

            if(docu.type.value == docType){
                
                deleteGooDocumentFromStudent({idDocu: docu._id, idStu: studentFormState._id}).unwrap()
            
            }
        })

    }

    const processDocs = (studentFormState, doc_link, docType) => {

        //console.log("|---------> processDocs processDocs processDocs <><><><><><>")

        var addNew = true

        studentFormState.gooDocuments.map(docu => {
            
            if(docu.type.value == docType){
                
                updateGooDocumentFromStudent({id: docu._id, url_link: doc_link}).unwrap()
                
                addNew = false;
            }
        })
    
        if(addNew){
    
            const gooDoc = {}
            gooDoc.type = {}
            gooDoc.type.value = docType
            gooDoc.type.label = docType
            gooDoc.url_link = doc_link
            gooDoc.stuId = studentFormState._id
            gooDoc.stuName = studentFormState.name+" "+studentFormState.surname
    
            //const newDocRes = await axios.post("/docs/addNewDoc",gooDoc);
            addGooDocumentFromStudent(gooDoc).unwrap()
        }

    }



    const onSubmit = async (formData) => {

        //console.log("NO ERRORS") 
        //console.log(formData);       

		try{

            formData.stuId = stuId
            formData.stuName = studentFormState?.name+" "+studentFormState?.surname
            
            if(typeof formData.meals === 'undefined') formData.meals = false
            if(typeof formData.zipcard === 'undefined') formData.zipcard = false
            if(typeof formData.discretionary === 'undefined') formData.discretionary = false
            if(typeof formData.vulnerable === 'undefined') formData.vulnerable = false

            if(!formData.vulnerable) formData.vulnerable_link = ""
            if(!formData.discretionary) formData.discretionary_link = ""

             // ADDING DOCS TO STUDENT LIST
            if(formData.discretionary_link && formData.discretionary_link != ""){
                processDocs(studentFormState, formData.discretionary_link, messageResources.DOCUMENTS.DISCRETIONARY_PROOF)
            }else{
                deleteDoc(studentFormState, messageResources.DOCUMENTS.DISCRETIONARY_PROOF)
            }

            //  // ADDING DOCS TO STUDENT LIST
            if(formData.vulnerable_link && formData.vulnerable_link != ""){
                processDocs(studentFormState, formData.vulnerable_link, messageResources.DOCUMENTS.VULNERABLE_PROOF)
            }else{
                deleteDoc(studentFormState, messageResources.DOCUMENTS.VULNERABLE_PROOF)
            }
        
            await addLocation(formData) 
            
            await editBursaryFormStudent(formData).unwrap()

            if(amend){
                toast.success(messageResources.TOASTY.BURSARY_EDIT)
            }else{
                toast.success(messageResources.TOASTY.BURSARY_ADDED)
            }

            //console.log(formData)
    
            setShow(false)

		}catch(err){
			console.log(err);
		}
	}



    return (
        <>
        {amend 
            ?
            <div className="profile-userbuttons">
                <button style={{marginRight: '10px'}} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" onClick={handleShow}>
                    {buttonText}
                </button>
            </div>
            :
            <button style={{marginRight: '10px'}} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" onClick={handleShow}>
                {buttonText}
            </button>

        }

        <Modal show={show} onHide={handleClose} centered size="lg">
            <Modal.Header closeButton>
            <Modal.Title>
                <div className="row">
                <div className="col-md-1"><i style={{fontSize: '32px'}} className="material-icons">error_outline</i></div>
                    <div className="col-md-10">{header}</div>
                </div>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                                
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                        
                                <div className="card card-box">
                                    <div className="card-body " id="bar-parent">
                                    {/* <div className="row"> */}
                                        <div className="row">   
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{messageResources.BURSARY.MEALS}</label>
                                                        <Controller
                                                            control={control}
                                                            name='meals'
                                                            render={({ field }) => (
                                                                <Toggle
                                                                    defaultChecked={bursaryToEdit?.meals}
                                                                    aria-label='No label tag'
                                                                    onChange={(value) => field.onChange(value)}
                                                                    value={bursaryToEdit?.meals ? bursaryToEdit?.meals : field.value}
                                                                />
                                                            )}
                                                        />	
                                                
                                                        
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{messageResources.BURSARY.ZIPCARD}</label>
                                                        <Controller
                                                            control={control}
                                                            name='zipcard'
                                                            render={({ field }) => (
                                                                <Toggle
                                                                    defaultChecked={bursaryToEdit?.zipcard}
                                                                    aria-label='No label tag'
                                                                    onChange={(value) => field.onChange(value)}
                                                                    value={bursaryToEdit?.zipcard ? bursaryToEdit?.zipcard : field.value}
                                                                    //checked={studentData.photo ? true : false}
                                                                />
                                                            )}
                                                        />	
                                                
                                                        
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{messageResources.BURSARY.DISCRETIONARY}</label>
                                                        <Controller
                                                            control={control}
                                                            name='discretionary'
                                                            render={({ field }) => (
                                                                <Toggle
                                                                    defaultChecked={bursaryToEdit?.discretionary}
                                                                    aria-label='No label tag'
                                                                    onChange={(value) => field.onChange(value)}
                                                                    value={bursaryToEdit?.discretionary ? bursaryToEdit?.discretionary : field.value}
                                                                    //checked={studentData.photo ? true : false}
                                                                />
                                                            )}
                                                        />	
                                                
                                                        
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{messageResources.BURSARY.VULNERABLE}</label>
                                                        <Controller
                                                            control={control}
                                                            name='vulnerable'
                                                            render={({ field }) => (
                                                                <Toggle
                                                                    defaultChecked={bursaryToEdit?.vulnerable}
                                                                    aria-label='No label tag'
                                                                    onChange={(value) => field.onChange(value)}
                                                                    value={bursaryToEdit?.vulnerable ? bursaryToEdit?.vulnerable : field.value}
                                                                    //checked={studentData.photo ? true : false}
                                                                />
                                                            )}
                                                        />	
                                                
                                                        
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="row">
                                            {viewDisc && 
                                                <InputText 
                                                    slots="col-md-6"
                                                    label={messageResources.DOCUMENTS.DISCRETIONARY_PROOF}
                                                    errors={errors}
                                                    register={register}
                                                    type="string"
                                                    plaHolder={messageResources.COMMON.PASTE_LINK}
                                                    inputName="discretionary_link"
                                                    original={true}
                                                />
                                            }

                                            {viewVul && 
                                                <InputText 
                                                    slots="col-md-6"
                                                    label={messageResources.DOCUMENTS.VULNERABLE_PROOF}
                                                    errors={errors}
                                                    register={register}
                                                    type="string"
                                                    plaHolder={messageResources.COMMON.PASTE_LINK}
                                                    inputName="vulnerable_link"
                                                    original={true}
                                                />
                                            }
                                        </div>
                                        
                                    {/* </div> */}
                                </div>
                            </div>
                            <div className="fake-modal-footer">
                                <Button variant="secondary" onClick={handleClose}>
                                    {messageResources.BUTTON.CANCEL}
                                </Button>
                                <button className="btn btn-modal" onClick={() => methods.handleSubmit(onSubmit)}>
                                    {amend 
                                        ?
                                        messageResources.BUTTON.AMEND
                                        :
                                        messageResources.BUTTON.ADD
                                    }
                                </button>
                            </div>
                        </form>
                    </FormProvider>

                </div>
            </Modal.Body>
            {/* <Modal.Footer>
            
            </Modal.Footer> */}
        </Modal>
        </>
    );
}
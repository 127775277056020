import React, {useState,useEffect} from "react";
import {Modal, Button} from 'react-bootstrap';
import { messageResources } from "../../assets/util/messageResources";
import { toast } from 'react-toastify';
import { useForm, Controller, FormProvider } from "react-hook-form"
import { addCareersSchema } from '../../assets/util/yupValidations'
import InputText from "../forms/Input/InputText";
import { yupResolver } from "@hookform/resolvers/yup"
import { useEditCareersFormStudentMutation, useUpdateGooDocumentFromStudentMutation, 
    useAddGooDocumentFromStudentMutation, useDeleteGooDocumentFromStudentMutation } from '../../redux/student/studentSlice'


export default function EditCareersModal({buttonText, header, stuId, studentInfo, amend}) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [render,setRender] = useState(false);


    const [editCareersFormStudent] = useEditCareersFormStudentMutation()
    const [updateGooDocumentFromStudent] = useUpdateGooDocumentFromStudentMutation()
    const [deleteGooDocumentFromStudent] = useDeleteGooDocumentFromStudentMutation()
    const [addGooDocumentFromStudent] = useAddGooDocumentFromStudentMutation()

    const deleteDoc = (studentInfo,  docType) => {

        studentInfo.gooDocuments.map(docu => {
            //console.log("|---------> STU DOCS <><><><><><>")
            //let docId = (docu._id).toString()
            if(docu.type.value == docType){
                
                //updateGooDocumentFromStudent({id: docu._id, url_link: doc_link}).unwrap()
                deleteGooDocumentFromStudent({idDocu: docu._id, idStu: studentInfo._id}).unwrap()
            
            }
        })

    }

    const processDocs = (studentInfo, doc_link, docType) => {
        
        var addNew = true

        studentInfo.gooDocuments.map(docu => {
    
            if(docu.type.value == docType){
                
                updateGooDocumentFromStudent({id: docu._id, url_link: doc_link}).unwrap()
                
                addNew = false;
            }
        })
    
        if(addNew){
    
            const gooDoc = {}
            gooDoc.type = {}
            gooDoc.type.value = docType
            gooDoc.type.label = docType
            gooDoc.description = `${messageResources.DOCUMENTS.AUTO_DESC}`
            gooDoc.url_link = doc_link
            gooDoc.stuId = studentInfo._id
            gooDoc.stuName = studentInfo.name+" "+studentInfo.surname
    
            //const newDocRes = await axios.post("/docs/addNewDoc",gooDoc);
            addGooDocumentFromStudent(gooDoc).unwrap()
        }

    }


    useEffect(() => {


        if(amend){
            reset(studentInfo?.careers)
            setRender(true);
        }else {
            reset({})
        }

	}, [studentInfo]);

    const methods = useForm({
        resolver: yupResolver(addCareersSchema),
    });

	const {
        register,
        handleSubmit,
        reset,
        defaultValues,
		control,
        formState: { errors },
    } = methods;

    //console.log(errors)

    const onSubmit = async (formData) => {

        //console.log("NO ERRORSC")
        //console.log(formData);
        

		try{
			
            formData.stuId = stuId


            // ADDING DOCS TO STUDENT LIST
            // if(formData.rev_link_1 != ""){
            //     processDocs(studentInfo, formData.rev_link_1, messageResources.DOCUMENTS.REVIEW_1)
            // }else{
            //     deleteDoc(studentInfo, messageResources.DOCUMENTS.REVIEW_1)
            // }

            // if(formData.rev_link_2 != ""){
            //     processDocs(studentInfo, formData.rev_link_2, messageResources.DOCUMENTS.REVIEW_2)
            // }else{
            //     deleteDoc(studentInfo, messageResources.DOCUMENTS.REVIEW_2)
            // }

            // if(formData.rev_link_3 != ""){
            //     processDocs(studentInfo, formData.rev_link_3, messageResources.DOCUMENTS.REVIEW_3)
            // }else{
            //     deleteDoc(studentInfo, messageResources.DOCUMENTS.REVIEW_3)
            // }

            // if(formData.case_link_1 != ""){
            //     processDocs(studentInfo, formData.case_link_1, messageResources.DOCUMENTS.CASE_1)
            // }else{
            //     deleteDoc(studentInfo, messageResources.DOCUMENTS.CASE_1)
            // }

            // if(formData.case_link_2 != ""){
            //     processDocs(studentInfo, formData.case_link_2, messageResources.DOCUMENTS.CASE_2)
            // }else{
            //     deleteDoc(studentInfo, messageResources.DOCUMENTS.CASE_2)
            // }

            // if(formData.case_link_3 != ""){
            //     processDocs(studentInfo, formData.case_link_3, messageResources.DOCUMENTS.CASE_3)
            // }else{
            //     deleteDoc(studentInfo, messageResources.DOCUMENTS.CASE_3)
            // }


            await editCareersFormStudent(formData)
            
            if(amend){
                toast.success(messageResources.TOASTY.CAREERS_EDIT)
            }else{
                toast.success(messageResources.TOASTY.CAREERS_ADDED)
            }

            setShow(false)

		}catch(err){
			console.log(err);
		}
	}


	

    
    return (
        <>
        
        <button style={{marginRight: '10px'}} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" onClick={handleShow}>
            {buttonText}
        </button>
        
        
        <Modal show={show} onHide={handleClose} centered size="lg">
            <Modal.Header  closeButton>
            <Modal.Title>
                <div className="row">
                <div className="col-md-1"><i style={{fontSize: '32px'}} className="material-icons">error_outline</i></div>
                    <div className="col-md-10">{header}</div>
                </div>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body><div className="row">
                                
                                <FormProvider {...methods}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                    
                                        <div className="card card-box">
                                            <div className="card-body " id="bar-parent">
                                                <div className="row">
                                                
                                                    <InputText 
                                                        slots="col-md-4"
                                                        label={messageResources.CAREERS.CV}
                                                        errors={errors}
                                                        register={register}
                                                        type="string"
                                                        plaHolder={messageResources.CAREERS.CV}
                                                        inputName="cv_link"
                                                        original={true}
                                                    />
                                                    <InputText 
                                                        slots="col-md-4"
                                                        label={messageResources.CAREERS.VOCATIONAL}
                                                        errors={errors}
                                                        register={register}
                                                        type="string"
                                                        plaHolder={messageResources.CAREERS.VOCATIONAL}
                                                        inputName="voc_link"
                                                        original={true}
                                                    />

                                                    <InputText 
                                                        slots="col-md-4"
                                                        label={messageResources.CAREERS.PLAN}
                                                        errors={errors}
                                                        register={register}
                                                        type="string"
                                                        plaHolder={messageResources.CAREERS.PLAN}
                                                        inputName="plan_link"
                                                        original={true}
                                                    />
                                                    
                                                    {/* <div className="col-md-1">
                                                        <button type="submit" style={{marginTop:"25px"}} className="btn btn-primary" onClick={methods.handleSubmit(onSubmit)}>{messageResources.BUTTON.ADD}</button>
                                                    </div> */}
                                                </div>
                                                
                                                <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>{messageResources.COMMON.NOTES} 
                                                                {/* {errors?.contact_number && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>} */}
                                                            </label>
                                                            <textarea 
                                                                rows="4"
                                                                className="form-control" 
                                                                {...register('notes')}
                                                                placeholder={messageResources.COMMON.NOTES}/>
                                                        </div>
                                                    </div>
                                            </div>    
                                        </div>
                                        <div className="fake-modal-footer">
                                            <Button variant="secondary" onClick={handleClose}>
                                                {messageResources.BUTTON.CANCEL}
                                            </Button>
                                            <button className="btn btn-modal" onClick={() => methods.handleSubmit(onSubmit)}>
                                                {amend 
                                                    ?
                                                    messageResources.BUTTON.AMEND
                                                    :
                                                    messageResources.BUTTON.ADD
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </FormProvider>

                            </div></Modal.Body>
            {/* <Modal.Footer>
            
            </Modal.Footer> */}
        </Modal>
        </>
    );
}
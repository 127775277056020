import React from 'react'
import { messageResources } from '../../assets/util/messageResources'
import ClipboardCopy from '../../components/copyText/ClipboardCopy'
import Moment from 'moment'

const ProgrammeCardFourth = ({programme}) => {

    //console.log("|-----------> ProgrammeCard COMPONENT ProgrammeCard <--------|")
    //console.log(programme)

    return (
        <div className="col-lg-4">
            <div className="card card-topline-aqua">
                <div className="card-body no-padding height-9">
                    
                    <div>
                        <ul className="list-group list-group-unbordered">
                            <li className="list-group-item">
                                <b>{messageResources.PROGRAMME.WEEKS}</b> 
                                <a className="pull-right"><ClipboardCopy copyText={programme?.learnerWeeks}/></a>
                            </li>
                            <li className="list-group-item">
                                <b>{messageResources.PROGRAMME.WEEKLY}</b> 
                                <a className="pull-right"><ClipboardCopy copyText={programme?.weeklyHours}/></a>
                            </li>
                            <li className="list-group-item">
                                <b>{messageResources.PROGRAMME.DAYS}</b> 
                                <a className="pull-right"><ClipboardCopy copyText={programme?.weeklyDays}/></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProgrammeCardFourth
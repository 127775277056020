import React, {useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import StreetLeafMap from '../../components/map/StreetLeafMap'
import { Outlet } from 'react-router'
import { messageResources } from '../../assets/util/messageResources'
import axios from 'axios'
import noProfile from '../../assets/img/noProfile/noProfile.jpg'
import { useGetStaffByIdQuery, selectStaffById } from '../../redux/staff/staffApiSlice'
import EditPwModal from '../../components/modal/EditPwModal'
import EditStaffModal from '../../components/modal/EditStaffModal'
import useAuth from '../../hooks/useAuth'
import ModalImage from "react-modal-image"

const StaffProfile = () => {
	

	//console.log("|---------> StaffProfile COMPONENT StaffProfile <---------|")
	useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

	const { id } = useAuth()
	const {staffId} = useParams()
	//console.log(staffId)

	let editPw = true
	let rowTaken = "100%"
	if(id != staffId){
		editPw = false
		rowTaken = "50%"
	}

	// USE THIS ONE???
	const {
		data: staffInfo,
		isLoading,
		isSuccess,
		isError,
		error
	} = useGetStaffByIdQuery(staffId)


	const deliverLand = async () => {
	
		//console.log(`|-------------------------> [1] <----`)

		const response = await axios.get(`https://www.seroots.org.uk/template/openLand/${staffId}/staff`, {
			responseType: 'blob',
			headers: {
			'Content-Type': 'application/pdf'
			}
		})

        //Create a Blob from the PDF Stream
        const file = new Blob(
            [response.data], 
            {type: 'application/pdf'});
            
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            // console.log(`|-------------------------> [4] <----`)
            // console.log(fileURL)
            //Open the URL on new Window
            window.open(fileURL);
    }

    return (
        
		<>
			<Breadcrumb pageTitle={`${staffInfo?.name} ${staffInfo?.surname} ${messageResources.OPS_TITLES.PROFILE} `}/>
			
			<div className="row"> 
			
				{/* <!-- BEGIN PROFILE SIDEBAR --> */}
				{/* <div className="profile-sidebar"> */}
				<div className="col-lg-4 col-md-12">
					<div className="card card-topline-aqua">
						<div className="card-body no-padding height-9">
							<div className="row">
								<div className="profile-userpic">
								<ModalImage
										small={staffInfo?.picture_path 
											? staffInfo.picture_path 
											: noProfile }
										large={staffInfo?.picture_path 
											? staffInfo.picture_path 
											: noProfile }
										alt={`${staffInfo?.name} ${staffInfo?.surname}`}
										hideDownload={true}
										hideZoom={true}
										className="modal-image"
									/>

									{/* <img src={staffInfo?.picture_path 
												? staffInfo.picture_path 
												: noProfile } className="img-responsive" alt=""/> */}
								</div>
							</div>
							<div className="profile-usertitle">
								<div className="profile-usertitle-name">{staffInfo?.name} {staffInfo?.surname}</div>
							</div>
							<ul className="list-group list-group-unbordered">
								<li className="list-group-item">
									<b>{messageResources.STAFF.POSITION}</b> 
									<a className="pull-right">{staffInfo?.position?.value}</a>
								</li>
								<li className="list-group-item">
									<b>{messageResources.COMMON.EMAIL}</b> 
									<a className="pull-right">{staffInfo?.email}</a>
								</li>
								<li className="list-group-item">
									<b>{messageResources.CONTACT_INFO.NUMBER}</b> 
									<a className="pull-right">{staffInfo?.contact_number}</a>
								</li>
							</ul>
							<div className="profile-userbuttons">
								{/* <Link to="addKin" className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">
									{messageResources.STAFF.PW}
								</Link> */}
								<div className='row'>
									{editPw && <EditPwModal 
												buttonText={messageResources.STAFF.PW}
												staffId={staffId}
												header={messageResources.MODAL.CHANGE_PW} 
												rowTook = {rowTaken}
											/>}

									{staffInfo &&
										<>
											<EditStaffModal
												staffInfo={staffInfo}
												header={messageResources.MODAL.EDIT_PROFILE}
												rowTook = {rowTaken}
												amend = {true}
											/>	
										<div style={{width: rowTaken, marginTop: '10px'}}>
											<a className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" 
												style={{marginLeft: '8px'}}  onClick={deliverLand}>{messageResources.BUTTON.LAND}</a>
										</div>
										</>

									}
								</div>
							</div>
						</div>
					</div>
				</div>
			
				{staffInfo?.address?.lat && <StreetLeafMap slots="col-lg-8 col-md-12" address={staffInfo?.address} showHeader={true}/>}
			</div> 
				
			<Outlet />

        </>
					
    )
}

export default StaffProfile

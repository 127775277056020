import React, {useEffect,useState, useRef} from 'react'
import Select from 'react-select'
import Moment from 'moment'
import { useForm, Controller, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { ehcpSchema } from '../../../assets/util/yupValidations'
import { messageResources } from '../../../assets/util/messageResources'
import { learnDificOptions } from '../../../assets/util/selectOptions'
import numberDaysDifference from "../../../assets/util/numberDaysDifference"
import DatePicker from 'react-datepicker'
import "../../../assets/css/forForm.css"
import useAuth from '../../../hooks/useAuth'
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom'
import { useUpdateGooDocumentFromStudentMutation, 
    useAddGooDocumentFromStudentMutation,
    selectStudentById,
    useEditEhcpFromStudentMutation } from '../../../redux/student/studentSlice'
import { useUpdateNotificationMutation, 
    selectAllNotifications } from "../../../redux/notifications/notificationsApiCalls";



const Ehcp = () => {

    // function handleDateChange(date,field){
    //     alert("IS IS COMING HERE AGAIN?")
    //     alert(field.name)
    //     console.log(field)
    //     var ehcpButtonDiv = document.getElementsByName("ehcpDate")
    //     field.value = date
    //     alert(field.value)
        
    // }

    const {studentId,action} = useParams()
    const [render,setRender] = useState(false);
	
    let ehBtnRef = useRef();
    let navigate = useNavigate()
    const studentInfo = useSelector((state) => selectStudentById(state, studentId))
    const { id } = useAuth()

    //console.log(studentInfo)

    // const deleteDoc = (studentInfo,  docType) => {
    //     console.log("|---------> deleteDoc deleteDoc deleteDoc <><><><><><>")
    //     console.log(studentInfo._id)

    //     studentInfo.gooDocuments.map(docu => {
    //         //console.log("|---------> STU DOCS <><><><><><>")
    //         //let docId = (docu._id).toString()
    //         if(docu.type.value == docType){
                
    //             //updateGooDocumentFromStudent({id: docu._id, url_link: doc_link}).unwrap()
    //             deleteGooDocumentFromStudent({idDocu: docu._id, idStu: studentInfo._id}).unwrap()
            
    //         }
    //     })

    // }

    const processDocs = (studentInfo, doc_link, docType) => {

        //console.log("|---------> processDocs processDocs processDocs <><><><><><>")

        var addNew = true

        studentInfo.gooDocuments.map(docu => {
            //console.log("|---------> STU DOCS <><><><><><>")
            //let docId = (docu._id).toString()
    
            if(docu.type.value == docType){
                
                updateGooDocumentFromStudent({id: docu._id, url_link: doc_link}).unwrap()
                
                addNew = false;
            }
        })
    
        if(addNew){
    
            const gooDoc = {}
            gooDoc.type = {}
            gooDoc.type.value = docType
            gooDoc.type.label = docType
            gooDoc.description = `${messageResources.DOCUMENTS.AUTO_DESC}`
            gooDoc.url_link = doc_link
            gooDoc.stuId = studentInfo._id
            gooDoc.stuName = studentInfo.name+" "+studentInfo.surname
    
            //const newDocRes = await axios.post("/docs/addNewDoc",gooDoc);
            addGooDocumentFromStudent(gooDoc).unwrap()
        }

    }

    useEffect(() => {
        var ehcpButtonDiv = document.getElementById("fuera")
        if(ehcpButtonDiv) ehcpButtonDiv.style.display = 'none'
        reset(studentInfo);
        setRender(true);
    }, [studentInfo]);

    const methods = useForm({
        resolver: !studentInfo?.ehcp && yupResolver(ehcpSchema),
    });

    const {
        register,
        handleSubmit,
        defaultValues,
        reset,
        control,
        formState: { errors },
    } = methods;

    console.log(errors)

    const notis = useSelector(selectAllNotifications)
    const [editEhcpFromStudent] = useEditEhcpFromStudentMutation()
    const [updateNotification] = useUpdateNotificationMutation()
    const [updateGooDocumentFromStudent] = useUpdateGooDocumentFromStudentMutation()
    const [addGooDocumentFromStudent] = useAddGooDocumentFromStudentMutation()

    const onSubmit = async (formData) => {

        if(ehBtnRef.current){
            //console.log("------> DISABLE BUTTON <---------")
            ehBtnRef.current.setAttribute("disabled", "disabled");
        }

		try{
			
		 
             // THE SERVER RECORDS THE DAY ONE HOUR EARLIER AS IT IS UTC and UK is UTC+1
            // IF WE DON'T FORMAT LIKE THIS THE DATE STORED WILL BE
            // ONE DAY BEFORE AT 23:00
            // WE FORMAT TO ADD A TIME IN THE MIDDLE OF THE DAY TO
            // AVOID THE SERVER STORING ONE DAY BEFORE
            // if(formData.ehcpDate) formData.ehcpDate = Moment(formData.ehcpDate).format('DD MMMM YYYY 15:00:00')
            // if(formData.lastAnualReview) formData.lastAnualReview = Moment(formData.lastAnualReview).format('DD MMMM YYYY 15:00:00')
            // if(formData.nextAnualReview) formData.nextAnualReview = Moment(formData.nextAnualReview).format('DD MMMM YYYY 15:00:00')

            const ehData = {}
            ehData.isApproved = true
            formData.ehcpDate ? ehData.date = Moment(formData.ehcpDate).format('DD MMMM YYYY 15:00:00') : ehData.date = studentInfo.ehcp?.date
            formData.lastAnualReview ? ehData.last_review = Moment(formData.lastAnualReview).format('DD MMMM YYYY 15:00:00') : ehData.last_review = studentInfo.ehcp?.last_review

            !formData.nextAnualReview ? ehData.next_review = null : ehData.next_review = Moment(formData.nextAnualReview).format('DD MMMM YYYY 15:00:00')
            !formData.ehcp.ld ? ehData.ld = null : ehData.ld = formData.ehcp.ld
            
            ehData.stuId = studentInfo._id
            ehData.year_one = formData.year_one
            ehData.year_two = formData.year_two
            ehData.year_three = formData.year_three
            ehData.year_four = formData.year_four
            ehData.year_five = formData.year_five


            //console.log(ehData)

            await editEhcpFromStudent(ehData)

            if(action == "add"){
                // REMOVE NOTIFIATION FOR ADDING EHCP INFO FOR THIS STUDENT
                notis?.map(una => {
                    // console.log(" UNA JODERRRRRRRR ")
                    // console.log(una.isProcessed)
                    // console.log(una.studentId._id)
                    // console.log(studentId)
                    // console.log(una.studentId._id == studentId)
                    // console.log(una.type)
            
                    if(una.studentId._id == studentId && una.type == 2 && !una.isProcessed){
                        
                        const today = new Date()

                        let notiBody = {}
                        notiBody.idFor = una.id
                        notiBody.isProcessed = true
                        notiBody.processedBy = id
                        notiBody.dateProcessed = today
                        notiBody.daysElapsed = numberDaysDifference(una.createdAt,today)

                        updateNotification(notiBody).unwrap()

                        return
                    }
                })

            }

            // ADDING DOCS TO STUDENT LIST
            // if(formData.annual_link != ""){
            //     processDocs(studentInfo, formData.annual_link, messageResources.DOCUMENTS.ANNUAL)
            // }else{
            //     deleteDoc(studentInfo, messageResources.DOCUMENTS.ANNUAL)
            // }

            if(formData.doc_link != ""){
                processDocs(studentInfo, formData.doc_link, messageResources.DOCUMENTS.EHCP)
            }
            
            

            toast.success(messageResources.TOASTY.STUDENT_EDITED)
            //totalNotifications(userInfo._id,dispatch);
            //getStudent(studentId,dispatch)

            if(ehBtnRef.current){
                //console.log("------> DISABLE BUTTON <---------")
                ehBtnRef.current.removeAttribute("disabled");
            }
            
            navigate(`/students/${studentId}`)

		}catch(err){
			console.log(err);
		}
	}


    return (
        <div className="row eh" id="ehcpDiv">
                            
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                
                    <div className="card card-box">
                        <div className="card-body " id="bar-parent">
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>{messageResources.STUDENT.EHCP_DATE}
                                            {errors.ehcpDate && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                        </label>
                                        <Controller
                                            control={control}
                                            name='ehcpDate'
                                            render={({ field }) => (
                                                <DatePicker
                                                    onChange={(date) => field.onChange(date) }
                                                    //onChange={(date) => handleDateChange(date,field)}
                                                    selected={field.value ? field.value : Date.parse(studentInfo?.ehcp?.date)}
                                                    dateFormat="d MMMM yyyy"
                                                    //selected={field.value}
                                                    placeholderText="Select Date"
                                                    peekNextMonth
                                                    portalId="root-portal"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    //showWeekNumbers
                                                    dropdownMode="select"
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>{messageResources.STUDENT.ANNUAL_REVIEW}
                                            {errors.lastAnualReview && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                        </label>
                                        <Controller
                                            control={control}
                                            name='lastAnualReview'
                                            render={({ field }) => (
                                                <DatePicker
                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field.value ? field.value : Date.parse(studentInfo?.ehcp?.last_review)}
                                                    //isClearable
                                                    //selected={field.value}
                                                    locale="en-GB"
                                                    dateFormat="d MMMM yyyy"
                                                    placeholderText="Select Date"
                                                    peekNextMonth
                                                    portalId="root-portal"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>{messageResources.STUDENT.NEXT_REVIEW}
                                            {errors.nextAnualReview && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                        </label>
                                        <Controller
                                            control={control}
                                            name='nextAnualReview'
                                            render={({ field }) => (
                                                <DatePicker
                                                    onChange={(date) => field.onChange(date)}
                                                    //selected={field.value ? field.value : Date.parse(studentInfo.ehcp?.next_review)}
                                                    selected={field.value}
                                                    locale="en-GB"
                                                    isClearable
                                                    dateFormat="d MMMM yyyy"
                                                    placeholderText="Select Date"
                                                    peekNextMonth
                                                    portalId="root-portal"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            )}
                                        />													
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{messageResources.STUDENT.EHCP_LINK} 
                                            {errors?.doc_link && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                        </label>
                                        <input 	type="text" 
                                                className="form-control" 
                                                {...register('doc_link')}
                                                placeholder={messageResources.STUDENT.EHCP_LINK}/>
                                    </div>    
                                </div>
                        
                                
                            </div>
                            <div className='row'>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>{messageResources.STUDENT.REVIEW_ONE}</label>
                                        <input 	type="text" 
                                                className="form-control" 
                                                {...register('year_one')}
                                                placeholder={messageResources.STUDENT.REVIEW_ONE}/>
                                    </div>    
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>{messageResources.STUDENT.REVIEW_TWO}</label>
                                        <input 	type="text" 
                                                className="form-control" 
                                                {...register('year_two')}
                                                placeholder={messageResources.STUDENT.REVIEW_TWO}/>
                                    </div>    
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>{messageResources.STUDENT.REVIEW_THREE}</label>
                                        <input 	type="text" 
                                                className="form-control" 
                                                {...register('year_three')}
                                                placeholder={messageResources.STUDENT.REVIEW_THREE}/>
                                    </div>    
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>{messageResources.STUDENT.REVIEW_FOUR}</label>
                                        <input 	type="text" 
                                                className="form-control" 
                                                {...register('year_four')}
                                                placeholder={messageResources.STUDENT.REVIEW_FOUR}/>
                                    </div>    
                                </div>

                            </div>
                            <div className='row'>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>{messageResources.STUDENT.REVIEW_FIVE}</label>
                                        <input 	type="text" 
                                                className="form-control" 
                                                {...register('year_five')}
                                                placeholder={messageResources.STUDENT.REVIEW_FIVE}/>
                                    </div>    
                                </div>
                                {/* <div className="col-md-4">
                                    <div className="form-group">
                                        <label>{messageResources.STUDENT.ANNUAL_LINK}</label>
                                        <input 	type="text" 
                                                className="form-control" 
                                                {...register('annual_link')}
                                                placeholder={messageResources.STUDENT.ANNUAL_LINK}/>
                                    </div>    
                                </div> */}

                                <div className="col-md-7">
                                    <div className="form-group">
                                        <label>{messageResources.STUDENT.LD}
                                            {errors?.kinship && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                        </label>

                                        <Controller
                                            name="ehcp.ld"
                                            control={control}
                                            render={({ field }) => (
                                                <Select options={learnDificOptions} 
                                                    isMulti
                                                    defaultValue={studentInfo?.ehcp?.ld}
                                                    menuPortalTarget={document.getElementById('root')}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                
                                <div className="col-md-1">
                                    <button type="submit" style={{marginTop:"25px"}} className="btn btn-primary" ref={ehBtnRef} onClick={methods.handleSubmit(onSubmit)}>{messageResources.BUTTON.SUBMIT_FORM}</button>
                                </div>
                            </div>
                        </div>                          
                    </div>                     
                </form>
            </FormProvider>
        </div>

    )
}

export default Ehcp
import {useEffect} from 'react'
import List from '../../components/list/List'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Messages from '../../components/messages/Messages'
import { Link, useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import formatProgramme from '../../assets/util/formatProgramme'
import Moment from 'moment'
import { messageResources } from '../../assets/util/messageResources'
import { selectAllStudents } from '../../redux/student/studentSlice'
import { useSelector } from 'react-redux'
import ClipboardCopy from '../../components/copyText/ClipboardCopy'

const AcademicList = () => {

    //console.log("|-----> AcademicList COMPONENT AcademicList <------|")
    const { type } = useParams()

    const { roles } = useAuth();
    let showButton = false
    if(roles?.includes(2)){
        showButton = true
    }

    const columnsForAllProgrammes = [
        
        { title: messageResources.COMMON.NAME, field: "name", defaultSort: "asc", render:rowData=><Link to={`/students/${rowData.stuId}`}> {rowData.name} </Link> }, 
        { title: messageResources.COMMON.SURNAME,  field: "surname", defaultSort: "asc", render:rowData=><ClipboardCopy copyText={rowData.surname} /> }, 
        { title: messageResources.PROGRAMME.PROG,  field: "programme.label" , sorting:false, emptyValue:()=><em>N/A</em>, render:rowData=><ClipboardCopy copyText={rowData.programme.label} />},
        { title: messageResources.PROGRAMME.ESP,  field: "speciality.label" , sorting:false, emptyValue:()=><em>N/A</em>, render:rowData=><ClipboardCopy copyText={rowData.speciality.label} />},
        { title: messageResources.PROGRAMME.START, field: "startDate", type: 'date', dateSetting: { format: 'dd/MM/yyyy'}, render:rowData=><ClipboardCopy copyText={Moment(rowData.startDate).format('DD MMMM YYYY')} />},
        { title: messageResources.PROGRAMME.END, field: "endDate", type: 'date', dateSetting: { format: 'dd/MM/yyyy'}, render:rowData=><ClipboardCopy copyText={Moment(rowData.endDate).format('DD MMMM YYYY')} />},
        { title: messageResources.PROGRAMME.YEAR,  hidden: type === "current" ? true : false , field: "academicYear", render:rowData=><ClipboardCopy copyText={rowData.academicYear} />  },
        // { title: messageResources.PROGRAMME.WEEKLY,  field: "weeklyHours", render:rowData=><ClipboardCopy copyText={rowData.weeklyHours} />  },
        // { title: messageResources.PROGRAMME.WEEKS,  field: "learnerWeeks", render:rowData=><ClipboardCopy copyText={rowData.learnerWeeks} />  },
        { title: messageResources.PROGRAMME.TOTAL,  field: "totalHours", render:rowData=><ClipboardCopy copyText={rowData.totalHours} />  },
        // { title: messageResources.PROGRAMME.FORQUALY,  field: "qualyHours", emptyValue:()=><em>N/A</em>, render:rowData=><ClipboardCopy copyText={rowData.qualyHours} />  },
        // { title: messageResources.PROGRAMME.EPP,  field: "eppHours", render:rowData=><ClipboardCopy copyText={rowData.eppHours} />  },
        { title: messageResources.COMMON.ACTIONS,  render:rowData=>  <>
                                                                
                                                                        <Link   className="btn btn-outline-success btn-circle" 
                                                                                to={`/academic/mark/${rowData.stuId}`}
                                                                                state={{programme: rowData, showButton: showButton }}> FULL PROGRAMME INFO  </Link> 
                                                                    </> }, 
    ];

    useEffect(() => {       
        window.scrollTo(0, 0);
    })


    const allOfThem = useSelector(selectAllStudents)
    let content;
    let title;
    let programsToShow = []
    allOfThem.map(student => {
        if(type === "current"){
            title = messageResources.OPS_TITLES.STUDYING
            if(student.activeProgramme){
                
                let prToList = formatProgramme(student.activeProgramme)
                prToList.name = student.name
                prToList.surname = student.surname
                prToList.stuId = student._id
                
                programsToShow.push(prToList)
            }
        }else if(type === "completed"){
            title = messageResources.OPS_TITLES.COMPLETED
            if(student.programmes?.length > 0){
                student.programmes?.map(program => {
                    if(!program.isActive){
                        
                        let prToList = formatProgramme(program)
                        prToList.name = student.name
                        prToList.surname = student.surname
                        prToList.stuId = student._id
                        
                        programsToShow.push(prToList)
                    }
                })
            }
        }    
    })
    

    if(programsToShow.length == 0){
        content = <Messages message={messageResources.BANNER.NO_STUDYING} type="alert" />
    }else if(programsToShow.length > 0){
        content = <List   titleForTable="" 
                    dataForTable={JSON.parse(JSON.stringify(programsToShow))} 
                    columnsForTable={columnsForAllProgrammes}
                    search={false}
                    colButton={true}
                    filter={true}
                    pageSize={programsToShow.length}/>
    }


    return ( 

        <>    
            <Breadcrumb pageTitle={`${title} (${programsToShow.length} records)`}/>
            <div className="row">
                <div className="col-md-12">
                    {/* <List   titleForTable="" 
                            dataForTable={JSON.parse(JSON.stringify(stusToShow))} 
                            columnsForTable={columnsForAllProgrammes}
                            search={false}
                            colButton={true}
                            filter={true}
                            pageSize={30}/> */}
                    {content}
                </div>
            </div>
        </>

    
    )
}

export default AcademicList

import React, {useEffect} from 'react';
import StudentForm from './StudentForm'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { selectStudentById } from '../../redux/student/studentSlice';

const EditStudent = () => {

	const {studentId} = useParams()
	//console.log(studentId)

	const studentInfo = useSelector((state) => selectStudentById(state, studentId))
    //console.log(studentInfo)

    useEffect(() => {
		//console.log("<><><><><><> STUINFO useEffect useEffect useEffect<><><><><><>")
		window.scrollTo(0, 0);
	}, []);

    return (
        <StudentForm studentData={studentInfo} isEdit={true}/>
    )
};

export default EditStudent;

import React from 'react';
import { messageResources } from '../../../assets/util/messageResources'

const EmailInput = ({ formData, setFormData }) => {
    return (
        <div className="card card-box">
            <div className="card-head">
                <header>{messageResources.COMMON.EMAIL}</header>
                <div className="tools">
                    <a className="t-collapse btn-color fa fa-chevron-down"
                        href="javascript:;"></a>
                </div>
            </div>
            <div className="card-body " id="bar-parent">
                            
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            {/* <label for="simpleFormEmail">{messageResources.COMMON.EMAIL}
                                {errors.contactEmail && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED_EMAIL} </span>}
                            </label> */}
                            <input 
                                type="email" 
                                className="form-control" 
                                placeholder={messageResources.COMMON.EMAIL}
                                value={formData.email}
                                onChange={(e) => {
                                    setFormData({ ...formData, email: e.target.value });
                                }}/>
                        </div>
                    </div>
                </div>
            </div> 
        </div>       
    )
};

export default EmailInput;














import { Outlet, Link, useLocation, Navigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useRefreshMutation } from "../../redux/auth/authApiSlice";
import usePersist from "../../hooks/usePersist";
import { useSelector } from "react-redux";


const PersistLogin = () => {

    //console.log("|------> COMPONENT PersistLogin COMPONENT <-------|")
    // originaly from a checkbox that the user checks
    //const [persist] = usePersist()
    // in this case I always want to persist
    const [persist] = useState(true) 
    //console.log(persist)

    const location = useLocation();

    const token = useSelector((state) => state.auth.token)
    //console.log("|------> TOKEN IN  PersistLogin COMPONENT <-------|")
    //console.log(token)

    const effectRan = useRef(false)

    const [trueSuccess, setTrueSuccess] = useState(false)

    // isUninitialized means that the refresh function has not been called yet
    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useRefreshMutation()


    useEffect(() => {

        //console.log("|------> PersistLogin useEffect <-------|")
        //console.log(!token & persist)
        //console.log(effectRan.current)
        //console.log(process.env.NODE_ENV)
        // react 18 strict mode
        //if(effectRan.current === true || process.env.NODE_ENV !== 'development'){

            const verifyRefreshToken = async () => {
                //console.log("|------> Verifying Refresh Token <-------|")
                try{
                    // const response = await refresh()
                    await refresh()
                    // const { accessToken } = response.data
                    setTrueSuccess(true)

                }catch(err){
                    console.error(err)
                }
            }

            if(!token & persist) verifyRefreshToken()
        //}

        // first time useEffect runs it sets it to true
        return () => effectRan.current = true

        // eslint-disable-next-line
    }, [])


    let content = "contact admin"
    if(!persist) { // persist: no
        //console.log('|-----> PersistLogin No Persist <------|')
        content = <Outlet />
    }else if(isLoading){ // persist: yes, token: no
        //console.log('|-----> PersistLogin Loading <------|')
        content = <p>Loading....</p>
    }else if(isError){ // persist: yes, token: no
        //console.log('|-----> PersistLogin Error <------|')
        content = (
            // <p>
            //     {error.data?.message}
            //     <Link to="/login">Please login again</Link>
            // </p>
            <Navigate to="/login" state={{ from: location }} replace />
        )
    }else if(isSuccess && trueSuccess){ // persist: yes, token: yes
        //console.log('|-----> PersistLogin Success <------|')
        content = <Outlet />
    }else if(token && isUninitialized){ // persist: yes, token: yes
        //console.log('|-----> PersistLogin Token & Uninitialized <------|')
        //console.log(isUninitialized)
        content = <Outlet />
    }

    return content 
}

export default PersistLogin


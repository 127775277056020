// redux and redux store both stand for
// container for javascript app 
// it stores the whole state of the app in a 
// inmmutable object tree
// we should only have a single store 
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../redux/auth/authSlice"
import notificationsReducer from "../redux/notifications/notificationsSlice"
import { apiSlice } from "./api/apiSlice";

export const store = configureStore({
    reducer: {
        // reducer called user equals to the one set up in its file
        ///user: userReduder,
        // dinamycally named 
        // [apiSlice.reducerPath] we named 'api' in apiSlice.js
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
        notifications: notificationsReducer
    },
    // as we use RTK Query with a store there is a requiremet to
    // use a middleware 
    // we add the middleware created by apislice
    middleware: getDefaultMiddleware => 
        getDefaultMiddleware().concat(apiSlice.middleware)
    //devTools: true
})


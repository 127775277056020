// slice name comes from splitting up redux state object into 
// multiple slices of state

import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth",
    initialState: { token: null },
    reducers: {
        setCredentials: (state, action) => {
            const { accessToken } = action.payload
            console.log('|-----> SET CREDENTIALS <-----|')
            //console.log(accessToken)
            state.token = accessToken
        },
        logout: (state, action) => {
            state.token = null
        }
    }
})

export const { setCredentials, logout } = authSlice.actions;

export default authSlice.reducer;

//SELECTOR when usong use selector we can use 'useSelecter(selectCurrentToken)'
// instead of 'useSelecter(state.user.token)'
export const selectCurrentToken = (state) => state.auth.token
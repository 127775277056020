import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from "../api/apiSlice"

const programmesAdapter = createEntityAdapter({})

const initialState = programmesAdapter.getInitialState()

export const programmesApiCalls = apiSlice.injectEndpoints({

    endpoints: builder => ({
        getProgrammes: builder.query({
            query: () => '/programmes',
            transformResponse: responseData => {

                // ONLY LOAD NOTIS FROM ACTIVE STUDENTS
                const loadedPrograms = []
                responseData.map(noti => {
                    // ESSENTIAL TO USE NORMALIZATION AND MONGO DB
                    //console.log("XXXXXXXXXX|--> getNotifications SLICEEEEEEEE getNotifications <--|XXXXXXXXX")
                    noti.id = noti._id
                })
                return programmesAdapter.setAll(initialState, loadedPrograms)
            },
            providesTags: (result, error, arg) => {
                if(result?.ids){
                    return [
                        { type: 'Programmes', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'Programmes', id}))
                    ]
                } else return [{ type: 'Programmes', id: 'LIST'}]
                
            }
        }),
                // addProgramm: builder.mutation({
        //     query: programmeData => ({
        //         url: '/programmes/add',
        //         method: 'POST',
        //         body: {
        //             programmeData
        //         }
        //     }),
        //     validateStatus: (response, result) => {
        //         console.log("|----> VALIDATING addNotification RESPONSE <---")
        //         console.log(response.status)
        //         return response.status === 200 && !result.error
        //     },
        //     invalidatesTags: (result, error, arg) => [
        //         { type: 'Programmes', id: 'LIST'}
        //     ]
        // }),
        updateProgramme: builder.mutation({
            query: programmeToUpdate => ({
                url: `/programmes/subject/${programmeToUpdate._id}`,
                method: 'PUT',
                body: programmeToUpdate
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Student', id: 'LIST' }
            ]    
        }),
        addDestinationToProgram: builder.mutation({
            query: destToAdd => ({
                url: `/programmes/destination/${destToAdd.progId}`,
                method: 'PUT',
                body: destToAdd
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Student', id: 'LIST' }
            ]
        }),
    })
})

export const {
    useGetProgrammesQuery,
    useAddDestinationToProgramMutation,
    useUpdateProgrammeMutation,
} = programmesApiCalls



// returns the query result object
export const selectProgrammesResult = programmesApiCalls.endpoints.getProgrammes.select()

// creates memoized selector
// used below to create selectors
const selectProgrammesData = createSelector(
    selectProgrammesResult,
    programmesResult => programmesResult.data // normalized state object ids & entities
)

export const {
    selectAll: selectAllProgrammes,
    selectById: selectProgrammeById,
    selectIds: selectProgrammesIds
} = programmesAdapter.getSelectors(state => selectProgrammesData(state) ?? initialState)





const numberDaysDifference = (dayOne,dayTwo) => {

    //console.log(dayOne)
    let date1 = Date.parse(dayOne)
    let date2 = Date.parse(dayTwo)
    //console.log(date1)

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2 - date1;

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    console.log(`THERE ARE ${diffInDays} DAYS DIFFERENCE`);

    return diffInDays;

}

export default numberDaysDifference
import React, {useRef} from 'react'
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import ReCAPTCHA from 'react-google-recaptcha'
import { messageResources } from '../../assets/util/messageResources'
import {registerSchema} from '../../assets/util/yupValidations'
import lettersOnly from '../../assets/img/logo/lettersOnlyWhite.png'
import treeOnly from '../../assets/img/logo/treeOnlyWhite3.png'
import "./register.css"
import useIpAddress from '../../hooks/useIpAddress'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { useRegisterStaffMutation } from '../../redux/auth/authApiSlice'


const Register = () => {

    //console.log("<><><><><><> Register Register  <><><><><><>")

    // ALLOW REGISTRATION ONLY FROM IPS FROM THE OFFICE
    const locationData = useIpAddress();
    //console.log(locationData);
    const IpAddress = locationData?.IPv4;
    //console.log(IpAddress);
    const recaptRef = useRef();

    const { register, handleSubmit, watch, reset, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(registerSchema),
    });

    console.log(errors)

    const onSubmit = (formData) => {

        //console.log(recaptRef)
        //formData.recaptToken = recaptRef
        //console.log(formData);
        console.log("NO ERRORS")

        registerUSer(formData);
            
    };

    const [registerStaff] = useRegisterStaffMutation()
    let navigate = useNavigate();    

    const registerUSer = async (formData) => {

        try{

            //console.log("<><><><><><> recaptToken recaptToken recaptToken  <><><><><><>")

            const recaptToken = await recaptRef.current.executeAsync();
            // it allows to reexecute the recaptcha check 
            recaptRef.current.reset();

            //console.log(recaptToken)
            formData.recaptToken = recaptToken
            formData.email = formData.email.toLowerCase();

            const staffRegistered = registerStaff(formData)

            staffRegistered.then(function(staff) {
                //console.log(staff.data);
                if(staff.data.email){
                
                    toast.info(messageResources.TOASTY.REGISTERED_SUCCESS,{
                        autoClose: 10002,
                    })
                    navigate('/login')
                    
                }else if(staff.data.message){
                    
                    toast.error(messageResources.TOASTY.ALREADY_REGISTERED,{
                        autoClose: 10002,
                    })
                }
                
            })
        
        
        }catch(err){
            console.log(err)
        }

    }



    return ( 
        
        <div className="limiter">
            <div className="container-login100 page-background">
                {/* <pre>{JSON.stringify(values, undefined, 2)}</pre><br/> 
                <pre>{JSON.stringify(errors, undefined, 2)}</pre> */}
                <div className="wrap-login100-register">
                    <form className="login100-form validate-form" id="regForm" onSubmit={handleSubmit(onSubmit)}>
                        <div className='d-flex justify-content-center'>
                                <img src={treeOnly} />
                        </div> 
                        <div className='d-flex justify-content-center'>
                            <img src={lettersOnly} />
                            {/* <i class="twa twa-2x twa-grimacing" style={{margin: 0}}></i> */}
                        </div>
                        <span className="login100-form-title p-b-34 p-t-27">
                        {messageResources.REGISTRATION.REGISTER}
                        </span>
                        <div className="row">
                            <div className="col-lg-6">
                                {errors.name && <span className="errorSpanOut"> {errors.name.message} </span>}
                                <div className="wrap-input100 validate-input">
                                    <input className="input100" 
                                            type="text" 
                                            placeholder={messageResources.COMMON.NAME}
                                            {...register('name')}
                                        />
                                    <span className="focus-input100" data-placeholder="&#xf207;"></span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                {errors.surname && <span className="errorSpanOut"> {errors.surname.message} </span>}
                                <div className="wrap-input100 validate-input">
                                    <input className="input100" 
                                            type="text"
                                            placeholder={messageResources.COMMON.SURNAME} 
                                            {...register('surname')}
                                        />
                                    <span className="focus-input100" data-placeholder="&#xf207;"></span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 p-t-20">
                                {errors.email && <span className="errorSpanOut"> {errors.email.message} </span>}
                                <div className="wrap-input100 validate-input">
                                    <input className="input100" 
                                            type="text"
                                            placeholder={messageResources.COMMON.EMAIL} 
                                            {...register('email')}
                                        />
                                    <span className="focus-input100" data-placeholder="&#xf207;"></span>
                                </div>
                            </div>
                            <div className="col-lg-6 p-t-20">
                                {errors.retEmail && <span className="errorSpanOut"> {messageResources.VALIDATIONS.EMAILS_MATCH} </span>}
                                <div className="wrap-input100 validate-input">
                                    <input className="input100" 
                                            type="text"
                                            placeholder={messageResources.REGISTRATION.RE_EMAIL} 
                                            {...register('retEmail')}
                                        />
                                    <span className="focus-input100" data-placeholder="&#xf207;"></span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 p-t-20">
                                {errors.password && <span className="errorSpanOut"> {errors.password.message} </span>}
                                <div className="wrap-input100 validate-input">
                                    <input  className="input100" 
                                            type="password"
                                            placeholder={messageResources.COMMON.PASSWORD}
                                            {...register('password')}
                                        />

                                    <span className="focus-input100" data-placeholder="&#xf191;"></span>
                                </div>
                            </div>
                            <div className="col-lg-6 p-t-20">
                                {errors.confirmPassword && <span className="errorSpanOut"> {messageResources.VALIDATIONS.PASSWORDS_MATCH} </span>}
                                <div className="wrap-input100 validate-input">
                                    <input  className="input100" 
                                            type="password"  
                                            placeholder={messageResources.REGISTRATION.CONF_PASS} 
                                            {...register('confirmPassword')}
                                        />

                                    <span className="focus-input100" data-placeholder="&#xf191;"></span>
                                </div>
                            </div>
                        </div>

                        <ReCAPTCHA  sitekey='6LfPnGwgAAAAAF3e8lQCTEzMRvJ7Cl6yACMwNZWU'
                                    size='invisible'
                                    ref={recaptRef}
                                
                                />

                        <div className="container-login100-form-btn">
                            <Link to="/verify/register" className="login100-form-btn" onClick={handleSubmit(onSubmit)}> 
                                {messageResources.REGISTRATION.REGISTER}
                            </Link>
                        </div>
                        <div className="text-center p-t-30">
                            <a href="/login" className="txt1">
                                {messageResources.REGISTRATION.ALREADY}
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Register

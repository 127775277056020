

const formatData = (type,value) => {

    //console.log(value)
    
    let valueNoSpaces; 

    if(type === "phone"){

        // no letters or spaces
        valueNoSpaces = value.replace(/[^\d]/g, '');
        //console.log(valueNoSpaces)

        if(valueNoSpaces.slice(0,3) === '020'){  
            //console.log(`${valueNoSpaces.slice(0, 3)} ${valueNoSpaces.slice(3,7)} ${valueNoSpaces.slice(7)}`)
            return `${valueNoSpaces.slice(0, 4)} ${valueNoSpaces.slice(4,7)} ${valueNoSpaces.slice(7)}`;
        }else if(valueNoSpaces.slice(0,2) === '07'){
            //console.log(`${valueNoSpaces.slice(0, 5)} ${valueNoSpaces.slice(5,8)} ${valueNoSpaces.slice(8)}`)
            return `${valueNoSpaces.slice(0, 5)} ${valueNoSpaces.slice(5,8)} ${valueNoSpaces.slice(8)}`;
        }else{
            //console.log('NOT YET')
            return value
        }

    }else if(type === "nin"){
        // no spaces
        valueNoSpaces = value.replace(/\s/g, '');
        //console.log(valueNoSpaces)
        //console.log(`${valueNoSpaces.slice(0, 2)} ${valueNoSpaces.slice(2,4)} ${valueNoSpaces.slice(4,6)} ${valueNoSpaces.slice(6,8)} ${valueNoSpaces.slice(8)}`)
        return `${valueNoSpaces.slice(0, 2)} ${valueNoSpaces.slice(2,4)} ${valueNoSpaces.slice(4,6)} ${valueNoSpaces.slice(6,8)} ${valueNoSpaces.slice(8)}`;

    }

}

export default formatData
import {useEffect, useState, useRef } from 'react'
import InputSelect from '../../components/forms/Input/InputSelect'
import { useSelector } from 'react-redux'
import List from '../../components/list/List'
import { useForm, Controller, FormProvider } from "react-hook-form"
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { messageResources } from '../../assets/util/messageResources'
import { yearChartOptions } from '../../assets/util/selectOptions'
import axios from 'axios'
import { selectCurrentToken } from '../../redux/auth/authSlice'

const Achievements = () => {

    const columnsAchievements = [

        { title: `${messageResources.STUDENT.NAME}`, field: "stuNAme" },
        { title: `${messageResources.COMMON.SUBJECT}`,  field: "subName" },  
        { title: `${messageResources.SUBJECT.OUTCOME}`,  field: "achievement", grouping: false }, 
        { title: `${messageResources.SUBJECT.ACTUAL}`,  field: "actual", type: 'date', dateSetting: { format: 'dd/MM/yyyy'}, grouping: false }, 
        
    ];

    useEffect(() => {       
        window.scrollTo(0, 0);
    },[])

    const formatSubject = (subject,stuNAme) => {
        
        let subjectToList = {}
        subjectToList.subName = subject.label
        subjectToList.achievement = subject.achievement?.label
        subjectToList.stuNAme = stuNAme
        subjectToList.actual = subject.actualDate
        
        return subjectToList
    
    }


    let ehBtnRef = useRef();
    
    const methods = useForm({});
    const {
        register,
        handleSubmit,
        defaultValues,
        reset,
        control,
        formState: { errors },
    } = methods;



    const token = useSelector(selectCurrentToken)
    const [mainStats,setMainStats] = useState([])
    const [year,setYear] = useState("")

    const onSubmit = async (formData) => {

		try{

            if(ehBtnRef.current){
                //console.log("------> DISABLE BUTTON <---------")
                ehBtnRef.current.setAttribute("disabled", "disabled");
            }
            // http://localhost:8800/serruts/studentsApi/allStudents/db
            const response = await axios.get(
                            `https://www.seroots.org.uk/studentsApi/allStudents/db`, {
                                
                            headers: {
                                        Authorization: `Bearer ` + token
                                    }    
            });

            let subToList
            let allToList = []

            response.data.map(student => {

                if(student.programmes?.length > 0){

                    student.programmes.map( program => {

                        if(program.academicYear == formData.year.label){

                            program.eepActivities?.map(subject => {
                                subToList = formatSubject(subject,`${student.name} ${student.surname}`)
                                allToList.push(subToList)
                            })

                            program.cgQualifications?.map(subject => {
                                subToList = formatSubject(subject,`${student.name} ${student.surname}`)
                                allToList.push(subToList)
                            })

                            program.suppActivities?.map(subject => {
                                subToList = formatSubject(subject,`${student.name} ${student.surname}`)
                                allToList.push(subToList)
                            })
                            
                        }

                    })

                }


            })

            //console.log('allToList allToList allToList allToList')
           // console.log(allToList)
            setMainStats(allToList)
            setYear(formData.year.label)

            if(ehBtnRef.current){
                //console.log("------> DISABLE BUTTON <---------")
                ehBtnRef.current.removeAttribute("disabled");
            }

        }catch(err){
			console.log(err);
		}
	}



    return (
        <>
        <Breadcrumb pageTitle={`${messageResources.OPS_TITLES.ACHIEVEMENTS} ${year}`}/>
        
        <div className="card card-topline-aqua">
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        
                            <div className="card-body " id="bar-parent">
                                <div className="row">
                                    
                                    <InputSelect 
                                        slots="col-md-2" 
                                        label={messageResources.CHART.YEAR} 
                                        errors={errors}
                                        control={control}
                                        inputName="year"
                                        isClearable={true}
                                        original={false}
                                        selectOptions={yearChartOptions}
                                    />
                                    <div className="col-md-1">
                                        <button type="submit" style={{marginTop:"25px"}} className="btn btn-primary" ref={ehBtnRef} onClick={methods.handleSubmit(onSubmit)}>{messageResources.BUTTON.SEARCH}</button>
                                    </div>
                                </div>
                            </div>
                        
                    </form>
                </FormProvider>
            </div>
        
            {mainStats?.length > 0 &&
                <div className="row">
                    <div className="col-md-12">
                        <List   titleForTable={`${mainStats.length} Subjects in ${year} - - Drag and drop headers to the grey line to group results`}
                                dataForTable={JSON.parse(JSON.stringify(mainStats))} 
                                columnsForTable={columnsAchievements}
                                search={false}
                                colButton={true}
                                group={true}
                                filter={true}
                                pageSize={`${mainStats.length}`}/>
                    </div>
                </div>
            }
        
        
        
        </>
    )
}

export default Achievements
import React from 'react'
import StudentForm from './StudentForm'
import "../../assets/css/forForm.css"

const AddStudent = () => {

    return (
    
		<StudentForm studentData={{}} isEdit={false}/>

    )
}

export default AddStudent

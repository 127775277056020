import { useEffect } from 'react'
import Card from '../../components/card/Card'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { selectAllStudents } from '../../redux/student/studentSlice'
import { messageResources } from '../../assets/util/messageResources'
import { useSelector } from 'react-redux'

const StudentsCards = () => {
    

    useEffect(() => {       
        window.scrollTo(0, 0);
    },[])

    //console.log("|-----> StudentsCards COMPONENT StudentsCards <------|")
    const allOfThem = useSelector(selectAllStudents)

    return (
    
        <>
            <Breadcrumb pageTitle={messageResources.OPS_TITLES.STU_CARDS}/>
            <div className="row">
                {allOfThem && allOfThem.length > 0 && allOfThem.map((student, i) => (
                
                    <Card slots="col-md-3" entityId={student.id} />
            
                ))}
            </div> 
        </>
    
    )
}

export default StudentsCards

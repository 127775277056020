import React from 'react'
import { messageResources } from '../../assets/util/messageResources'
import InfoModal from '../../components/modal/InfoModal'
import EditTaxiModal from '../../components/modal/EditTaxiModal'
import useAuth from '../../hooks/useAuth';

const TaxiCard = ({taxi, entityId, isStuActive}) => {

    //console.log(taxi)
    //console.log(entityId)
    //console.log("|---> TaxiCard COMPONENT TaxiCard <---|")
    const { roles } = useAuth()
    

    return (
        <div className="col-lg-6 col-md-12">
            <div className="card card-topline-aqua">
                <div className="card-body no-padding height-9">
                    <div className="profile-usertitle">
                        <div className="profile-usertitle-name">
                            
                            {(roles?.includes(2) || roles?.includes(3) || roles?.includes(5)) && isStuActive &&
                                <div style={{marginBottom: '10px'}}>

                                    <InfoModal 
                                        buttonText={messageResources.BUTTON.DELETE}
                                        stuId={entityId}
                                        toDeleteId={taxi._id}
                                        methodType="deleteTaxi"
                                        toastText={messageResources.TOASTY.TAXI_DELETED}
                                        header={messageResources.MODAL.DELETE_TAXI} 
                                        text={messageResources.MODAL.DEL_TAXI_TEXT}
                                    />
                                    
                                    <EditTaxiModal
                                        buttonText={messageResources.BUTTON.AMEND}
                                        stuId={entityId}
                                        taxiToEdit={taxi}
                                        amend={true}
                                        header={messageResources.MODAL.AMEND_TAXI} 
                                    />
                
                                </div>
                            }

                        </div>
                    </div>
                    <div>
                    <ul className="list-group list-group-unbordered">
                        <li className="list-group-item">
                            <b>{messageResources.TAXI.COMPANY}</b> 
                            <a className="pull-right">{taxi.company}</a>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.CONTACT_INFO.NUMBER}</b> 
                            <a className="pull-right">{taxi.contact_number}</a>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.COMMON.EMAIL}</b> 
                            <a className="pull-right">{taxi.email}</a>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.STUDENT.INDEPENDENT}</b> 
                            <a className="pull-right">{taxi.isIndependent ? "YES" : "NO"}</a>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.COMMON.NOTES}</b> 
                            <a className="pull-right">{taxi.notes}</a>
                        </li>
                    </ul>
                    </div>
                    {/* <div className="profile-userbuttons">
                        <Link to={`/students/edit/${studentInfo._id}`} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">
                            {messageResources.OPS_TITLES.STU_EDIT}
                        </Link>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default TaxiCard
import React, {useEffect} from 'react'
import { useFormContext, Controller } from "react-hook-form"
import { messageResources } from '../../../assets/util/messageResources'
import Select from 'react-select'
import ContactInfo from '../contactInfo/ContactInfo'
import { kinshipOptions } from '../../../assets/util/selectOptions'

const NokForm = ({personInfo}) => {

    const { register, formState: { errors }, control, reset } = useFormContext();

    // console.log(personInfo)

    // useEffect(() => {
	// 	console.log("<><><><><><> NokForm NokForm NokForm NokForm<><><><><><>")
    //     console.log(personInfo)
	// 	reset(personInfo)

	// },[personInfo]);

    return (
        <>
            <div className="card card-box">
                <div className="card-body " id="bar-parent">
                    <div className="row">
                        <div className="col-md-2">
                            <div className="form-group">
                                <label>{messageResources.COMMON.NAME} 
                                    {errors?.nokName && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                </label>
                                <input 	type="text" 
                                        className="form-control" 
                                        {...register('nokName')}
                                    // onChange={(e) => formatPhoneNumber(e)}
                                        placeholder="Name"/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label >{messageResources.COMMON.SURNAME}
                                    {errors?.nokSurname && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                </label>
                                <input 	type="text" 
                                        className="form-control" 
                                        {...register('nokSurname')}
                                        placeholder="Surname"/>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                <label>{messageResources.NOK.KINSHIP}
                                    {errors?.kinship && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                </label>

                                <Controller
                                    name="kinship"
                                    control={control}
                                    render={({ field }) => (
                                        <Select options={kinshipOptions} 
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label for="simpleFormEmail">{messageResources.COMMON.EMAIL}
                                    {errors.nokEmail && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED_EMAIL} </span>}
                                </label>
                                <input 
                                    type="email" 
                                    className="form-control" 
                                    {...register('nokEmail')}
                                    placeholder={messageResources.COMMON.EMAIL}/>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                <label for="simpleFormEmail">{messageResources.CONTACT_INFO.NUMBER}
                                    {errors.nokContact_number && <span className="errorSpan">  {errors.nokContact_number.message} </span>}
                                </label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    {...register('nokContact_number')}
                                    placeholder={messageResources.CONTACT_INFO.NUMBER}/>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
            <ContactInfo pathField="nokAddress" contactData={personInfo}/>
        </>
    )
}

export default NokForm
import React from 'react';
import App from './App';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import {Provider} from "react-redux";
import { CookiesProvider } from 'react-cookie';
// import userReduder from "./redux/user/userSlice"
// import authReducer from "./redux/auth/authSlice"
// import studentReducer from "./redux/student/studentSlice"
// import notificationsSlice from "./redux/notifications/notificationsSlice"
import ErrorBoundary from "./components/errorBoundary/ErrorBoundary"
import { configureStore, createStore } from "@reduxjs/toolkit"
import { store } from "./redux/store"
import { ApiProvider } from "@reduxjs/toolkit/query/react"
import { apiSlice } from "./redux/api/apiSlice"
import { extendedStudentSlice } from "./redux/student/studentSlice"
// this is the local storeage in the window browser
//import storage from "redux-persist/lib/storage"
//import persistReducer from 'redux-persist/es/persistReducer';


// if we want to dispatch something right when the app loads
// with a simple reducer
//store.dispatch(fetUser());
// OR using RTK queries
// store.dispatch(extendedStudentSlice.endpoints.getStudents.initiate())

ReactDOM.render(
  
  <CookiesProvider>
    <ApiProvider api={apiSlice}>
      <Provider store={store}>
        <BrowserRouter>
          <ErrorBoundary >
            <App />
          </ErrorBoundary>
        </BrowserRouter>
      </Provider>
    </ApiProvider>    
  </CookiesProvider>
  
  
  ,
  document.getElementById('root')
);
import axios from 'axios'


const addLocation = async (objectEdit) => {

    //console.log("LOCATION addLocation LOCATION")

    var URL = 'https://api.ipgeolocation.io/ipgeo?apiKey=2d1c5e5f693d4aee925b99615a8dd366';

    //console.log(objectEdit)

    if(objectEdit.ip && objectEdit.ip != "Not found") {
        URL = URL +"&ip="+objectEdit.ip
    }

    //console.log(URL)

    const apiResponseIo = await axios.get(URL);
    //console.log("|--------> LOCATION abstractapi <----------|")

    objectEdit.location = `${apiResponseIo?.data?.city}, ${apiResponseIo?.data?.zipcode}, ${apiResponseIo?.data?.country_name}`
    objectEdit.latitude = apiResponseIo?.data?.latitude
    objectEdit.longitude = apiResponseIo?.data?.longitude
    objectEdit.ipAddress = objectEdit.ip ? objectEdit.ip : apiResponseIo?.data?.ip

    return objectEdit

    // apiResponseIo.then(geo => {

    //     console.log(geo.data)
    //     objectEdit.location = `${geo?.data?.city}, ${geo?.data?.zipcode}, ${geo?.data?.country_name}`
    //     objectEdit.latitude = geo?.data?.latitude
    //     objectEdit.longitude = geo?.data?.longitude
    //     objectEdit.ipAddress = geo?.data?.ip

    //     return objectEdit
    // })


}

export default addLocation
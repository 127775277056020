import List from '../../components/list/List'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { Link } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { messageResources } from '../../assets/util/messageResources'
import PacmanLoader from 'react-spinners/PacmanLoader'
import InfoModal from '../../components/modal/InfoModal'
import ClipboardCopy from '../../components/copyText/ClipboardCopy'
import { useGetStudentsArchivedQuery } from '../../redux/student/studentSlice'

const StudentsArchive = () => {

    //console.log("|-----> StudentsList COMPONENT StudentsList <------|")

    const { roles } = useAuth()

    const columnsForAllStudents = [
        
        { title: "Name",  field: "name", defaultSort: "asc", render:rowData=><Link to={`/students/${rowData._id}`}> {rowData.name} </Link> }, 
        { title: "Surname",  field: "surname", defaultSort: "asc", render:rowData=><ClipboardCopy copyText={rowData.surname} /> }, 
        { title: "Email",  field: "email", render:rowData=><ClipboardCopy copyText={rowData.email} />  },
        { title: "Contact Number",  field: "contact_number" , sorting:false, emptyValue:()=><em>N/A</em>, render:rowData=><ClipboardCopy copyText={rowData.contact_number} />},
        { title: "Date of Birth", field: "date_birth", type: 'date', dateSetting: { format: 'dd/MM/yyyy'}},
        { title: "Age",  field: "age" },
        { title: "Ethnicity",  field: "ethnicity.value", render:rowData=><ClipboardCopy copyText={rowData.ethnicity.value} />  },
        { title: "NIN",  field: "nin", render:rowData=><ClipboardCopy copyText={rowData.nin} />   },
        { title: "Passport Number",  field: "passport_number" , sorting:false, emptyValue:()=><em>N/A</em>, render:rowData=><ClipboardCopy copyText={rowData.passport_number} />},
        { title: "Passport Expiry", field: "passport_expiry", type: 'date', dateSetting: { format: 'dd/MM/yyyy'} },
        { title: "Borough",  field: "borough.value", render:rowData=><ClipboardCopy copyText={rowData.borough.value} />   },
        { title: "Actions",  render:rowData=>  
                                            <>   
                                            {(roles.includes(2) || roles.includes(5)) &&  
                                                <InfoModal 
                                                    buttonText={messageResources.BUTTON.ACTI_STU}
                                                    stuId={rowData._id}
                                                    toDeleteId={rowData.idDoc}
                                                    methodType="activeStu"
                                                    header={messageResources.MODAL.ACTI_STU_HEADER}  
                                                    text={messageResources.MODAL.ACTI_STU_TEXT}
                                                    toastText={messageResources.TOASTY.STU_ACTIVATED}
                                                />
                                            }
                                            </>
                                        }, 
    ];

 
    const { 
        data: archived,
        isLoading,
        isSuccess 
    } = useGetStudentsArchivedQuery()

    let content;
    if(isLoading){
        content =   <div style={{position: 'fixed', top: '50%', left: '50%'}}>
                        <PacmanLoader  color="#84bdb1" size={70}/>
                    </div>
    }else if(isSuccess){
        const {ids, entities} = archived


        const listContent = ids?.length
            ? ids.map(stuId => entities[stuId] )
            : null


        content = <>
                    <Breadcrumb pageTitle={`${messageResources.OPS_TITLES.STU_ARCH} (${listContent?.length} records)`}/>
                    <div className="row">
                        <div className="col-md-12">
                            <List   titleForTable="" 
                                    dataForTable={JSON.parse(JSON.stringify(listContent))} 
                                    columnsForTable={columnsForAllStudents}
                                    search={false}
                                    colButton={true}
                                    filter={true}
                                    pageSize={listContent?.length}/>
                        </div>
                    </div>
                </>
    }

    return ( 
            content
    )
}

export default StudentsArchive

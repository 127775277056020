import React from 'react'
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import noProfile from '../../assets/img/noProfile/noProfile.jpg'
import { selectStudentById } from '../../redux/student/studentSlice';
import ModalImage from "react-modal-image"

const Card = ({slots, entityId}) => {

    const profile = useSelector((state) => selectStudentById(state, entityId))

    return (
        <div className={slots} >
            <div className="card card-box" style={{borderTop: '3px solid #6f824f', borderRadius: '10px'}}>
                <div className="card-body no-padding ">
                    <div className="doctor-profile" >
                                    <ModalImage
                                            small={profile?.picture_path 
                                                ? profile?.picture_path 
                                                : noProfile}
                                            large={profile?.picture_path 
                                                ? profile?.picture_path 
                                                : noProfile}
                                            alt={`${profile?.name} ${profile?.surname}`}
                                            hideDownload={true}
                                            hideZoom={true}
                                            className="doctor-pic"
                                        />
                            {/* <img src={profile.picture_path 
                                        ? profile.picture_path 
                                        : noProfile } className="doctor-pic" alt=""/> */}
                        <div className="profile-usertitle">
                            <div className="doctor-name">{profile.name} {profile.surname}</div>
                            <div className="name-center"> {profile.borough.value} </div>
                        </div>
                        <div>
                            <p><i className="fa fa-envelope"></i> {profile.email}</p>
                        </div>
                        <div>
                            <p><i className="fa fa-phone"></i> {profile.contact_number}</p>
                        </div>
                        <div className="profile-userbuttons">
                            <Link to={`/students/${profile._id}`} onC className="btn btn-circle btn-primary btn-sm">
                                Read More
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card

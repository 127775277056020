import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import { useLocation, Outlet } from 'react-router-dom'


const Layout = () => {


  const location = useLocation();
  //console.log(location)

  return (

      location.pathname == "/" || location.pathname == "/login" || location.pathname == "/register" || location.pathname == "/forgot" || location.pathname.startsWith("/reset/")
        ? <Outlet />
        :
      <div className="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md page-full-width header-white white-sidebar-color logo-indigo">
        <div className="page-wrapper">
            <Navbar />
            <div className="page-container">
              <div className="page-content-wrapper">
                <div className="page-content">

                    {/* the outlet component represents all the children of layout component
                    anything nested inside the layout component is represented by the Outlet */}
                  
                    <Outlet />
                </div>
              </div>
            </div>
            <Footer />
        </div>
      </div>
  )
}

export default Layout
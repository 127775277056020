import React, {useEffect, useRef, useState} from 'react'
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useParams} from "react-router-dom";
import { messageResources } from '../../assets/util/messageResources'
import { forgotSchema } from '../../assets/util/yupValidations'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'; 
import { setCredentials } from '../../redux/auth/authSlice';
import { useForgotMutation } from '../../redux/auth/authApiSlice';
import lettersOnly from '../../assets/img/logo/lettersOnlyWhite.png'
import treeOnly from '../../assets/img/logo/treeOnlyWhite3.png'
import ReCAPTCHA from 'react-google-recaptcha'
import "../../assets/css/pages/extra_pages.css"



const Forgot = () => {

    //console.log("|--------> LOGIN LOGIN <----------|")
    const dispatch = useDispatch();
    const recaptRef = useRef();
    const passRef = useRef()

    let navigate = useNavigate();

    const [forgot] = useForgotMutation()

    const { register, handleSubmit, watch, reset, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(forgotSchema),
    });


    const onSubmit = async (formData) => { 

        try {

    
        //console.log(formData);
        console.log("|-------> NO ERRORS <--------|")

        const recaptToken = await recaptRef.current.executeAsync();
        // it allows to reexecute the recaptcha check 
        recaptRef.current.reset();

        //console.log(recaptToken)
        formData.recaptToken = recaptToken
        formData.email = formData.email.toLowerCase();

        const { message } = await forgot(formData).unwrap()
        
        // dispatch(setCredentials({accessToken}))

        if(message && message == "noUserWithEmail"){

            toast.error(messageResources.TOASTY.NOT_REGISTERED,{
                autoClose: 10002,
            })

        }else{

            toast.success(messageResources.TOASTY.RESET_PW,{
                autoClose: 10002,
            })
            
        }

        navigate('/login')


    }catch(err){
        console.log(err)
    }

    
    };

    toast.configure({
        pauseOnFocusLoss: false
    });



    return (
        
        <>
            <div className="limiter">
                <div className="container-login100 page-background" >
                {/* <pre>{JSON.stringify(errors, undefined, 2)}</pre> */}
                    <div className="wrap-login100">
                        <form className="login100-form validate-form" onSubmit={handleSubmit(onSubmit)}>
                            <div className='d-flex justify-content-center'>
                                    <img src={treeOnly} />
                            </div> 
                            <div className='d-flex justify-content-center'>
                                    <img src={lettersOnly} />
                            </div>
                            <span className="login100-form-title p-b-34 p-t-27">
                                {messageResources.LOGIN.RESET}
                            </span>
                            <div className="text-center txt1">
                                
                                    {messageResources.LOGIN.TYPE}
                                
                            </div>
                            <div className="row">
                                {errors.email && <span className="errorSpanOut"> {errors.email.message} </span>}
                                <div className="wrap-input100 validate-input">
                                    <input className="input100" 
                                        type="text"
                                       //value="eiranovahrt@gmail.com"
                                        // autoComplete='off'
                                        placeholder={messageResources.COMMON.EMAIL} 
                                        {...register('email')}
                                    />
                                    <span className="focus-input100" data-placeholder="&#xf207;"></span>
                                </div>

                                <ReCAPTCHA  sitekey='6LfPnGwgAAAAAF3e8lQCTEzMRvJ7Cl6yACMwNZWU'
                                            size='invisible'
                                            ref={recaptRef} 
                                />

                                <div className="container-login100-form-btn">
                            
                                    <button class="login100-form-btn " onClick={handleSubmit(onSubmit)}>
                                            {messageResources.COMMON.SUBMIT}
                                    </button>

                                </div>
                            </div>    
                            <div className="text-center p-t-30">
                                <a href="/login" className="txt1">
                                    {messageResources.REGISTRATION.ALREADY}
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Forgot

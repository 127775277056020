import React, {useEffect,useState} from 'react'
import List from '../../components/list/List'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectAllStudents } from '../../redux/student/studentSlice'
import { messageResources } from '../../assets/util/messageResources'
import noProfile from '../../assets/img/noProfile/noProfile.jpg'


const DocumentsList = () => {


    const columnsForAllDocs = [
        
        { title: "Student",  field: "student",  render:rowData=><Link to={`/students/${rowData.stId}`}> {rowData.student} </Link> }, 
        { title: "Type",  field: "type" }, 
        { title: "Google Link",  render:rowData=> <a target="_blank" href={rowData.link}> CLICK TO OPEN </a> }, 
        { title: "Upload Date", field: "uploaded", defaultSort: "desc", type: 'date', dateSetting: { format: 'dd MMMM yyyy HH:mm'} },
        { title: "Description",  field: "description" }
    ];

    //console.log("|-----> DocumentsList COMPONENT DocumentsList <------|")
    //const [allDocs,setAllDocs] = useState([]);

    const allOfThem = useSelector(selectAllStudents)
    let docsForList = []; 

    if(allOfThem.length > 0){
        allOfThem.map(student => {
            if(student.gooDocuments?.length > 0){
                            
                student.gooDocuments.map((document) => {
                    
                    let singleDoc = {}
                    singleDoc.type = document.type?.value
                    singleDoc.link = document.url_link
                    singleDoc.description = document.description
                    singleDoc.uploaded = document.createdAt
                    singleDoc.student = `${student.name}  ${student.surname}`
                    singleDoc.stId = student._id
                    
                    docsForList.push(singleDoc)
                })
            }
        })
    }

    useEffect(() => {       
        window.scrollTo(0, 0);
    })

    // useEffect(() => {       
    //     window.scrollTo(0, 0);
    //     //dispatch(messageStart());
    //     console.log("<><><><><><> ALL STUDENTS DOCUMENTS <><><><><><>")
        
    //     const getDocs = async () => {
    //         try{
    //             const res = await axios.get("/studentsApi/docs");
    //             //console.log(res.data);

    //             let docsForList = [];
    //             console.log(res.data.length)
    //             if(res.data.length > 0) {
                    
    //                 res.data.map((student) => {
                        
    //                     if(student.gooDocuments?.length > 0){
                            
    //                         student.gooDocuments.map((document) => {
                                
    //                             let singleDoc = {}
    //                             singleDoc.type = document.type?.value
    //                             singleDoc.link = document.url_link
    //                             singleDoc.description = document.description
    //                             singleDoc.uploaded = document.createdAt
    //                             singleDoc.student = `${student.name}  ${student.surname}`
    //                             singleDoc.stId = student._id
                                
    //                             docsForList.push(singleDoc)
    //                         })
    //                     }
    //                 })
    //             }
                
    //             setAllDocs(docsForList);
                
    //         }catch(err){
    //             console.log(err.message);
                
    //         }
    //     } 
    //     getDocs();
    
    // }, []);


    //throw new Error("FORCED ERROR");

    return (
    
    <>
        <Breadcrumb pageTitle={`${messageResources.OPS_TITLES.ALL_DOCS} (${docsForList.length} records)`}/>
        <div className="row">
            <div className="col-md-12">
                <List   titleForTable="" 
                        dataForTable={JSON.parse(JSON.stringify(docsForList))} 
                        columnsForTable={columnsForAllDocs}
                        search={false}
                        filter={true}
                        pageSize={20}/>
            </div>
        </div>
    </>

    )
}

export default DocumentsList

import {useEffect} from 'react'
import { messageResources } from '../../assets/util/messageResources';
import ClipboardCopy from '../../components/copyText/ClipboardCopy'
import List from '../../components/list/List'
import useAuth from '../../hooks/useAuth';
import Moment from 'moment'
import EditSubjectModal from '../../components/modal/EditSubjectModal';

const Subjects = ({subjects, progId, tableTitle, stuId, componentFrom}) => {

    const { roles } = useAuth()

    var hideButton = true;

    if(componentFrom == "FullProgramme" && (roles.includes(2) || roles.includes(5))){
        hideButton = false
    }
    
    const columnsForSubjects = [
        
        { title: messageResources.SUBJECT.REF,  width: "10%", field: "value", render:rowData=><ClipboardCopy copyText={rowData.value.lastIndexOf('_') != -1 ? rowData.value.substring(0,rowData.value.lastIndexOf('_')) : rowData.value} />  },
        { title: messageResources.COMMON.NAME, width: "30%", align:"left", field: "label", render:rowData=><ClipboardCopy copyText={rowData.label} />  },
        { title: messageResources.PROGRAMME.START,  width: "10%", field: "startDate", type: 'date', render:rowData=><ClipboardCopy copyText={Moment(rowData.startDate).format('DD MMMM YYYY')} />},
        { title: messageResources.PROGRAMME.END,  width: "10%", field: "endDate", type: 'date', render:rowData=><ClipboardCopy copyText={Moment(rowData.endDate).format('DD MMMM YYYY')} />},
        { title: messageResources.SUBJECT.ACTUAL, width: "10%", field: "actualDate", emptyValue:()=><em>N/A</em>, type: 'date', render:rowData=><ClipboardCopy copyText={Moment(rowData.actualDate).format('DD MMMM YYYY')} />},
        { title: messageResources.SUBJECT.HOURS, width: "5%", field: "hours", emptyValue:()=><em>N/A</em> },
        { title: messageResources.SUBJECT.COMPLETED, width: "10%", field: "completed", emptyValue:()=><em>Not Completed</em>, render:rowData=> rowData.completed ? "Completed" : "Not Completed" },
        { title: messageResources.SUBJECT.ACHIEVEMENT,  width: "10%", field: "achievement.label" , sorting:false, emptyValue:()=><em>Not marked</em>, render:rowData=><ClipboardCopy copyText={rowData.achievement.label} />},
        { title: "Actions",  hidden: hideButton, width: "5%", render:rowData=>  
                                        <>
                                            {/* {showButton && roles.includes(2) &&  */}
                                                <EditSubjectModal  
                                                            buttonText={messageResources.BUTTON.MARK}
                                                            subjectToEdit={rowData}
                                                            progId={progId}
                                                            stuId={stuId}
                                                            amend={true}
                                                            header={messageResources.MODAL.MARK_SUBJECT}
                                                />
                                            {/* }  */}
                                        </>   
                                    }, 
                                            
        // { title: "Passport Expiry",  field: "passport_expiry", type:"currency", currencySetting:{currencyCode:"USD",minimumFractionDigits:0}, cellStyle:{color:"blue"}},
    ];

    let content;
    if(subjects){
        content = <List    titleForTable={tableTitle} 
                        dataForTable={JSON.parse(JSON.stringify(subjects))} 
                        columnsForTable={columnsForSubjects}
                        search={false}
                        colButton={false}
                        filter={false}
                        pageSize={subjects?.length}/>
    }

    useEffect(() => {       
        window.scrollTo(0, 0);
    })



    return (
        <div>
            {content}
        </div>
    )
}

export default Subjects
import React from 'react'
import { messageResources } from '../../assets/util/messageResources'
import ClipboardCopy from '../../components/copyText/ClipboardCopy'
import Moment from 'moment'

const ProgrammeCardThird = ({assessment}) => {

    //console.log("|-----------> ProgrammeCardThird COMPONENT ProgrammeCardThird <--------|")

    return (
        <div className="col-lg-6 col-md-12">
            <div className="card card-topline-aqua">
                <div className="card-body no-padding height-9">
                    {/* <div className="profile-usertitle">
                        <div className="profile-usertitle-name">
                            
                            {programme.programme.label}  / {programme.speciality.label} 

                        </div>
                    </div> */}
                    <div>
                        <ul className="list-group list-group-unbordered">
                            <li className="list-group-item">
                                <b>{messageResources.PROGRAMME.MATH_EXEM}</b> 
                                <a className="pull-right"><ClipboardCopy copyText={assessment?.mathsExem?.label}/></a>
                            </li>
                            <li className="list-group-item">
                                <b>{messageResources.PROGRAMME.ENG_EXEM}</b> 
                                <a className="pull-right"><ClipboardCopy copyText={assessment?.engExem?.label}/></a>
                            </li>
                            <li className="list-group-item">
                                <b>{messageResources.PROGRAMME.CGSE_ENG}</b> 
                                <a className="pull-right">{assessment?.gcseEng?.label ? <ClipboardCopy copyText={assessment?.gcseEng?.label}/> : messageResources.COMMON.NONE}</a>
                            </li>
                            <li className="list-group-item">
                                <b>{messageResources.PROGRAMME.CGSE_MATH}</b> 
                                <a className="pull-right">{assessment?.gcseMath?.label ? <ClipboardCopy copyText={assessment?.gcseMath?.label}/> : messageResources.COMMON.NONE}</a>
                            </li>
                            <li className="list-group-item">
                                <b>{messageResources.ASSESSMENT.LINK}</b>    
                                {
                                    assessment?.ass_link 
                                    ? 
                                    <a className="pull-right" target="_blank" href={assessment?.ass_link}> {messageResources.COMMON.OPEN} </a>
                                    :
                                    
                                    <span className="pull-right"> {messageResources.COMMON.NO_LINK} </span>
                                }
                            
                            </li>
                            <li className="list-group-item">
                                <b>{messageResources.PROGRAMME.PRIOR}</b> 
                                <a className="pull-right"><ClipboardCopy copyText={assessment?.priorAtt?.label}/></a>
                            </li>
                        
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProgrammeCardThird
import React from 'react'
import { messageResources } from '../../assets/util/messageResources'
import ClipboardCopy from '../../components/copyText/ClipboardCopy'
import Moment from 'moment'

const ProgrammeCard = ({programme}) => {

    //console.log("|-----------> ProgrammeCard COMPONENT ProgrammeCard <--------|")

    return (
        <div className="col-lg-4">
            <div className="card card-topline-aqua">
                <div className="card-body no-padding height-9">
                    {/* <div className="profile-usertitle">
                        <div className="profile-usertitle-name">
                            
                            {programme.programme.label}  / {programme.speciality.label} 

                        </div>
                    </div> */}
                    <div>
                        <ul className="list-group list-group-unbordered">
                            {/* <li className="list-group-item">
                                <b>{messageResources.PROGRAMME.PROG}</b> 
                                <a className="pull-right"><ClipboardCopy copyText={programme?.programme?.label}/></a>
                            </li> */}
                        
                            <li className="list-group-item">
                                <b>{messageResources.PROGRAMME.START}</b> 
                                <a className="pull-right"><ClipboardCopy copyText={Moment(programme?.startDate).format('DD MMMM YYYY')} bClass="pull-right" element="a"/></a>
                            </li>
                            <li className="list-group-item">
                            <b>{messageResources.PROGRAMME.END}</b> 
                                <a className="pull-right"><ClipboardCopy copyText={Moment(programme?.endDate).format('DD MMMM YYYY')} bClass="pull-right" element="a"/></a>
                            </li>
                            {programme?.aim?.label && 
                                <li className="list-group-item">
                                    <b>{messageResources.PROGRAMME.AIM}</b> 
                                    <a className="pull-right"><ClipboardCopy copyText={programme?.aim?.label}/></a>
                                </li>
                            }  
                            {programme?.timetable && 
                                <li className="list-group-item">
                                    <b>{messageResources.PROGRAMME.TIME}</b> 
                                    <a className="pull-right"><a target="_blank" href={programme?.timetable}> {messageResources.COMMON.OPEN} </a></a>
                                </li>
                            }    
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProgrammeCard
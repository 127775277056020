import React, {useState,useEffect} from "react";
import {Modal, Button} from 'react-bootstrap';
import useAuth from "../../hooks/useAuth";
import { messageResources } from "../../assets/util/messageResources";
import numberDaysDifference from "../../assets/util/numberDaysDifference"
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import addLocation from "../../assets/util/addLocation"
import { useForm, Controller, FormProvider } from "react-hook-form"
import { nokSchemaModal } from '../../assets/util/yupValidations'
import Select from 'react-select'
import { yupResolver } from "@hookform/resolvers/yup"
import { kinshipOptions } from '../../assets/util/selectOptions'
import { selectAllNotifications, useUpdateNotificationMutation } from "../../redux/notifications/notificationsApiCalls";
import { selectStudentById, useAddNokFromStudentMutation,
            useEditNokFormStudentMutation } from "../../redux/student/studentSlice";     


export default function EditNokModal({buttonText, header, stuId, nokToEdit, amend}) {

    //const {userInfo} = useSelector((state) => state.user)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const studentFormState = useSelector((state) => selectStudentById(state, stuId))

    useEffect(() => {
		reset(nokToEdit)
	}, [nokToEdit]);

    const methods = useForm({
        //defaultValues: studentData,
        resolver: yupResolver(nokSchemaModal),
    });

    const { id } = useAuth()
    const [addNokFromStudent] = useAddNokFromStudentMutation()
    const [editNokFormStudent] = useEditNokFormStudentMutation()
    const [updateNotification] = useUpdateNotificationMutation()
    const notis = useSelector(selectAllNotifications)


	const {
        register,
        handleSubmit,
        reset,
		control,
        formState: { errors },
    } = methods;


    //console.log(errors)

    const onSubmit = async (formData) => {

        //console.log(formData);
        console.log("NO ERRORS")
        
		try{
			
			//console.log("<><><><><><> NOKEDIT onSubmit NOKEDIT  <><><><><><>")
            formData.stuId = stuId
            formData.stuName = studentFormState?.name+" "+studentFormState?.surname
            await addLocation(formData) 

            if(amend){
                await editNokFormStudent(formData).unwrap()
                toast.success(messageResources.TOASTY.NOK_EDIT)
            }else{
            
                
                await addNokFromStudent(formData).unwrap()

                notis?.map(una => {
            
                    if(una.studentId._id == stuId && una.type == 5 && !una.isProcessed){
                        //console.log(" DOS DOS ")
                        const today = new Date()

                        let notiBody = {}
                        notiBody.idFor = una.id
                        notiBody.isProcessed = true
                        notiBody.processedBy = id
                        notiBody.dateProcessed = today
                        notiBody.daysElapsed = numberDaysDifference(una.createdAt,today)

                        updateNotification(notiBody).unwrap()

                        return
                    }
                })

                toast.success(messageResources.TOASTY.NOK_ADDED)
            }

            setShow(false)

		}catch(err){
			console.log(err);
		}
	}



    return (
        <>

        {amend 
            ?
            <button style={{marginRight: '10px'}} className="btn btn-outline-success btn-circle" onClick={handleShow}>
                {buttonText}
            </button>
            :
            <div className="profile-userbuttons">
                <button style={{marginRight: '10px'}} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" onClick={handleShow}>
                    {buttonText}
                </button>
            </div>
        }

        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
            <Modal.Title>
                <div className="row">
                    <div className="col-md-1"><i style={{fontSize: '32px'}} className="material-icons">error_outline</i></div>
                    <div className="col-md-10">{header}</div>
                </div>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body><div className="row">
                                
                                <FormProvider {...methods}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                    
                                            <div className="card card-box">
                                                <div className="card-body " id="bar-parent">
                                                {/* <div className="row"> */}
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{messageResources.COMMON.NAME} 
                                                                    {errors?.name && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                                                </label>
                                                                <input 	type="text" 
                                                                        className="form-control" 
                                                                        {...register('name')}
                                                                    // onChange={(e) => formatPhoneNumber(e)}
                                                                        placeholder="Name"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label >{messageResources.COMMON.SURNAME}
                                                                    {errors?.surname && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                                                </label>
                                                                <input 	type="text" 
                                                                        className="form-control" 
                                                                        {...register('surname')}
                                                                        placeholder="Surname"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{messageResources.NOK.KINSHIP}
                                                                    {errors?.kinship && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                                                </label>

                                                                <Controller
                                                                    name="kinship"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <Select options={kinshipOptions} 
                                                                            {...field}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label for="simpleFormEmail">{messageResources.CONTACT_INFO.NUMBER}
                                                                    {errors?.contact_number && <span className="errorSpan"> {errors.contact_number.message} </span>}
                                                                </label>
                                                                <input 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    {...register('contact_number')}
                                                                    placeholder={messageResources.CONTACT_INFO.NUMBER}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label for="simpleFormEmail">{messageResources.COMMON.EMAIL}
                                                                {errors.email && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED_EMAIL} </span>}
                                                            </label>
                                                            <input 
                                                                type="email" 
                                                                className="form-control" 
                                                                {...register('email')}
                                                                placeholder={messageResources.COMMON.EMAIL}/>
                                                        </div>
                                                    </div>
                                                    

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            
                                                            <label >{messageResources.CONTACT_INFO.ADDRESS_1}
                                                                {errors.address?.address1 && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}   
                                                            </label>
                                                            
                                                            <input 
                                                                type="text" 
                                                                className="form-control"
                                                                {...register(`address.address1`)}
                                                                placeholder={messageResources.CONTACT_INFO.ADDRESS_1}
                                                            /> 
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label for="simpleFormEmail">{messageResources.CONTACT_INFO.ADDRESS_2}</label>
                                                                <input 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    {...register(`address.address2`)}
                                                                    placeholder={messageResources.CONTACT_INFO.ADDRESS_2}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                    
                                                                <label >{messageResources.CONTACT_INFO.POSTCODE}
                                                                    {errors.address?.postcode && <span className="errorSpan">  {errors.address.postcode.message} </span>}   
                                                                </label>
                                                                <input 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    {...register(`address.postcode`)}
                                                                    placeholder={messageResources.CONTACT_INFO.POSTCODE}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>{messageResources.COMMON.NOTES} 
                                                                {/* {errors?.contact_number && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>} */}
                                                            </label>
                                                            <textarea 
                                                                rows="4"
                                                                className="form-control" 
                                                                {...register('notes')}
                                                                placeholder={messageResources.COMMON.NOTES}/>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        <div className="fake-modal-footer">
                                            <Button variant="secondary" onClick={handleClose}>
                                                {messageResources.BUTTON.CANCEL}
                                            </Button>
                                            <button className="btn btn-modal" onClick={() => methods.handleSubmit(onSubmit)}>
                                                {amend 
                                                    ?
                                                    messageResources.BUTTON.AMEND
                                                    :
                                                    messageResources.BUTTON.ADD
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </FormProvider>

                            </div></Modal.Body>
            {/* <Modal.Footer>
            
            </Modal.Footer> */}
        </Modal>
        </>
    );
}
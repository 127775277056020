import React, {useState,useEffect} from "react";
import {Modal, Button} from 'react-bootstrap';
import { messageResources } from "../../assets/util/messageResources";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import addLocation from "../../assets/util/addLocation"
import { useForm, FormProvider, Controller } from "react-hook-form"
import { newTaxiSchema } from '../../assets/util/yupValidations'
import { yupResolver } from "@hookform/resolvers/yup"
import formatData from "../../assets/util/formatData"
import useAuth from "../../hooks/useAuth";
import Toggle from 'react-toggle'
import numberDaysDifference from "../../assets/util/numberDaysDifference"
import { selectStudentById, useAddTaxiFromStudentMutation, useEditTaxiFormStudentMutation } from "../../redux/student/studentSlice";
import { useUpdateNotificationMutation, selectAllNotifications } from "../../redux/notifications/notificationsApiCalls";

export default function EditTaxiModal({amend, buttonText, header, stuId, taxiToEdit}) {

    //console.log("------> EditTaxiModal EditTaxiModal <----|")

    const { id } = useAuth()

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const studentFormState = useSelector((state) => selectStudentById(state, stuId))

    useEffect(() => {
        //console.log("------> EditTaxiModal UseEffect <----|")
		reset(taxiToEdit)
	}, [taxiToEdit]);

    const methods = useForm({
        resolver: yupResolver(newTaxiSchema),
    });

	const {
        register,
        handleSubmit,
        reset,
        defaultValues,
		control,
        formState: { errors },
    } = methods;

    //console.log(errors)

    const notis = useSelector(selectAllNotifications)
    const [addTaxiFromStudent] = useAddTaxiFromStudentMutation()
    const [updateNotification] = useUpdateNotificationMutation()
    const [editTaxiFormStudent] = useEditTaxiFormStudentMutation()



    const onSubmit = async (formData) => {

        //console.log(formData);
        console.log("NO ERRORS")        

		try{
        
            
            formData.stuName = studentFormState?.name+" "+studentFormState?.surname
            await addLocation(formData) 

            formData.contact_number = formatData("phone",formData.contact_number)
            formData.stuId = stuId

            if(amend){
                await editTaxiFormStudent(formData).unwrap()
                toast.success(messageResources.TOASTY.TAXI_EDIT)
            }else{

                await addTaxiFromStudent(formData).unwrap()

                notis?.map(una => {
                    // console.log(" UNA JODERRRRRRRR ")
                    // console.log(una.studentId._id)
                    // console.log(stuId)
                    // console.log(una.studentId._id == stuId)
            
                    if(una.studentId._id == stuId && una.type == 3 && !una.isProcessed){
                        //console.log(" DOS DOS ")
                        const today = new Date()

                        let notiBody = {}
                        notiBody.idFor = una.id
                        notiBody.isProcessed = true
                        notiBody.processedBy = id
                        notiBody.dateProcessed = today
                        notiBody.daysElapsed = numberDaysDifference(una.createdAt,today)

                        updateNotification(notiBody).unwrap()

                        return
                    }
                })

                toast.success(messageResources.TOASTY.TAXI_ADDED)
            }
    
            setShow(false)

		}catch(err){
			console.log(err);
		}
	}



    return (
        <>
        {amend 
            ?
            <button style={{marginRight: '10px'}} className="btn btn-outline-success btn-circle" onClick={handleShow}>
                {buttonText}
            </button>
            :
            <button style={{marginRight: '10px'}} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" onClick={handleShow}>
                {buttonText}
            </button>

        }

        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
            <Modal.Title>
                <div className="row">
                <div className="col-md-1"><i style={{fontSize: '32px'}} className="material-icons">error_outline</i></div>
                    <div className="col-md-10">{header}</div>
                </div>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                                
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                        
                                <div className="card card-box">
                                    <div className="card-body " id="bar-parent">
                                    {/* <div className="row"> */}
                                    
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>{messageResources.TAXI.COMPANY} 
                                                    {errors?.company && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                                </label>
                                                <input 	type="text" 
                                                    className="form-control" 
                                                    {...register('company')}
                                                    placeholder="Company's Name"/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>{messageResources.COMMON.EMAIL} 
                                                    {errors?.email && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                                </label>
                                                <input 	type="text" 
                                                    className="form-control" 
                                                    {...register('email')}
                                                    placeholder={messageResources.COMMON.EMAIL} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{messageResources.CONTACT_INFO.NUMBER} 
                                                        {/* {errors?.contact_number && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>} */}
                                                        {errors?.contact_number && <span className="errorSpan"> {errors.contact_number.message} </span>}
                                                    </label>
                                                    <input 	type="text" 
                                                        className="form-control" 
                                                        {...register('contact_number')}
                                                        placeholder={messageResources.CONTACT_INFO.NUMBER}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{messageResources.STUDENT.INDEPENDENT}</label>
                                                        <Controller
                                                            control={control}
                                                            name='isIndependent'
                                                            render={({ field }) => (
                                                                <Toggle
                                                                    defaultChecked={taxiToEdit?.isIndependent}
                                                                    aria-label='No label tag'
                                                                    onChange={(value) => field.onChange(value)}
                                                                    value={taxiToEdit?.isIndependent ? taxiToEdit?.isIndependent : field.value}
                                                                    //checked={studentData.photo ? true : false}
                                                                />
                                                            )}
                                                        />	
                                                
                                                        
                                                </div>
                                            </div>
                                        </div>    
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>{messageResources.MEDICAL.NOTES} 
                                                    {/* {errors?.contact_number && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>} */}
                                                </label>
                                                <textarea 
                                                    rows="4"
                                                    className="form-control" 
                                                    {...register('notes')}
                                                    placeholder={messageResources.MEDICAL.NOTES}/>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-1">
                                            <button type="submit" style={{marginTop:"25px"}} className="btn btn-primary" onClick={methods.handleSubmit(onSubmit)}>{messageResources.BUTTON.ADD}</button>
                                        </div> */}
                                    {/* </div> */}
                                </div>
                            </div>
                            <div className="fake-modal-footer">
                                <Button variant="secondary" onClick={handleClose}>
                                    {messageResources.BUTTON.CANCEL}
                                </Button>
                                <button className="btn btn-modal" onClick={() => methods.handleSubmit(onSubmit)}>
                                    {amend 
                                        ?
                                        messageResources.BUTTON.AMEND
                                        :
                                        messageResources.BUTTON.ADD
                                    }
                                </button>
                            </div>
                        </form>
                    </FormProvider>

                </div>
            </Modal.Body>
            {/* <Modal.Footer>
            
            </Modal.Footer> */}
        </Modal>
        </>
    );
}
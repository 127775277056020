
//import "./assets/plugins/bootstrap/css/bootstrap.min.css"
import "bootstrap/dist/css/bootstrap.min.css"
//import "./assets/plugins/flatpicker/css/flatpickr.min.css"
//import "./assets/plugins/bootstrap-datetimepicker/css/bootstrap-datetimepicker.min.css"
//import "./assets/plugins/bootstrap-colorpicker/css/bootstrap-colorpicker.css"

// icons & fonts
import "./assets/fonts/font-awesome/css/font-awesome.min.css"
import "./assets/fonts/simple-line-icons/simple-line-icons.min.css"
import "./assets/fonts/material-design-icons/material-icon.css"

// Material Design Lite CSS
import "./assets/plugins/material/material.min.css"
import "./assets/css/material_style.css"

// Theme Styles
import "./assets/css/theme/full/theme_style.css"
import "./assets/css/plugins.min.css"
import "./assets/css/theme/full/style.css"
import "./assets/css/responsive.css"
import "./assets/css/theme/full/theme-color.css"
import "./assets/css/pages/formlayout.css"

import "./assets/plugins/iconic/css/material-design-iconic-font.min.css"

// overwrite css
import "./assets/css/overWriteTheme.css"
import "./assets/css/notifications.css"

//emojis
import "./assets/css/twemoji-awesome.css"

//toggle
import "react-toggle/style.css"

//dateTimePicker
import "react-datepicker/dist/react-datepicker.css"

// react-toastify
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./assets/css/toastyCustom.css";

import Login from "./pages/login/Login"
import StudentsList from "./pages/studentsList/StudentsList"
import StudentsCards from "./pages/studentsCards/StudentsCards"
import HomeAdmin from "./pages/homeAdmin/HomeAdmin"
import Register from "./pages/register/Register"
import AddStudent from "./pages/addStudent/AddStudent"
import AddStaff from "./pages/addStaff/AddStaff"
import StaffList from "./pages/staffList/StaffList"
import StaffProfile from "./pages/staffProfile/StaffProfile"
import StudentProfile from "./pages/studentProfile/StudentProfile"
import Ehcp from './components/forms/ehcp/Ehcp'
import NotificationsMain from "./pages/notifications/NotificationsMain"
import NotFound from "./pages/notFound/NotFound"
import Layout from "./components/layout/Layout"
import FullProgramme from "./pages/academic/FullProgramme"
import Unauthorized from "./components/unauthorized/Unauthorized"
import Charts from "./pages/charts/Charts"
import ChartsPerYear from "./pages/charts/ChartsPerYear"
import Achievements from "./pages/academic/Achievements"
import RequireAuth from "./components/requireAuth/RequireAuth"
import EhcpDatesList from "./pages/studentsList/EhcpDatesList"
import Programme from "./pages/programme/Programme"
import Assessment from "./pages/assessment/Assessment"
import UserTracker from "./pages/tracker/UserTracker"
import StudentsData from "./pages/studentsList/StudentsData"
import Forgot from "./pages/forgot/Forgot"
import AcademicList from "./pages/academic/AcademicList"
import AcademicListArchived from "./pages/academic/AcademicListArchived"
import StudentsArchive from "./pages/studentsList/StudentsArchive"
import PreFetch from "./components/preFetch/PreFetch"
import PersistLogin from "./components/persistLogin/PersistLogin"
import AreaChart from "./pages/charts/AreaChart"
import DocumentsList from "./pages/studentDocs/DocumentsList"
import Favicon from "react-favicon";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation
} from "react-router-dom";
import EditStudent from "./pages/addStudent/EditStudent"
import ResetPw from "./pages/forgot/ResetPw"



const App = () => {

  //console.log("<><><><><><> APP APP APP APP <><><><><><>")


  // SHOWS NOTICIATIONS EVERY SET TIME
  // useEffect(() => {

  //   const getNotis = async (idFromLogin) => {
  //     try{

  //         console.log("interval interval interval interval interval")
  //         let closeNoti = 5000
  //         // GET NOTIS WITH NO OWNER AND WITH THIS USER AS OWNER
  //         const res = await axios.get("notifications/random?processed=false");
  //         res.data.map(notification => {
              
  //             closeNoti += 140

  //             notification.priority == 1 
  //                 ?
  //                 toast.error(notification.message,{
  //                     position:"top-right",
  //                     autoClose: closeNoti,
  //                 })
  //                 : notification.priority == 2 
  //                     ? 
  //                     toast.warn(notification.message,{
  //                         position:"top-right",
  //                         autoClose: closeNoti,
  //                     })
  //                     : 
  //                         toast.info(notification.message,{
  //                             position:"top-right",
  //                             autoClose: closeNoti,
  //                         })

  //         })
  //     }catch(err){
  //         console.log(err);
  //     }

  //   } 
  
  //     const interval = setInterval(() => {
  //       if (!document.hidden) {
  //         getNotis();
  //       }
  //     }, 300000);
  //     return () => clearInterval(interval);
    
    

  // },[])


  // const AuthWrapper = () => {
  //   return isExpired(localStorage.getItem('accessToken')
  //     ? <Navigate to="/login" replace />
  //     : <Outlet />;
  // };


  

  return (
    
      <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          <Favicon url="https://res.cloudinary.com/dlkpi3swo/image/upload/v1659900572/favicon_k5rry4.ico"></Favicon>
          
          <Routes>
          
            {/* PUBLIC ROUTES NOT LOGGED IN */}
            
            
            <Route path="/" element={<Layout />}>
            
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot" element={<Forgot />} />
              <Route path="/reset/:id" element={<ResetPw />} />
              <Route path="/verify/:text" element={<Login />} />
              <Route path="/logout"  element={<Login />} />
              <Route path="/unauthorized" element={<Unauthorized />} />

              
              {/* NEW REFACTORED ROUTES WITH INDEXES */}
              <Route element={<PersistLogin />}>

                <Route element={<PreFetch />}>

                  {/* <Route element={<RequireAuth allowedRoles={[1]}/>}> */}

                  <Route path="/dashboard" element={<HomeAdmin />} />

                  <Route path="/documents" element={<DocumentsList />} />

                  <Route path="/charts" element={<Charts />} />

                  <Route path="/yearCharts" element={<ChartsPerYear />} />

                  <Route path="/academic" element={<AcademicList />} />

                  <Route path="/academic/archived" element={<AcademicListArchived />} />

                  <Route path="/academic/subjects" element={<Achievements />} />

                  <Route path="/academic">
                    <Route path=":type" element={<AcademicList />} />
                    <Route path="mark/:studentId" element={<FullProgramme />} />
                    {/* <Route path="completed" element={<TerminatedList />} /> */}
                  </Route>

                  <Route path="/students">
                    
                    <Route index element={<StudentsList />} />
                    <Route path="data" element={<StudentsData />} />
                    <Route path="archive" element={<StudentsArchive />} />
                    <Route path="add" element={<AddStudent />} />
                    <Route path="ehDates" element={<EhcpDatesList />} />
                    
                    <Route path="edit/:studentId" element={<EditStudent />} />
                    <Route path=":studentId" element={<StudentProfile />} >
                      <Route path="addEHCP/:studentId/:action/" element={<Ehcp />} />
                    </Route>
                    <Route path=":studentId" element={<StudentProfile />} >
                      <Route path="addProgramme/:studentId/:action/" element={<Programme />} />
                    </Route>
                    <Route path=":studentId" element={<StudentProfile />} >
                      <Route path="addAssessment/:studentId/:action/" element={<Assessment />} />
                    </Route>
                    <Route path="cards" element={<StudentsCards />} />
                    {/* <Route path="activate" element={<Programme />} /> */}

                  </Route>

                  {/* </Route> */}

                  {/* <Route element={<RequireAuth allowedRoles={[1, 2]}/>}> */}
                
                  <Route path="/staff" >

                    {/* REMAKE THIS ROUTE FROM '/staff/list' TO '/STAFF' */}
                    <Route index element={ <StaffList /> } />
                    <Route path=":staffId" element={ <StaffProfile /> } />
                    <Route path="staff/add" element={ <AddStaff /> } />
                    <Route path="staff/cards" element={ <StaffList /> } />

                  </Route>
                
                  {/* </Route> */}

                  <Route path="/tracker" element={ <UserTracker /> } />
                  
                  <Route path="/notifications" element={ <NotificationsMain /> } />

                </Route>

              </Route>
              
            </Route>

            {/* Catch ALL */}
            <Route path="/*" element={<NotFound />} />

          </Routes>
          
      </>

  );
  
  
};



export default App;
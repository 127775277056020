import { useState } from "react";

const ClipboardCopy = ({copyText, cName, element, bClass}) => {

    const [isCopied, setIsCopied] = useState(false);

    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
        // console.log("copyTextToClipboard copyTextToClipboard copyTextToClipboard")
        // console.log(text)
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    // onClick handler function for the copy button
    const handleCopyClick = () => {
        // console.log("handleCopyClick handleCopyClick handleCopyClick")
        // console.log(copyText)
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(copyText)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <>
            {/* <input type="text" value={copyText} readOnly /> */}
            {/* Bind our handler function to the onClick button property */}
            {/* <button className="pull-right" onClick={handleCopyClick}>
            <span>{isCopied ? 'Copied!' : 'Copy'}</span>
            </button> */}

            <button className={bClass} onClick={handleCopyClick}>
            {/* { isCopied 
                ?   <div className="profile-desc-item pull-right">Copied!</div>
                :   <div className="profile-desc-item pull-right"> {copyText}</div>
            } */}

            { element == "div" 
                ?   isCopied    ? <div className={cName}>Copied!</div>
                                :   <div className={cName}> {copyText}</div>
                : element == "a" 
                    ? isCopied      ? <a className={cName}>Copied!</a>
                                    : <a className={cName}>{copyText}</a>
                    : isCopied      ? <>Copied!</>
                                    : <>{copyText}</>
            }
            </button>
        </>
    );
}

export default ClipboardCopy;
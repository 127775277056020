import React, {useState, useEffect} from 'react'
import { messageResources } from '../../assets/util/messageResources'
import InfoModal from '../../components/modal/InfoModal'
import StreetLeafMap from '../../components/map/StreetLeafMap'
import EditSupportModal from '../../components/modal/EditSupportModal'
import useAuth from '../../hooks/useAuth'

const SupportCard = ({worker, entityId, isStuActive, number}) => {

    //console.log(number)
    //console.log(entityId)
    const [addressMap,setAddressMap] = useState({})

    const { roles } = useAuth()
    //console.log(roles)
    //console.log(roles?.includes(3))

    //const {userInfo} = useSelector((state) => state.user);

    useEffect(() => {
		
        //console.log(worker)

        var address = {}
        address.address1 = worker.address1
        address.address2 = worker.address2
        address.postcode = worker.postcode
        address.lat = worker.lat
        address.long = worker.long
        address.city = worker.city

        setAddressMap(address)

	}, [worker]);

    return (

        <>

        <div className="col-lg-6 col-md-12">
            <div className="card card-topline-aqua">
                <div className="card-body no-padding height-9">
                    <div className="profile-usertitle">
                        <div className="profile-usertitle-name">
                            
                            {worker.name} {worker.surname} 

                            {(roles?.includes(2) || roles?.includes(3) || roles?.includes(5)) && isStuActive &&
                                <div style={{marginBottom: '10px'}}>

                                    <InfoModal
                                        buttonText={messageResources.BUTTON.DELETE}
                                        stuId={entityId}
                                        toDeleteId={worker._id}
                                        methodType="deleteSupport"
                                        header={messageResources.MODAL.DELETE_SUPPORT} 
                                        text={messageResources.MODAL.DEL_SUPPORT_TEXT}
                                        toastText={messageResources.TOASTY.SUPPORT_DELETED}
                                    />
                                    
                                    <EditSupportModal
                                        buttonText={messageResources.BUTTON.AMEND}
                                        stuId={entityId}
                                        supportToEdit={worker}
                                        header={messageResources.MODAL.AMEND_SUPPORT} 
                                        amend={true}
                                    />

                                </div>
                            }
                        </div>
                    </div>
                    <div>
                    <ul className="list-group list-group-unbordered" >
                        <li className="list-group-item">
                            <b>{messageResources.CONTACT_INFO.NUMBER}</b> 
                            <a className="pull-right">{worker.contact_number}</a>
                        </li>
                        <li className="list-group-item">
                            <b>{messageResources.COMMON.EMAIL}</b> 
                            <a className="pull-right">{worker.email}</a>
                        </li>
                        {/* <li className="list-group-item">
                            <b>{messageResources.COMMON.ADDRESS}</b> 
                            <a className="pull-right">{worker.address1}, {worker.postcode}, {worker.city}</a>
                        </li> */}
                        <li className="list-group-item">
                            <b>{messageResources.COMMON.NOTES}</b> 
                            <a className="pull-right">{worker.notes}</a>
                        </li>
                        {number > 1 &&
                            <li className="list-group-item">
                                <StreetLeafMap slots="col-lg-12 col-md-12" address={addressMap} keyMap={Math.random()} showHeader={false}/>
                            </li>
                        }
                    </ul>
                    </div>
                    {/* <div className="profile-userbuttons">
                        <Link to={`/students/edit/${studentInfo._id}`} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">
                            {messageResources.OPS_TITLES.STU_EDIT}
                        </Link>
                    </div> */}
                </div>
            </div>
        </div>

        { number <= 1 && <div className="col-lg-6 col-md-12"><StreetLeafMap slots="col-lg-12 col-md-12" address={addressMap} keyMap={Math.random()} showHeader={true}/></div> }
        </>
        
    )
}

export default SupportCard
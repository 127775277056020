import React from 'react'
import { messageResources } from '../../assets/util/messageResources'
import ClipboardCopy from '../../components/copyText/ClipboardCopy'
import Moment from 'moment'

const AssessmentCardEng = ({assessment}) => {

    //console.log("|-----------> ProgrammeCard COMPONENT ProgrammeCard <--------|")

    return (
        <div className="col-lg-3">
            <div className="card card-topline-aqua">
                <div className="card-body no-padding height-9">
                    
                    <div>
                        <ul className="list-group list-group-unbordered">
                        
                            <li className="list-group-item">
                                <b>{messageResources.ASSESSMENT.TYPE}</b> 
                                <a className="pull-right"><ClipboardCopy copyText="English" bClass="pull-right" element="a"/></a>
                            </li>
                        
                            <li className="list-group-item">
                                <b>{messageResources.ASSESSMENT.DATE}</b> 
                                <a className="pull-right">{ assessment?.engDate ? <ClipboardCopy copyText={Moment(assessment?.engDate).format('DD MMMM YYYY')} bClass="pull-right" element="a"/> : ""}</a>
                            </li>
                            
                            <li className="list-group-item">
                                <b>{messageResources.ASSESSMENT.RESULTS}</b> 
                                <a className="pull-right"><ClipboardCopy copyText={assessment?.engResults?.label}/></a>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssessmentCardEng
import React, {useState,useEffect} from "react";
import {Modal, Button} from 'react-bootstrap';
import { messageResources } from "../../assets/util/messageResources";
import addLocation from "../../assets/util/addLocation"
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller, FormProvider } from "react-hook-form"
import { newDocumentSchema, newCareersDocSchema } from '../../assets/util/yupValidations'
import Select from 'react-select'
import { yupResolver } from "@hookform/resolvers/yup"
import { docTypeOptions, yearOptions, careerDocTypeOptions } from '../../assets/util/selectOptions'
import { useUpdateGooDocumentFromStudentMutation, 
            useAddGooDocumentFromStudentMutation,
            selectStudentById } from '../../redux/student/studentSlice'


export default function EditDocModal({buttonText, header, stuId, docuToEdit, amend, career}) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [updateGooDocumentFromStudent, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateGooDocumentFromStudentMutation()

    const [addGooDocumentFromStudent] = useAddGooDocumentFromStudentMutation()

	const studentInfo = useSelector((state) => selectStudentById(state, stuId))

    useEffect(() => {

        if(amend){
            let docuToFill = {}
            docuToFill.type = {}
            docuToFill.type.value = docuToEdit.type
            docuToFill.type.label = docuToEdit.type
            docuToFill.description = docuToEdit.description
            docuToFill.url_link = docuToEdit.url_link
            docuToFill.id = docuToEdit.idDoc
            docuToFill.year = {}
            docuToFill.year.value = docuToEdit.year
            docuToFill.year.label = docuToEdit.year
            //console.log(docuToFill)

            reset(docuToFill)
        }else {
            reset({})
        }

	}, [docuToEdit]);

    const methods = useForm({
        resolver: yupResolver(career ? newCareersDocSchema : newDocumentSchema) ,
    });

	const {
        register,
        handleSubmit,
        reset,
        defaultValues,
		control,
        formState: { errors },
    } = methods;


    const onSubmit = async (formData) => {

        console.log("NO ERRORS")
        //console.log(formData);
        

		try{

			//console.log("<><><><><><> DOCUMENT onSubmit DOCUMENT  <><><><><><>") 
            formData.stuId = stuId
            formData.stuName = `${studentInfo?.name} ${studentInfo?.surname}`
            await addLocation(formData) 

            //console.log("<><><><><><> formEdited formEdited formEdited  <><><><><><>") 
            //console.log(formEdited)

            if(amend){
                //console.log("|-----> AMENDING DOC DOC AMENDING <------|")
                //await axios.put(`/docs/${formData.idDoc}`, formData)
                
                //  const res = await axios.put(
                //     `/docs/${formData.idDoc}`,
                //     formData,
                //     {headers: {
                //         authorization: `Bearer ` + token
                //     }
        
                //     });

                await updateGooDocumentFromStudent(formData).unwrap()
                toast.success(messageResources.TOASTY.DOCUMENT_EDITED)
            }else{
                //console.log(" ADDING ADDING ")
                
                await addGooDocumentFromStudent(formData).unwrap()

                toast.success(messageResources.TOASTY.DOCUMENT_ADDED)
            }

            setShow(false)

		}catch(err){
			console.log(err);
		}
	}


	

    
    return (
        <>
        {/* <button className="btn btn-outline-success" onClick={handleShow}>
            {buttonText}
        </button> */}
        {amend 
            ?
            <button style={{marginRight: '10px'}} className="btn btn-outline-success btn-circle" onClick={handleShow}>
                {buttonText}
            </button>
            :
            
            <button style={{marginRight: '10px'}} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" onClick={handleShow}>
                {buttonText}
            </button>
            
        }
        
        

        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
            <Modal.Title>
                <div className="row">
                <div className="col-md-1"><i style={{fontSize: '32px'}} className="material-icons">error_outline</i></div>
                    <div className="col-md-10">{header}</div>
                </div>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body><div className="row">
                                
                                <FormProvider {...methods}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                    
                                            <div className="card card-box">
                                                <div className="card-body " id="bar-parent">
                                                {/* <div className="row"> */}
                                                
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>{messageResources.DOCUMENTS.LINK} 
                                                                {errors?.url_link && <span className="errorSpan">  {errors?.url_link.message} </span>}
                                                            </label>
                                                            <input 	type="text" 
                                                                className="form-control" 
                                                                {...register('url_link')}
                                                                placeholder="Link from Google Docs"/>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>{messageResources.DOCUMENTS.TYPE}
                                                                {errors?.type && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                                            </label>
                                                            <Controller
                                                                name="type"
                                                                control={control}
                                                                render={({ field }) => (
                                                                <Select options={career ? careerDocTypeOptions : docTypeOptions} 
                                                                    {...field}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>

                                                    {career && 
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>{messageResources.DOCUMENTS.YEAR}
                                                                    {errors?.year && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                                                </label>
                                                                <Controller
                                                                    name="year"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                    <Select options={yearOptions} 
                                                                        {...field}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>{messageResources.DOCUMENTS.DESCRIPTION}</label>
                                                            <input 	type="text" 
                                                                className="form-control" 
                                                                {...register('description')}
                                                                placeholder="Doc Description if needed"/>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-1">
                                                        <button type="submit" style={{marginTop:"25px"}} className="btn btn-primary" onClick={methods.handleSubmit(onSubmit)}>{messageResources.BUTTON.ADD}</button>
                                                    </div> */}
                                                {/* </div> */}
                                            </div>
                                        </div>
                                        <div className="fake-modal-footer">
                                            <Button variant="secondary" onClick={handleClose}>
                                                {messageResources.BUTTON.CANCEL}
                                            </Button>
                                            <button className="btn btn-modal" onClick={() => methods.handleSubmit(onSubmit)}>
                                                {amend 
                                                    ?
                                                    messageResources.BUTTON.AMEND
                                                    :
                                                    messageResources.BUTTON.ADD
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </FormProvider>

                            </div></Modal.Body>
            {/* <Modal.Footer>
            
            </Modal.Footer> */}
        </Modal>
        </>
    );
}
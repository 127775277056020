import React, {useState} from "react";
import {Modal, Button} from 'react-bootstrap';
import { messageResources } from "../../assets/util/messageResources";
import { toast } from 'react-toastify';
import { useForm, FormProvider } from "react-hook-form"
import { changePwSchema } from '../../assets/util/yupValidations'
import { yupResolver } from "@hookform/resolvers/yup"
import InputText from "../forms/Input/InputText";
import { useUpdateStaffMutation } from "../../redux/staff/staffApiSlice";
import useAuth from "../../hooks/useAuth";

export default function EditPwModal({buttonText, header, rowTook }) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const methods = useForm({
        resolver: yupResolver(changePwSchema),
    });

	const {
        register,
        handleSubmit,
        reset,
        defaultValues,
		control,
        formState: { errors },
    } = methods;

    const { id } = useAuth()
    const [updateStaff] = useUpdateStaffMutation()
    //console.log(errors)

    const onSubmit = async (formData) => {

        console.log("NO ERRORS")
        formData._id = id
        

		try{

            //console.log("|-----> EditPwModal COMPONENT EditPwModal <------|")
            const res = await updateStaff(formData).unwrap()

            if(res.message){
                toast.error(res.message)
            }else{
                toast.success(messageResources.TOASTY.DOCUMENT_EDITED)
            }
            
            setShow(false)

		}catch(err){
			console.log(err);
		}
	}


	

    
    return (
        <>
        {/* <button className="btn btn-outline-success" onClick={handleShow}>
            {buttonText}
        </button> */}
        
            <div className="profile-userbuttons" style={{width: rowTook}}>
                <button style={{marginRight: '10px'}} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" onClick={handleShow}>
                    {buttonText}
                </button>
            </div>
        
        
        

        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
            <Modal.Title>
                <div className="row">
                <div className="col-md-1"><i style={{fontSize: '32px'}} className="material-icons">error_outline</i></div>
                    <div className="col-md-10">{header}</div>
                </div>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                        
                                <div className="card card-box">
                                    <div className="card-body " id="bar-parent">
                                    {/* <div className="row"> */}
                                    
                                    <InputText 
                                        slots="col-md-12"
                                        label={messageResources.MODAL.OLD_PW}
                                        errors={errors}
                                        register={register}
                                        type="text"
                                        plaHolder={messageResources.MODAL.OLD_PW}
                                        inputName="current"
                                        original={false}
                                    />
                                    <InputText 
                                        slots="col-md-12"
                                        label={messageResources.MODAL.NEW_PW}
                                        errors={errors}
                                        register={register}
                                        type="password"
                                        plaHolder={messageResources.MODAL.NEW_PW}
                                        inputName="newPassword"
                                        original={true}
                                    />
                                    <InputText 
                                        slots="col-md-12"
                                        label={messageResources.MODAL.RETYPE_PW}
                                        errors={errors}
                                        register={register}
                                        type="password"
                                        plaHolder={messageResources.MODAL.RETYPE_PW}
                                        inputName="retypeNew"
                                        original={false}
                                        errorText={messageResources.VALIDATIONS.PASSWORDS_MATCH}
                                    />
                                        
                                </div>
                            </div>
                            <div className="fake-modal-footer">
                                <Button variant="secondary" onClick={handleClose}>
                                    {messageResources.BUTTON.CANCEL}
                                </Button>
                                <button className="btn btn-modal" onClick={() => methods.handleSubmit(onSubmit)}>
                                    {messageResources.BUTTON.AMEND}
                                </button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
            
            </Modal.Footer> */}
        </Modal>
        </>
    );
}
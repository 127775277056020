import React, {useState,useEffect} from "react";
import {Modal, Button} from 'react-bootstrap';
import { messageResources } from "../../assets/util/messageResources";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router'
import InputText from '../../components/forms/Input/InputText'
import { useSelector } from 'react-redux';
import { useForm, Controller, FormProvider } from "react-hook-form"
import { destinationSchema } from '../../assets/util/yupValidations'
import Select from 'react-select'
import { yupResolver } from "@hookform/resolvers/yup"
import { destinationOptions } from '../../assets/util/selectOptions'
import { selectStudentById } from '../../redux/student/studentSlice'
import { useAddDestinationToProgramMutation } from '../../redux/programme/programmeApiSlice'

export default function AddDestination({buttonText, header, stuId, progId, currentDest}) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {

        if(currentDest?.label && currentDest?.label != ""){
            let destToFill = {}
            destToFill.type = {}
            destToFill.type.value = currentDest.value
            destToFill.type.label = currentDest.label
            destToFill.employer = currentDest.employer
            reset(destToFill)
            //setRender(true);
        }else {
            reset({})
        }

	}, [currentDest]);

    const [addDestinationToProgram] = useAddDestinationToProgramMutation()
    const navigate = useNavigate()
	const studentInfo = useSelector((state) => selectStudentById(state, stuId))


    const methods = useForm({
        resolver: yupResolver(destinationSchema) ,
    });

	const {
        register,
        handleSubmit,
        reset,
        defaultValues,
		control,
        formState: { errors },
    } = methods;

    //console.log(errors)

    const onSubmit = async (formData) => {

        console.log("NO ERRORS")

		try{

			//console.log("<><><><><><> DOCUMENT onSubmit DOCUMENT  <><><><><><>") 
            formData.stuId = stuId
            formData.stuName = `${studentInfo?.name} ${studentInfo?.surname}`
            formData.progId = progId

            const updatedProgramme = await addDestinationToProgram(formData).unwrap()

            toast.success(messageResources.TOASTY.DEST_ADDED)
        
            setShow(false)

            navigate(`/academic/mark/${stuId}`, { state: { programme: updatedProgramme, showButton: true } });

		}catch(err){
			console.log(err);
		}
	}


	

    
    return (
        <>
        
        <button style={{marginRight: '10px'}} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" onClick={handleShow}>
            {buttonText}
        </button>
            
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
            <Modal.Title>
                <div className="row">
                <div className="col-md-1"><i style={{fontSize: '32px'}} className="material-icons">error_outline</i></div>
                    <div className="col-md-10">{header}</div>
                </div>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body><div className="row">
                                
                                <FormProvider {...methods}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                    
                                            <div className="card card-box">
                                                <div className="card-body " id="bar-parent">
                                                {/* <div className="row"> */}
                                                    
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>{messageResources.PROGRAMME.DESTINATION}
                                                                {errors?.type && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                                            </label>
                                                            <Controller
                                                                name="type"
                                                                control={control}
                                                                render={({ field }) => (
                                                                <Select options={destinationOptions} 
                                                                    {...field}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <InputText 
                                                        slots="col-md-12"
                                                        label={messageResources.PROGRAMME.EMPL}
                                                        errors={errors}
                                                        register={register}
                                                        type="string"
                                                        plaHolder={messageResources.PROGRAMME.EMPL}
                                                        inputName="employer"
                                                        original={true}
                                                    />
                                                    

                                    
                                            </div>
                                        </div>
                                        <div className="fake-modal-footer">
                                            <Button variant="secondary" onClick={handleClose}>
                                                {messageResources.BUTTON.CANCEL}
                                            </Button>
                                            <button className="btn btn-modal" onClick={() => methods.handleSubmit(onSubmit)}>
                                                    {messageResources.BUTTON.AMEND}
                                            </button>
                                        </div>
                                    </form>
                                </FormProvider>

                            </div></Modal.Body>
            {/* <Modal.Footer>
            
            </Modal.Footer> */}
        </Modal>
        </>
    );
}
import { store } from '../../redux/store'
import { studentSlice } from '../../redux/student/studentSlice'
import { notificationsApiCalls } from '../../redux/notifications/notificationsApiCalls'
import useAuth from '../../hooks/useAuth';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';


// ROLES
// { value: 1, label: 'USER' }
// { value: 2, label: 'ADMINISTRATOR' }
// { value: 3, label: 'STUDENT REGISTER' }
// { value: 4, label: 'STUDENT EHCP' }
// { value: 5, label: 'STUDENT EDIT NO NOTIS' },

// THIS COMPONENT IS TO KEEP THE RTK QUERIES SUBSCRIPTIONS ACTIVE
// BY DEFAULT THE SUBS ARE ACTIVE BY 60 SECONDS SO IF WE
// TRY TO USE useSelector IN A COMPONENT INSTEAD OF QUERING AND THE SUB IS NO LONGER ACTIVE
// WE WONT GET THE DATA
const PreFetch = () => {

    //console.log('|----> PreFetch COMPONENT PreFetch <------|')

    // const { roles } = useAuth();
    // console.log(roles)

    useEffect(() => {
    
        //console.log('|----> PreFetch SUBSCRIBING PreFetch <------|')
        // this creates a subscription to that query which does not expire
        // initiate() creates the manual subscription
        const students = store.dispatch(studentSlice.endpoints.getStudents.initiate())
        const notifications = store.dispatch(notificationsApiCalls.endpoints.getNotifications.initiate())

        // admin get all notifications
        // if(roles?.includes(2)){
        //     notifications = store.dispatch(notificationsApiCalls.endpoints.getNotifications.initiate())
        // }else{
        //     notifications = store.dispatch(notificationsApiCalls.endpoints.getMyNotifications.initiate())
        // }
    
        return () => {
            //console.log('|-----> unsubscribing <-------|')
            students.unsubscribe()
            notifications.unsubscribe()
        }
    }, [])

    return <Outlet />
}
export default PreFetch
import List from '../../components/list/List'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { Link } from 'react-router-dom'
import Moment from 'moment'
import { messageResources } from '../../assets/util/messageResources'
import { selectAllStudents } from '../../redux/student/studentSlice'
import { useSelector } from 'react-redux'
import ClipboardCopy from '../../components/copyText/ClipboardCopy'

const EhcpDatesList = () => {

    console.log("|-----> EhcpDatesList COMPONENT EhcpDatesList <------|")

    const columnsForEhcpDates = [
        
        { title: messageResources.COMMON.NAME,  field: "name", defaultSort: "asc", render:rowData=><Link to={`/students/${rowData._id}`}> {rowData.name} </Link> }, 
        { title: messageResources.COMMON.SURNAME,  field: "surname", defaultSort: "asc", render:rowData=><ClipboardCopy copyText={rowData.surname} /> }, 
        { title: messageResources.COMMON.EMAIL,  field: "email", render:rowData=><ClipboardCopy copyText={rowData.email} />  },
        { title: messageResources.STUDENT.EHCP_DATE,  field: "ehcp.date" , type: 'date', sorting:false, emptyValue:()=><em>N/A</em>, render:rowData=><ClipboardCopy copyText={Moment(rowData.ehcp.date).format('DD MMMM YYYY')} />},
        { title: messageResources.STUDENT.ANNUAL_REVIEW,  field: "ehcp.last_review" , type: 'date', sorting:false, emptyValue:()=><em>N/A</em>, render:rowData=><ClipboardCopy copyText={Moment(rowData.ehcp.last_review).format('DD MMMM YYYY')} />},
        { title: messageResources.STUDENT.NEXT_REVIEW,  field: "ehcp.next_review" , type: 'date', sorting:false, emptyValue:()=><em>N/A</em>, render:rowData=><ClipboardCopy copyText={Moment(rowData.ehcp.next_review).format('DD MMMM YYYY')} />},
        
    ];

    const allOfThem = useSelector(selectAllStudents)

    return ( 

        <>    
            <Breadcrumb pageTitle={`${messageResources.OPS_TITLES.EHCP_DATA} (${allOfThem.length} records)`}/>
            <div className="row">
                <div className="col-md-12">
                    <List   titleForTable="" 
                            dataForTable={JSON.parse(JSON.stringify(allOfThem))} 
                            columnsForTable={columnsForEhcpDates}
                            search={false}
                            colButton={true}
                            filter={true}
                            pageSize={30}/>
                </div>
            </div>
        </>

    
    )
}

export default EhcpDatesList

import React from 'react'
import { messageResources } from '../../assets/util/messageResources'
import ClipboardCopy from '../../components/copyText/ClipboardCopy'
import Moment from 'moment'

const ProgrammeCardSecond = ({programme}) => {

    //console.log("|-----------> ProgrammeCard COMPONENT ProgrammeCard <--------|")

    return (
        <div className="col-lg-4">
            <div className="card card-topline-aqua">
                <div className="card-body no-padding height-9">
                    {/* <div className="profile-usertitle">
                        <div className="profile-usertitle-name">
                            
                            {programme.programme.label}  / {programme.speciality.label} 

                        </div>
                    </div> */}
                    <div>
                        <ul className="list-group list-group-unbordered">
                            {programme?.qualyHours && 
                                <li className="list-group-item">
                                    <b>{messageResources.PROGRAMME.FORQUALY}</b> 
                                    <a className="pull-right"><ClipboardCopy copyText={programme?.qualyHours}/></a>
                                </li>
                            }
                            <li className="list-group-item">
                                <b>{messageResources.PROGRAMME.EPP}</b> 
                                <a className="pull-right"><ClipboardCopy copyText={programme?.eppHours}/></a>
                            </li>
                            <li className="list-group-item">
                                <b>{messageResources.PROGRAMME.TOTAL}</b> 
                                <a className="pull-right"><ClipboardCopy copyText={programme?.totalHours}/></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProgrammeCardSecond
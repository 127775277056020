import React from 'react'
import { Link } from 'react-router-dom'

const NewNotification = ({data, slots}) => {

    //console.log("|-----> Notification COMPONENT Notification <-----|")
    //console.log(data._id)
    
    
    return (

        
        <div className={`col-xs-12 ${slots} col-sm-offset-3`}>
            <div className="new-message-box">
                <div className={`${data.priority == 3 ? "new-message-box-info" : data.priority == 2 ? "new-message-box-alert" : "new-message-box-danger"}`}>
                    <div className= {`info-tab ${data.priority == 3 ? "tip-icon-info" : data.priority == 2 ? "tip-icon-alert" : "tip-icon-danger"}`}><i></i></div>
                    <div className={`${data.priority == 3 ? "tip-box-info" : data.priority == 2 ? "tip-box-alert" : "tip-box-danger"}`}>
                        { data.type == 1
                
                        ?
                            <Link to={`/students/edit/${data.studentId._id}`} title="Process Notification">
                                <p>{data.message} </p>
                            </Link>

                        :

                            <Link to={`/students/${data.studentId._id}`} title="Process Notification">
                                <p>{data.message} </p>
                            </Link>
                        }
                    </div>
                </div>
            </div>
        </div>
            
        
        
/* <div className="row">
    <div className="col-xs-12 col-sm-3 col-sm-offset-3">
            <div className="new-message-box">
                <div className="new-message-box-warning">
                    <div className="info-tab tip-icon-warning" title="error"><i></i></div>
                    <div className="tip-box-warning">
                        <p>No Medical added for Asenefa Sivacenko when student was registered</p>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    <div className="col-xs-12 col-sm-3 col-sm-offset-3">
            <div className="new-message-box">
                <div className="new-message-box-success">
                    <div className="info-tab tip-icon-success" title="success"><i></i></div>
                    <div className="tip-box-success">
                        <p>No Medical added for Asenefa Sivacenko when student was registered</p>
                    </div>
                </div>
            </div>
        </div>
        */



    )
}

export default NewNotification
import {useState, useRef} from 'react'
import { messageResources } from '../../../assets/util/messageResources';

const InputText = ({slots, label, errors, register, plaHolder, inputName, type, original, errorText}) => {

    //console.log(Object.keys(errors));
    const keys = Object.keys(errors)
    //console.log(keys.includes(inputName));
    let errorToShow = messageResources.VALIDATIONS.REQUIRED
    if(errorText){
        errorToShow = errorText
    }
    //console.log(type)
    // const passRef = useRef()
    // const[eye,seteye]=useState(true);
    // const[password,setpassword]=useState("password");

    // const Eye=()=>{
    //     if(password=="password"){
    //         setpassword("text");
    //         seteye(false);
    //     }
    //     else{
    //         setpassword("password");
    //         seteye(true);
    //     }
    // }
    

    return (
        <div className={slots}>
            <div className="form-group">
                <label>{label} 
                {keys.includes(inputName) && <span className="errorSpan">  {original ? errors[inputName].message : errorToShow} </span>}
                </label>

                <input 	
                    type="text"
                    className="form-control" 
                    {...register(inputName)}
                    placeholder={plaHolder}
                />
            
                {/* {type === 'text' 
                    ?
                        <input 	
                            type="text"
                            className="form-control" 
                            {...register(inputName)}
                            placeholder={plaHolder}
                        />
                    :
                    <>
                        <input  className="input100" ref={passRef}
                            type={password} 
                            placeholder={plaHolder}
                            {...register(inputName)}
                        />
                        <span className="focus-input100" data-placeholder="&#xf191;"></span>
                        <i onClick={Eye} style={{ color: '#fff', fontSize: '22px', lineHeight: '46px' }} className={`fa ${eye ? "fa-eye-slash" : "fa-eye" }`}></i>
                    </>              
                }
                     */}
                
            </div>
        </div>
    )
}

export default InputText

import { createSelector, createEntityAdapter } from "@reduxjs/toolkit"
import { apiSlice } from "../api/apiSlice"
import { roleOptions } from "../../assets/util/selectOptions"

const staffAdapter = createEntityAdapter({})

const initialState = staffAdapter.getInitialState()

export const staffApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getStaffs: builder.query({
            query: () => '/staffApi',
            // there is no an error
            validateStatus: (response, result) => {
                return response.status === 200 && !result.error
            },
            // This has to be dome always we mormalize data and working
            // witn mongo db
            // when normalize data there are one array with the ids and one 
            // object with the entities
            // the property fro normalizaition is id but inb mongo db is called _id
            // here we add id with _id value 
            transformResponse: responseData => {
                const loadedStaffs = responseData.map(staff => {
                    staff.id = staff._id

                    if(staff.role.length > 0){
                        let toPrint = "";
                        staff.role.map((uno) => {
                            toPrint += `${roleOptions[uno-1].label} , `
                        })
                        
                        if(toPrint.trim().endsWith(',')) { toPrint = toPrint.slice(0, -2)}
                        staff.rolString = toPrint
                    }
                    return staff
                });
                return staffAdapter.setAll(initialState, loadedStaffs)
            },
            // it provides the tags that can be invalidated
            providesTags: (result, error, arg) => {
                if(result?.ids){
                    return [
                        { type: 'Staff', id: 'LIST' },
                        // mapping all the ids so ny of them can be invalidated
                        ...result.ids.map(id => ({ type: 'Staff', id }))
                    ]
                }else return [{ type: 'Staff', id: 'LIST' }]
            }
        }),
        getStaffById: builder.query({
            query: id => `/staffApi/find/${id}`,
            // there is no an error
            validateStatus: (response, result) => {
                return response.status === 200 && !result.error
            },
            providesTags: (result, error, arg) => {
                return [{ type: 'Staff', id: 'Uno'}]
            }
        }),
        updateStaff: builder.mutation({
            query: staffToEdit => ({
                url: `/staffApi/${staffToEdit.staffId}`,
                method: 'PUT',
                body: staffToEdit
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Staff',  id: "LIST"},
                { type: 'Staff', id: "Uno"}
            ]
        }),
        updateStaffReset: builder.mutation({
            query: staffToEdit => ({
                url: `/staffApi/reset/${staffToEdit.staffId}`,
                method: 'PUT',
                body: staffToEdit
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Staff',  id: "LIST"},
                { type: 'Staff', id: "Uno"}
            ]
        })
        
    })
})

export const {
    useGetStaffsQuery,
    useGetStaffByIdQuery,
    useUpdateStaffMutation,
    useUpdateStaffResetMutation
} = staffApiSlice

// returns the query result object
export const selectStaffsResult = staffApiSlice.endpoints.getStaffs.select()

// creates memoized selector
// used below to create selectors
const selectStaffsData = createSelector(
    selectStaffsResult,
    staffsResult => staffsResult.data // normalized state object ids & entities
)

export const {
    selectAll: selectAllStaffs,
    selectById: selectStaffById,
    selectIds: selectStaffIds
} = staffAdapter.getSelectors(state => selectStaffsData(state) ?? initialState)



import axios from "axios"
import { useState, useEffect } from "react";

const useIpAddress = () => {

    const [result,setResult] = useState();

    useEffect(() => {
        const getData = async () => {
            console.log("<><><><><><> GETTING IP ADDRESS  <><><><><><>");
            const res = await axios.get('https://geolocation-db.com/json/')
            console.log(res.data);
            setResult(res.data);
        
        };
        getData();
    }, []);
    
    return result;

}

export default useIpAddress;
import React, {useEffect, useState} from 'react'
import Navbar from '../../components/navbar/Navbar'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Footer from '../../components/footer/Footer'
import EmailInput from '../../components/forms/Input/EmailInput'
import StaffData from '../../components/forms/staffData/StaffData'
import { messageResources } from '../../assets/util/messageResources'
import { toast } from 'react-toastify';
import "../../assets/css/forForm.css"
import axios from 'axios'


const AddStaff = () => {

	console.log("<><><><><><> ADD STAFF ADD STAFF  <><><><><><>")

	useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

	const [page,setPage] = useState(0);
	const [emailFromForm,setEmailFromForm] = useState([]);

	const [emailToForm,setEmailToForm] = useState("");
	const [staffFromDb,setStaffFromDb] = useState({});

	const formTitles = [messageResources.COMMON.STEP1, messageResources.COMMON.STEP2];

	const PageDisplay = () => {
		if(page === 0){
			return <EmailInput formData={emailFromForm} setFormData={setEmailFromForm}/>
		}else if(page ===1){
			return <StaffData staffDb={staffFromDb}/>
		}
	}

	const getStaffByEmail = async () => {

		console.log("<><><><><><> getStaffByEmail FIRST STEP  <><><><><><>")
		//console.log(emailFromForm);
		const res = await axios.post("/staffApi/byEmail",emailFromForm);
		//console.log(res.data);
		
		if(res.data?.email){
			setStaffFromDb(res.data);

			toast.warn(messageResources.TOASTY.STAFF_REGISTERED,{
				autoClose: 12002,
			})
		}else{
			setStaffFromDb({emailFixed: emailFromForm.email});
			//toast.error(messageResources.TOASTY.STUDENT_ADDED)
		}
	}

    return (
    
		<div className="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md page-full-width header-white white-sidebar-color logo-indigo">
			<div className="page-wrapper">
            <Navbar />
            <div className="page-container">
                <div className="page-content-wrapper">
                    <div className="page-content">
					<Breadcrumb pageTitle={messageResources.OPS_TITLES.STAFF_ADD.concat(" ").concat(...formTitles[page])}/>
                        <div className="row">
                        <div className="col-md-12 col-sm-6">
						
							<div>{PageDisplay()}</div>
							<div className="col-md-2 col-sm-2">
								<button className="btn btn-primary" style={{float:"left"}}
									disabled={page == 0}
									onClick={() => {
										setPage((currPage) => currPage - 1);
									}}
								>
									{messageResources.BUTTON.PREV}
								</button>
								
								<button className="btn btn-primary" style={page === 1 ? {display:"none"} : {display:"block", float:"right"}}
									
									onClick={() => {

										if (page === 1) {
											
											console.log(emailFromForm);
										} else {
											setPage((currPage) => currPage + 1);
											getStaffByEmail();
										}

									}}
								>
									{page === 1 ? messageResources.COMMON.SUBMIT : messageResources.BUTTON.NEXT }
								</button>
							</div>
						{/* <FormProvider {...methods}>
						<form onSubmit={handleSubmit(onSubmit)}>
							

							<ContactInfo />
							
						
						
							
							<button type="submit" className="btn btn-primary" onClick={methods.handleSubmit(onSubmit)}>{messageResources.COMMON.SUBMIT}</button>
						</form>
						</FormProvider>	 */}

						</div>
                        </div>
                    </div>
                </div>
            </div>
			<Footer />
		</div>
    </div> 

    )
}

export default AddStaff

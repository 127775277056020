import {useEffect} from 'react'
import ApexCharts from 'react-apexcharts'
import AreaChart from '../charts/AreaChart'
import PieChart from '../charts/PieChart'
import ColumnChart from '../charts/ColumnChart'
import { useSelector } from 'react-redux'
import {getGenderSeries, getBoroughSeries, getProgChoiceSeries,
            getEthniSeries, getLangSeries, getVocChoiceSeries,
            getBursarySeries } from '../../assets/util/pieChartStats'
import { selectAllStudents } from '../../redux/student/studentSlice'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { messageResources } from '../../assets/util/messageResources'

const Charts = () => {

    useEffect(() => {       
        window.scrollTo(0, 0);
    },[])

    
    //console.log("|-------------> allStudents in allOfThem <---------------|")
    const allOfThem = useSelector(selectAllStudents)
    //console.log(allOfThem)

    const genderFrom = getGenderSeries(allOfThem)
    const langFrom = getLangSeries(allOfThem)
    const borouFrom = getBoroughSeries(allOfThem)
    const ethiFrom = getEthniSeries(allOfThem)
    const vocaFrom = getVocChoiceSeries(allOfThem)
    const programFrom = getProgChoiceSeries(allOfThem)
    const bursaryFrom = getBursarySeries(allOfThem)


    return (
        <>
            <Breadcrumb pageTitle={messageResources.OPS_TITLES.HOURS_CHART}/>
            
            <div className="col-md-12" style={{display: 'flex',  marginBottom: '30px', height:'500px'}}>

                <div className="col-lg-6 col-md-12 chartCard" >
                        
                        <PieChart series={genderFrom} title={messageResources.CHART.GENDER} legendPos='right'/>
                
                </div>
                <div className="col-lg-6 col-md-12 chartCard" >
                
                    <PieChart series={ethiFrom} title={messageResources.CHART.ETH} legendPos='right'/>

                </div>
                
            </div> 

            <div className="col-md-12" style={{display: 'flex',  marginBottom: '30px', height:'500px'}}>

                <div className="col-lg-6 col-md-12 chartCard" >
                        
                    <PieChart series={borouFrom} title={messageResources.CHART.BORO} legendPos='right'/>
                
                </div>

                <div className="col-lg-6 col-md-12 chartCard" >

                    <PieChart series={langFrom} title={messageResources.CHART.LANGS} legendPos='right'/>

                </div>

            </div>


            <div className="col-md-12" style={{display: 'flex',  marginBottom: '30px', height:'500px'}}>

                <div className="col-lg-6 col-md-12 chartCard" >
                        
                    <PieChart series={bursaryFrom} title={messageResources.CHART.BUR} legendPos='right'/>
                
                </div>

                {/* <div className="col-lg-6 col-md-12" style={{background: '#fff', borderTop: '3px solid #6f824f', borderRadius: '10px', marginLeft: '10px', paddingTop: '10px'}}>

                    <PieChart series={langFrom} title={messageResources.CHART.LANGS} legendPos='right'/>

                </div> */}

            </div>

            
        </>

    )
}

export default Charts
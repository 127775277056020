import {useEffect, useState, useRef } from 'react'
import PieChart from '../charts/PieChart'
import InputSelect from '../../components/forms/Input/InputSelect'
import { useSelector } from 'react-redux'
import { getProgStatsPerYear } from '../../assets/util/pieChartStats'
import { useForm, Controller, FormProvider } from "react-hook-form"
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { messageResources } from '../../assets/util/messageResources'
import { yearChartOptions } from '../../assets/util/selectOptions'
import axios from 'axios'
import { selectCurrentToken } from '../../redux/auth/authSlice'

const ChartsPerYear = () => {

    useEffect(() => {       
        window.scrollTo(0, 0);
    },[])

    let ehBtnRef = useRef();
    
    
    const methods = useForm({});
    const {
        register,
        handleSubmit,
        defaultValues,
        reset,
        control,
        formState: { errors },
    } = methods;


    const token = useSelector(selectCurrentToken)
    const [mainStats,setMainStats] = useState([])
    const [year,setYear] = useState("")

    const onSubmit = async (formData) => {

		try{

            if(ehBtnRef.current){
                //console.log("------> DISABLE BUTTON <---------")
                ehBtnRef.current.setAttribute("disabled", "disabled");
            }

            let yearNoSlash = (formData.year.label).replace('/','_')
        
            const response = await axios.get(
                            `https://www.seroots.org.uk/programmes/${yearNoSlash}`, {
                                
                            headers: {
                                        Authorization: `Bearer ` + token
                                    }    
            });

            setMainStats(getProgStatsPerYear(response.data))
            setYear(formData.year.label)

            if(ehBtnRef.current){
                //console.log("------> DISABLE BUTTON <---------")
                ehBtnRef.current.removeAttribute("disabled");
            }


        }catch(err){
			console.log(err);
		}
	}



    return (
        <>
            <Breadcrumb pageTitle={`${messageResources.OPS_TITLES.HOURS_CHART} ${year}`}/>

            <div className="card card-topline-aqua">
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        
                            <div className="card-body " id="bar-parent">
                                <div className="row">
                                    
                                    <InputSelect 
                                        slots="col-md-2" 
                                        label={messageResources.CHART.YEAR} 
                                        errors={errors}
                                        control={control}
                                        inputName="year"
                                        isClearable={true}
                                        original={false}
                                        selectOptions={yearChartOptions}
                                    />
                                    <div className="col-md-1">
                                        <button type="submit" style={{marginTop:"25px"}} className="btn btn-primary" ref={ehBtnRef} onClick={methods.handleSubmit(onSubmit)}>{messageResources.BUTTON.SEARCH}</button>
                                    </div>
                                </div>
                            </div>
                        
                    </form>
                </FormProvider>
            </div>    

            <div className="col-md-12 chartWrap">
            
                <div className="col-lg-6 col-md-12 chartCard" >
                        
                    <PieChart series={mainStats?.programme} title={`${messageResources.CHART.PROG} ${year}`} legendPos='right'/>
                
                </div>

                <div className="col-lg-6 col-md-12 chartCard">
                
                
                    {mainStats?.numbers && <table className='statable'>
                        <tr>
                            <th style={{width: '80%'}} >{`${year} ${messageResources.STATABLE.QUALI}`}</th>
                            <th>{messageResources.STATABLE.EXPECT}</th>
                            <th>{messageResources.STATABLE.ACHIV}</th>
                            <th>{messageResources.STATABLE.PERCEN}</th>
                        </tr>
                        <tr>
                            <td>{messageResources.STATABLE.PRE}</td>
                            <td>{mainStats?.numbers?.nonExpected}</td>
                            <td>{mainStats?.numbers?.nonAchieved}</td>
                            <td>{mainStats?.numbers?.nonPercent}%</td>
                        </tr>
                        <tr>
                            <td>{messageResources.STATABLE.HORTI}</td>
                            <td>{mainStats?.numbers?.hortiExpected}</td>
                            <td>{mainStats?.numbers?.hortiAchieved}</td>
                            <td>{mainStats?.numbers?.hortiPercent}%</td>
                        </tr>
                        <tr>
                            <td>{messageResources.STATABLE.RETAIL}</td>
                            <td>{mainStats?.numbers?.retailExpected}</td>
                            <td>{mainStats?.numbers?.retailAchieved}</td>
                            <td>{mainStats?.numbers?.retailPercent}%</td>
                        </tr>
                        <tr>
                            <td>{messageResources.STATABLE.FLORAL}</td>
                            <td>{mainStats?.numbers?.floralExpected}</td>
                            <td>{mainStats?.numbers?.floralAchieved}</td>
                            <td>{mainStats?.numbers?.floralPercent}%</td>
                        </tr>
                        <tr>
                            <td>{messageResources.STATABLE.ENTRY}</td>
                            <td>{mainStats?.numbers?.entryExpected}</td>
                            <td>{mainStats?.numbers?.entryAchieved}</td>
                            <td>{mainStats?.numbers?.entryPercent}%</td>
                        </tr>
                        <tr>
                            <td>{messageResources.STATABLE.ENG}</td>
                            <td>{mainStats?.numbers?.englishExpected}</td>
                            <td>{mainStats?.numbers?.englishAchieved}</td>
                            <td>{mainStats?.numbers?.englishPercent}%</td>
                        </tr>
                        <tr>
                            <td>{messageResources.STATABLE.MATHS}</td>
                            <td>{mainStats?.numbers?.mathsExpected}</td>
                            <td>{mainStats?.numbers?.mathsAchieved}</td>
                            <td>{mainStats?.numbers?.mathsPercent}%</td>
                        </tr>
                        <tr>
                            <td>{messageResources.STATABLE.EMPLOY}</td>
                            <td>{mainStats?.numbers?.employExpected}</td>
                            <td>{mainStats?.numbers?.employAchieved}</td>
                            <td>{mainStats?.numbers?.employPercent}%</td>
                        </tr>
                        <tr>
                            <td>{messageResources.STATABLE.ICT}</td>
                            <td>{mainStats?.numbers?.ictExpected}</td>
                            <td>{mainStats?.numbers?.ictAchieved}</td>
                            <td>{mainStats?.numbers?.ictPercent}%</td>
                        </tr>
                        <tr style={{backgroundColor: '#6f824f', color: '#fff'}}>
                            <td>{messageResources.STATABLE.TOTAL}</td>
                            <td>{mainStats?.numbers?.totalExpected}</td>
                            <td>{mainStats?.numbers?.totalAchieved}</td>
                            <td>{mainStats?.numbers?.totalPercent}%</td>
                        </tr>
                    </table>}
                </div>
                
                
            </div>

            <div className="col-md-12 chartWrap">

                <div className="col-lg-6 col-md-12 chartCard">
                        
                    <PieChart series={mainStats?.destination} title={`${messageResources.CHART.DESTI} ${year}`} legendPos='right'/>
                
                </div>

                <div className="col-lg-6 col-md-12 chartCard">
                        
                        <PieChart series={mainStats?.retention} title={`${messageResources.CHART.RETEN} ${year}`} legendPos='right'/>
                    
                    </div>

            </div>

            <div className="col-md-12 chartWrap">

                    <div className="col-lg-6 col-md-12 chartCard">
                        
                        <PieChart series={mainStats?.vocational} title={`${messageResources.CHART.SPEC} ${year}`} legendPos='right'/>
                    
                    </div>

            </div>

            
        </>

    )
}

export default ChartsPerYear

const formatProgramme = (program) => {

    let prToList = {}

    prToList._id = program._id
    prToList.programme = {}
    prToList.programme = program.programme
    prToList.speciality = program.speciality
    prToList.startDate = program.startDate
    prToList.endDate = program.endDate
    prToList.weeklyHours = program.weeklyHours
    prToList.weeklyDays = program.weeklyDays
    prToList.learnerWeeks = program.learnerWeeks
    prToList.totalHours = program.totalHours
    prToList.qualyHours = program.qualyHours
    prToList.eppHours = program.eppHours
    prToList.academicYear = program.academicYear
    prToList.gcseEng = program.gcseEng
    prToList.gcseMath = program.gcseMath
    prToList.engExem = program.engExem
    prToList.destination = {}
    prToList.destination = program.destination
    prToList.mathsExem = program.mathsExem
    if(program.suppActivities?.length > 0) prToList.suppActivities = program.suppActivities
    if(program.eepActivities?.length > 0) prToList.eepActivities = program.eepActivities
    if(program.cgQualifications?.length > 0) prToList.cgQualifications = program.cgQualifications

    return prToList

}

export default formatProgramme
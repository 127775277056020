import React from 'react'
import ApexCharts from 'react-apexcharts'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { messageResources } from '../../assets/util/messageResources'

const PieChart = ({series, title, legendPos}) => {

    let toLabels = []
    let toData = []

    series?.map(uno => {
        if(uno.toData > 0){
            toData.push(uno.toData)
            toLabels.push(uno.toLabel)
        }
    })

    // console.log(toLabels)
    // console.log(toData)


    const state = {
        
        series:  toData,
        options : {
            chart: {
                width: 550,
                type: 'pie',
            },
            labels: toLabels,
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '18px',
                }    
            },  
            colors: ['#2E93fA', '#59bf21', '#546E7A', '#c23a68', '#FF9800', '#22dab2', '#f35221', '#7228eb', '#1504f7'],  
            // responsive: [{
            //     breakpoint: 480,
            //     options: {
            //         chart: {
            //             width: 200
            //         },
            //         legend: {
            //             position: 'bottom'
            //         }
            //     }
            // }],
            grid: {
                borderColor: '#e7e7e7'
            },
            title: {
                text: title,
                align: 'left',
                style: {
                    fontSize:  '23px',
                    fontWeight:  'normal',
                    fontFamily:  'Poppins, sans-serif',
                    color:  '#6f824f'
                },
            },
            fill: {
                type: 'gradient',
            },
            legend: {
                position: legendPos,
                fontSize: '18px',
                //horizontalAlign: 'right',
                //floating: true,
                //offsetY: -25,
                //offsetX: -10
            },
            
            
            
        }
    
    
    }

  return (
    <>   
        <ApexCharts options={state.options} series={state.series} type="pie" height="98%" />    
    </>
    

  )
}

export default PieChart
import React , {useEffect, useState} from 'react';
import { yupResolver } from "@hookform/resolvers/yup"
import Select from 'react-select'
import ContactInfo from '../contactInfo/ContactInfo'
import { messageResources } from '../../../assets/util/messageResources'
import { useForm, Controller, FormProvider } from "react-hook-form"
import { positionOptions  } from '../../../assets/util/selectOptions'
import { addStaffSchema } from '../../../assets/util/yupValidations'
import { useNavigate } from 'react-router-dom'
import uploadPicture from '../../../assets/util/uploadPicture';
import { toast } from 'react-toastify';
import "../../../assets/css/forForm.css"
import axios from 'axios'


const StaffData = ({staffDb}) => {


    const methods = useForm({
        //defaultValues: staffDb,
        resolver: yupResolver(addStaffSchema),
    });

	const {
        register,
        handleSubmit,
        reset,
        defaultValues,
		control,
        formState: { errors },
    } = methods;

    console.log(errors)

    useEffect(() => {
        //console.log("<><><><><><> CHANGING RESET RESET CHANGING  <><><><><><>")
        reset(staffDb);
    }, [staffDb]);
    

	const onSubmit = (formData) => {

        console.log("NO ERRORS")

		try{
            getLatLonPicForStaff(formData);

		}catch(err){
			console.log(err);
		}
	}

	let navigate = useNavigate();

	const addStaffMember = async (formData) => {

        try{

            const  res = await axios.post("/staffApi/addStaff",formData);
            //console.log(res.data);
			//console.log(res.data.email);
            if(res.data.email){
				//REDIRECT TO LIST AFTER THIS
				toast.success(messageResources.TOASTY.STAFF_ADDED)
				navigate('/staff/list')
            }else {
				toast.error(messageResources.TOASTY.STAFF_NOT_ADDED)
            }
            
        }catch(err){
            console.log(err)
        }
    }

	const getLatLonPicForStaff = async (formData) => {

        console.log("<><><><><><> getLatLonPic getLatLonPic getLatLonPic getLatLonPic <><><><><><>")
			

        const resFromMaps = await axios.get(`
        https://api.opencagedata.com/geocode/v1/json?q=${formData.address.address1} ${formData.address.address2} ${formData.address.postcode} London United Kingdom&key=c0348377c7af49c29b5b501efa40474b
    `)
    
        // console.log(resFromMaps);
        // console.log(resFromMaps?.data.results[0].geometry.lat)
        // console.log(resFromMaps?.data.results[0].geometry.lng)
        // console.log(resFromMaps?.data.results[0].components.city)

        formData.address.lat = resFromMaps?.data.results[0].geometry.lat;
        formData.address.long = resFromMaps?.data.results[0].geometry.lng
        resFromMaps?.data.results[0].components?.town 
                            ? formData.address.city = resFromMaps?.data.results[0].components?.town
                            : resFromMaps?.data.results[0].components?.city 
                                ? formData.address.city = resFromMaps?.data.results[0].components?.city
                                : formData.address.city = ""


        //console.log("<><><><><><> CLOUDINARY CLOUDINARY CLOUDINARY CLOUDINARY<><><><><><>")
    
        if(formData.picture[0]){ 

            //console.log("<><><><><><> UPLOADING PICTURE  <><><><><><>")
            // DATA FOR CLOUDINARY
            const pictureData = new FormData()
            pictureData.append("file",formData.picture[0])
            // uload preset name set in clooudinary
            pictureData.append("upload_preset","roots-pic")
            // given when signing up with cloudinary
            pictureData.append("cloud_name","dlkpi3swo")
            const  resFromCloudinary = await axios.post("https://api.cloudinary.com/v1_1/dlkpi3swo/image/upload",pictureData);
            //console.log(resFromCloudinary);
            //console.log(resFromCloudinary.data.url);
            formData.picture_path = resFromCloudinary.data.url;

        }else{
            console.log("<><><><><><> NO NO NO PICTURE TO UPLOAD <><><><><><>")
        }

        formData.position = formData.position.value;

        //console.log("<><><><><><> BEFORE CALL  ADD STAFF <><><><><><>")
        //console.log(formData)
        addStaffMember(formData);
	
	}
	


    return (
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card card-box">
                    <div className="card-head">
                        <header>{messageResources.STUDENT.PERSONAL_DATA}</header>
                        <div className="tools">
                            <a className="t-collapse btn-color fa fa-chevron-down"
                                href="javascript:;"></a>
                        </div>
                    </div>
                    <div className="card-body " id="bar-parent">
                        
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>{messageResources.COMMON.NAME} 
                                            {errors?.firstName && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                        </label>
                                        <input 	type="text" 
                                                className="form-control" 
                                                //value={staffDb?.name}
                                                {...register('name')}
                                                placeholder="Student's Name"/>
                                    </div>
                                    
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label >{messageResources.COMMON.SURNAME}
                                            {errors?.surname && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                        </label>
                                        <input 	type="text" 
                                                className="form-control" 
                                                //value={staffDb?.surname}
                                                {...register('surname')}
                                                placeholder="Student's Surname"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label >{messageResources.COMMON.PICTURE}</label>
                                        <input 	type="file" 
                                                className="form-control" 
                                                {...register('picture')}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>{messageResources.STAFF.POSITION}
                                            {errors?.position && <span className="errorSpan">  {messageResources.VALIDATIONS.REQUIRED} </span>}
                                        </label>
                                        <Controller
                                            name="position"
                                            control={control}
                                            render={({ field }) => (
                                                <Select options={positionOptions} 
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>

                <ContactInfo contactData={staffDb} pathField="address"/>
            
                <div className="col-md-11 col-sm-6" style={{float:"right"}}>
                    <button type="submit" 
                            style={{marginRight:"120px"}} 
                            className="btn btn-primary" 
                            onClick={methods.handleSubmit(onSubmit)}>
                        
                        {messageResources.COMMON.SUBMIT}
                    </button>
                </div>
            </form>
        </FormProvider>
    );
};

export default StaffData;
